<template>
  <el-card class="center audit-opts-bottom-btns" shadow="never">
    <el-button plain @click="openDialog">审核不通过</el-button>
    <el-button type="primary" @click="passConfirmCheck">审核通过</el-button>
    <el-dialog
      title="审核不通过描述说明"
      :visible.sync="dialogVisible"
      width="80%"
      custom-class="audit-opts-dialog"
      :before-close="handleClose">
      <div>
        <div class="texts">
          <el-button
            v-for="(item, index) in texts"
            :key="index"
            :type="item.plain?'default':'primary'"
            :plain="item.plain"
            @click="editText(index)">{{item.text}}</el-button>
        </div>
        <el-input v-model="remark"
        type="textarea"
        maxlength="300"
        show-word-limit
        :autosize="{ minRows: 5, maxRows: 5}"
        placeholder="请输入需要补充调整的说明"></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>

export default {
  name: 'auditOpts',
  components: {
  },
  props: {
    archiveId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      remark: '',
      pass: false,
      dialogVisible: false,
      texts: [
        { plain: true, text: '藏品概要 信息与实物不符' },
        { plain: true, text: '细节描述 信息与实物不符' },
        { plain: true, text: '主图  图片不清晰' },
        { plain: true, text: '细节描述  图片模糊' },
        { plain: true, text: '藏品分类不正确' },
        { plain: true, text: '藏品概要 信息与实物不符' },
        { plain: true, text: '细节描述 信息与实物不符' }
      ]
    }
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    handleClose() {
      this.remark = ''
      this.texts = [
        { plain: true, text: '藏品概要 信息与实物不符' },
        { plain: true, text: '细节描述 信息与实物不符' },
        { plain: true, text: '主图  图片不清晰' },
        { plain: true, text: '细节描述  图片模糊' },
        { plain: true, text: '藏品分类不正确' },
        { plain: true, text: '藏品概要 信息与实物不符' },
        { plain: true, text: '细节描述 信息与实物不符' }
      ]
      this.dialogVisible = false
    },
    editText(index) {
      if (this.texts[index].plain) {
        this.texts[index].plain = false
        this.remark = this.remark + ' ' + this.texts[index].text
      } else {
        // debugger
        let re = new RegExp(' ' + this.texts[index].text)
        this.texts[index].plain = true
        this.remark = this.remark.replace(re, '')
      }
      console.log(this.remark)
    },
    submit() {
      this.pass = false
      this.firstAudit()
      this.dialogVisible = false
    },
    // 触发父组件表单校验
    passConfirmCheck() {
      this.$emit('identifyFormCheck')
    },
    passConfirm(identifyForm) {
      const h = this.$createElement
      this.$msgbox({
        title: '提示',
        message: h('p', { class: 'confirm-msg' }, [
          h('span', { class: 'warning-msg' }, '审核通过将会进入初鉴'),
          h('span', null, '，请确定是选择'),
          h('span', { class: 'institute-Name' }, '[' + identifyForm.deptName + ']'),
          h('span', null, '进行初鉴吗？')
        ]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
      //  审核通过操作
        this.pass = true
        this.firstAudit(identifyForm)
      }).catch(() => {})
    },
    openDialog() {
      this.dialogVisible = true
    },

    // 提交初审
    async firstAudit(identifyForm = null) {
      try {
        this.$loading({
          lock: true,
          text: '正在提交',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.7)'
        })
        const { data } = await this.$api.record.firstAudit({
          archiveId: this.archiveId,
          pass: this.pass,
          remark: this.remark,
          identifyType: identifyForm ? identifyForm.process : '',
          deptId: identifyForm ? identifyForm.institute : ''
        })
        if (data.code === 0) {
          this.$loading().close()
          this.$message.success('提交成功')
          this.isSaved = true
          this.$router.back()
          // this.getRecord()
        } else {
          this.$loading().close()
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        this.$loading().close()
        throw new Error(err)
      }
    }
  }
}
</script>

<style lang="scss">
.audit-opts-bottom-btns{
  .el-button + .el-button {
    margin-left: 40px;
}
}
.audit-opts-dialog{
  .texts{
    .el-button{
      margin-bottom: 18px;
    }
  }
}

.confirm-msg {
  .warning-msg {
    color: $--color-warning;
  }
  .institute-Name {
    color: $--color-info;
  }
}
</style>
