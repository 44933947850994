<template>
  <div class="option-detail-form">
    <div class="ym-panel-radius">
      <div class="title">
        <span>{{deptData.deptName}}</span>
      </div>
      <template v-for="option in deptData.collectionDetectVos">
        <div class="dept" :key="option[dist.optionId]" v-if="initFinished">
          <div class="dept-header">
            <div class="header-left">
              鉴定项目：<span class="option">{{option[dist.optionName]}}</span>
            </div>
            <div class="header-right">
              <el-button size="small" class="btn-save" @click="handleSave(option[dist.optionId], option.detectId)" :loading="btnLoading[option[dist.optionId]]" v-if="!submitted && !isOperatorOrRecorder">{{btnLoading[option[dist.optionId]] ? '保存中' : '保存'}}</el-button>
            </div>
          </div>
          <div class="dept-result">
            <el-form :model="form[option[dist.optionId]]"
                     :rules="rules"
                     :ref="'form' + option[dist.optionId]"
                     label-width="180px"
                     label-position="left"
                     label-suffix="："
                     size="small"
                     :disabled="submitted || isOperatorOrRecorder">
              <el-form-item label="项目结果" prop="result">
                <el-select v-model="form[option[dist.optionId]].result" placeholder="请选择" v-if="!submitted">
                  <el-option label="通过" value="通过"></el-option>
                  <el-option label="不通过" value="不通过"></el-option>
                </el-select>
                <p v-else>{{form[option[dist.optionId]].result}}</p>
              </el-form-item>

              <el-form-item label="专家姓名" prop="expert">
                <el-input v-model="form[option[dist.optionId]].expert" :placeholder="submitted ? '' : '请输入'" maxlength="50" v-if="!submitted"></el-input>
                <p v-else>{{form[option[dist.optionId]].expert}}</p>
              </el-form-item>

              <el-form-item label="物联环境" prop="environment">
                <el-input v-model="form[option[dist.optionId]].environment" :placeholder="submitted ? '' : '请输入'" maxlength="100" :title="form[option[dist.optionId]].environment" v-if="!submitted"></el-input>
                <p class="show-info" v-else>{{form[option[dist.optionId]].environment}}</p>
              </el-form-item>

              <el-form-item label="设备型号规格" prop="equipmentType">
                <el-input v-model="form[option[dist.optionId]].equipmentType" :placeholder="submitted ? '' : '请输入'" maxlength="100" :title="form[option[dist.optionId]].equipmentType" v-if="!submitted"></el-input>
                <p class="show-info" v-else>{{form[option[dist.optionId]].equipmentType}}</p>
              </el-form-item>

              <el-form-item label="试剂和试验条件(参数)" prop="condition" class="item-textarea">
                <el-input v-model="form[option[dist.optionId]].condition" type="textarea" maxlength="500" :autosize="{ minRows: 5, maxRows: 5}" resize="none" show-word-limit :placeholder="submitted ? '' : '请输入'" :title="form[option[dist.optionId]].condition" v-if="!submitted"></el-input>
                <p v-else class="show-info" v-html="showParagraph(form[option[dist.optionId]].condition)"></p>
              </el-form-item>

              <el-form-item label="项目检测报告" prop="report" class="item-report">
                <file-upload :sizeLimit="1024*50"
                             typeErrorMsg="上传的格式必须为PDF"
                             :acceptTypes="acceptTypes"
                             :file-list="reportList[option[dist.optionId]]"
                             @onSuccess="onUploadSuccess($event, 'report', option[dist.optionId], option[dist.optionName])"
                             @onRemove="onFileRemove('report', option[dist.optionId])"
                             @onProgress="onUploadProgress('report', option[dist.optionId])"
                             @onError="onUploadError('report', option[dist.optionId])">
                  <el-button size="small" icon="el-icon-upload2" v-if="(!reportList[option[dist.optionId]] || reportList[option[dist.optionId]].length < 1) && !progressing[option[dist.optionId]]" :disabled="submitted || isOperatorOrRecorder">上传报告</el-button>
                </file-upload>
                <el-tooltip effect="light" placement="top" content="格式必须为PDF，不大于50MB" class="report-tooltip">
                  <i class="tips-icon el-icon-question"></i>
                </el-tooltip>
              </el-form-item>

              <el-form-item label="辅证材料" prop="support"  class="item-support">
                <img-video-upload :limit="5"
                                  :sizeLimit="1024*200"
                                  :file-list="form[option[dist.optionId]].supportFileList"
                                  :previewList="form[option[dist.optionId]].supportFilePreview"
                                  :compressList="form[option[dist.optionId]].supportFileCompress"
                                  :acceptTypes="acceptSupportTypes"
                                  :showFileList="false"
                                  size="medium"
                                  :disabled="submitted || isOperatorOrRecorder"
                                  @onSuccess="onUploadSuccess($event, 'support', option[dist.optionId])"
                                  typeErrorMsg="请上传格式为JPG、JPEG、PNG的图片或格式为mp4、ogg、flv、avi、wmv、rmvb的视频文件"
                                  @onRemove="(index) => onFileRemove('support', option[dist.optionId], index)">
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </img-video-upload>
                <el-tooltip effect="light" placement="top" content="格式为JPG、JPEG、PNG的图片或格式为mp4、ogg、flv、avi、wmv、rmvb的视频文件" class="support-tooltip">
                  <i class="tips-icon el-icon-question"></i>
                </el-tooltip>
              </el-form-item>

            </el-form>
          </div>
        </div>
      </template>
      <el-form :model="summaryForm"
               :rules="summaryRules"
               class="summary-form"
               ref="formSummary"
               label-width="180px"
               label-position="left"
               label-suffix="："
               size="small"
               :disabled="submitted || isOperatorOrRecorder">

        <el-form-item label="鉴定项目" class="item-option">
          <div class="option-tag" v-for="option in deptData.collectionDetectVos" :key="option[dist.optionId]">{{option[dist.optionName]}}</div>
        </el-form-item>

        <el-form-item label="确真结果" prop="result">
          <el-select v-model="summaryForm.result" v-if="!submitted">
            <el-option label="真品" value="真品"></el-option>
            <el-option label="仿品(艺术品)" value="仿品"></el-option>
          </el-select>
          <p v-else>{{summaryForm.result === '真品' ? '真品' : '仿品(艺术品)'}}</p>
        </el-form-item>

        <el-form-item label="鉴定报告" prop="report" class="item-report">
          <file-upload :sizeLimit="1024*50"
                       typeErrorMsg="上传的格式必须为PDF"
                       :file-list="summaryReportList"
                       :acceptTypes="acceptTypes"
                       @onSuccess="onUploadSuccess($event, 'summary')"
                       @onRemove="onFileRemove('summary')"
                       @onProgress="onUploadProgress('summary')"
                       @onError="onUploadError('summary')"
                       :disabled="summaryUploadDisabled || submitted || isOperatorOrRecorder"
                       >
            <el-button size="small" icon="el-icon-upload2" v-if="(!summaryReportList || summaryReportList.length < 1) && !summaryProgressing" :disabled="submitted || isOperatorOrRecorder">上传报告</el-button>
          </file-upload>
          <el-tooltip effect="light" placement="top" content="格式必须为PDF，不大于50MB" class="summary-report-tooltip">
              <i class="tips-icon el-icon-question"></i>
          </el-tooltip>
        </el-form-item>

        <el-form-item label="鉴定结论" prop="conclusion" class="item-textarea">
          <el-input v-model="summaryForm.conclusion" type="textarea" maxlength="500" :autosize="{ minRows: 5, maxRows: 5}" resize="none" show-word-limit :placeholder="submitted ? '' : '请输入'" :title="summaryForm.conclusion" v-if="!submitted"></el-input>
          <p v-else class="show-info" v-html="showParagraph(summaryForm.conclusion)"></p>
        </el-form-item>

      </el-form>
      <div class="btn-block">
        <el-button type="primary" class="btn-submit" @click="handleSubmit" v-if="!submitted && !isOperatorOrRecorder" :loading="submitLoading">{{submitLoading ? '提交中' : '确定提交'}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from '@/views/record/component/uploadOnlyFile.vue' // 文件上传
import ImgVideoUpload from '@/views/record/component/uploadImgVideo.vue'
import { showParagraph } from '@/utils/handleParagraph.js'

export default {
  components: {
    FileUpload,
    ImgVideoUpload
  },
  props: {
    deptData: {
      type: Object,
      default: () => {}
    },
    isOperatorOrRecorder: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let checkResult = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请选择项目结果'))
      } else {
        callback()
      }
    }
    let checkExpert = (rule, value, callback) => {
      const regExp = /^[a-zA-Z\u4e00-\u9fa5 ·]*$/
      if (!value) {
        callback(new Error('请输入专家姓名'))
      } else if (!regExp.test(value)) {
        callback(new Error('请填写正确的姓名'))
      } else {
        callback()
      }
    }
    let checkReport = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请上传项目检测报告'))
      } else {
        callback()
      }
    }
    let checkSummaryResult = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请选择确真结果'))
      } else {
        callback()
      }
    }
    let checkSummaryReport = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请上传鉴定报告'))
      } else {
        callback()
      }
    }
    let checkConclusion = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请填写鉴定结论'))
      } else {
        callback()
      }
    }
    return {
      dist: {
        optionId: 'detectId',
        optionName: 'detectItemName'
      },
      btnLoading: {},
      submitLoading: false,
      submitted: false, // 该机构已完成所有确真提交
      initFinished: false,
      acceptTypes: /.pdf$|.PDF$/,
      acceptSupportTypes: /.jpg$|.jpeg$|.png$|.bmp$|.JPG$|.JPEG$|.PNG$|.BMP$|.mp4$|.ogg$|.flv$|.avi$|.wmv$|.rmvb$/,
      rules: {
        result: [
          { validator: checkResult, required: true, trigger: 'change' }
        ],
        expert: [
          { validator: checkExpert, required: true, trigger: 'change' }
        ],
        report: [
          { validator: checkReport, required: true, trigger: 'change' }
        ]
      },
      summaryRules: {
        result: [
          { validator: checkSummaryResult, required: true, trigger: 'change' }
        ],
        report: [
          { validator: checkSummaryReport, required: true, trigger: 'change' }
        ],
        conclusion: [
          { validator: checkConclusion, required: true, trigger: 'change' }
        ]
      },
      form: {}, // 所有项目的表格汇总
      reportList: {}, // 各个项目的上传文件
      summaryReportList: [], // 综合报告file-list
      summaryForm: {
        result: '',
        report: '',
        conclusion: ''
      },
      progressing: {},
      summaryProgressing: false,
      fileDisabled: false, // 检测报告和辅助材料是否禁用上传
      summaryUploadDisabled: false
    }
  },
  mounted() {
    this.initForm(this.deptData)
  },
  methods: {
    showParagraph,
    initForm(data) {
      // 检测项目表单初始化
      data.collectionDetectVos.forEach(item => {
        this.$set(this.form, item[this.dist.optionId], {})
        this.$set(this.form[item[this.dist.optionId]], 'result', item.itemResult ? item.itemResult : '')
        this.$set(this.form[item[this.dist.optionId]], 'expert', item.expert ? item.expert : '')
        this.$set(this.form[item[this.dist.optionId]], 'environment', item.iotEnvironment ? item.iotEnvironment : '')
        this.$set(this.form[item[this.dist.optionId]], 'equipmentType', item.equipment ? item.equipment : '')
        this.$set(this.form[item[this.dist.optionId]], 'condition', item.condition ? item.condition : '')
        this.$set(this.form[item[this.dist.optionId]], 'report', item.testReport ? item.testReport : '')

        let arrOrigin = []
        let arrCompress = []
        let arrfileList = []
        let arrOriginHalf = [] // 原图/视频半路径
        let arrCompressHalf = [] // 原缩略图/视频半路径
        if (item.supplementaryMaterials && item.supplementaryMaterials.length > 0) {
          item.supplementaryMaterials.forEach(supplyInfo => {
            arrOrigin.push(this.FileUrl + supplyInfo.originMaterial)
            arrOriginHalf.push(supplyInfo.originMaterial)
            // 视频文件没有压缩，原文件地址与缩略地址一致
            arrCompress.push(this.FileUrl + supplyInfo.compressMaterial)
            arrCompressHalf.push(supplyInfo.compressMaterial)

            arrfileList.push({ url: this.FileUrl + supplyInfo.originMaterial })
          })
        }
        // 辅助资料文件地址
        this.$set(this.form[item[this.dist.optionId]], 'supportFilePreview', arrOrigin)
        // 辅助资料压缩文件地址
        this.$set(this.form[item[this.dist.optionId]], 'supportFileCompress', arrCompress)

        this.$set(this.form[item[this.dist.optionId]], 'supportFileList', arrfileList)
        // 辅助资料的半路径地址
        this.$set(this.form[item[this.dist.optionId]], 'support', arrOriginHalf)
        this.$set(this.form[item[this.dist.optionId]], 'supportThumbnail', arrCompressHalf)

        this.$set(this.reportList, item[this.dist.optionId], item.testReport ? [{
          name: `${item.detectItemName}检测报告`,
          url: this.FileUrl + item.testReport
        }] : [])
        this.$set(this.progressing, item[this.dist.optionId], false)

        this.$set(this.btnLoading, item[this.dist.optionId], false)
      })
      // 机构综合反馈初始化
      data.identifyResult && this.$set(this.summaryForm, 'result', data.identifyResult)
      if (data.file) {
        this.$set(this.summaryForm, 'report', data.file)
        this.summaryReportList = [{
          name: '综合鉴定报告',
          url: this.FileUrl + data.file
        }]
        this.submitted = true
      }
      data.conclusion && this.$set(this.summaryForm, 'conclusion', data.conclusion)

      this.initFinished = true
    },
    onUploadSuccess(e, fileType, optionId, optionName) {
      const res = e.res
      console.log(res)
      if (res.code === 0) {
        if (fileType === 'summary') {
          this.$set(this.summaryForm, 'report', res.data.originalPath)
          this.summaryReportList = [{
            name: '综合鉴定报告',
            url: this.FileUrl + res.data.originalPath
          }]
          this.summaryProgressing = false
          this.$refs.formSummary.validateField('report')
        } else if (fileType === 'report') {
          this.$set(this.form[optionId], 'report', res.data.originalPath)
          this.$set(this.reportList, optionId, [{
            name: `${optionName}检测报告`,
            url: this.FileUrl + res.data.originalPath
          }])
          this.$set(this.progressing, optionId, false)
          this.$refs[`form${optionId}`][0].validateField('report')
        } else if (fileType === 'support') {
          this.form[optionId].supportFilePreview.push(this.FileUrl + res.data.originalPath)
          this.form[optionId].supportFileCompress.push(res.data.compressPath ? this.FileUrl + res.data.compressPath : this.FileUrl + res.data.originalPath)
          this.form[optionId].supportFileList.push({
            name: res.data.filename,
            url: this.FileUrl + res.data.originalPath
          })
          this.form[optionId].support.push(res.data.originalPath)
          this.form[optionId].supportThumbnail.push(res.data.compressPath ? res.data.compressPath : res.data.originalPath)
        }
      }
    },
    onFileRemove(fileType, optionId, index) {
      if (fileType === 'summary') {
        // const url = this.summaryForm.report
        this.summaryReportList = []
        this.$set(this.summaryForm, 'report', '')
        this.summaryProgressing = false
        // this.removeFile(url)
      } else if (fileType === 'report') {
        this.$set(this.form[optionId], 'report', '')
        this.$set(this.reportList, optionId, [])
        this.$set(this.progressing, optionId, false)
      } else if (fileType === 'support') {
        this.form[optionId].supportFilePreview.splice(index, 1)
        this.form[optionId].supportFileCompress.splice(index, 1)
        this.form[optionId].supportFileList.splice(index, 1)
        this.form[optionId].support.splice(index, 1)
        this.form[optionId].supportThumbnail.splice(index, 1)
      }
    },
    onUploadProgress(fileType, optionId) {
      if (fileType === 'report') {
        console.log('progressing')
        this.$set(this.progressing, optionId, true)
      } else if (fileType === 'summary') {
        this.summaryProgressing = true
      }
    },
    onUploadError(fileType, optionId) {
      if (fileType === 'report') {
        this.$set(this.progressing, optionId, false)
      } else if (fileType === 'summary') {
        this.summaryProgressing = false
      }
    },
    // 保存对应检测项目的填写内容
    async handleSave(optionId, detectId) {
      console.log(optionId)
      this.$set(this.btnLoading, optionId, true)
      console.log(this.form)
      console.log(this.deptData)
      try {
        let dataForm = this.form[optionId]
        let supplementaryMaterials = []
        // 处理辅助材料
        dataForm.support.forEach((filePath, index) => {
          supplementaryMaterials.push({
            originMaterial: filePath,
            compressMaterial: dataForm.supportThumbnail[index]
          })
        })
        const { data } = await this.$api.finalIdentify.saveOptionInfo({
          detectId: detectId,
          itemResult: dataForm.result,
          expert: dataForm.expert,
          iotEnvironment: dataForm.environment,
          equipment: dataForm.equipmentType,
          condition: dataForm.condition,
          testReport: dataForm.report,
          supplementaryMaterials: supplementaryMaterials
        })
        if (data.code === 0) {
          this.$set(this.btnLoading, optionId, false)
          this.$message({
            type: 'success',
            message: '保存成功'
          })
        } else {
          this.$set(this.btnLoading, optionId, false)
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        this.$set(this.btnLoading, optionId, false)
        throw new Error(err)
      }
    },
    // 删除上传的文件
    async removeFile(url) {
      try {
        const { data } = await this.$api.record.removeFile({ params: { url: url } })
        console.log(data)
      } catch (err) {
        throw new Error(err)
      }
    },
    validatorOption(id) {
      return new Promise((resolve, reject) => {
        if (id !== 'Summary') {
          this.$refs[`form${id}`][0].validate((valid) => {
            if (!valid) {
              /* eslint-disable */
              reject('存在未填写的必填项，请填写完后再提交')
              /* eslint-disable */
            } else {
              resolve()
            }
          })
        } else {
          this.$refs[`form${id}`].validate((valid) => {
            if (!valid) {
              /* eslint-disable */
              reject('存在未填写的必填项，请填写完后再提交')
              /* eslint-disable */
            } else {
              resolve()
            }
          })
        }
      })
    },
    handleSubmit() {
      console.log(this.form)
      let arr = Object.keys(this.form)
      arr.push('Summary')
      Promise.all(arr.map(this.validatorOption)).then(() => {
        console.log('校验成功')
        const h = this.$createElement
        this.$msgbox({
          title: '提示',
          message: h('p', { class: 'final-confirm-msg' }, [
            h('span', null, '确定提交终鉴数据吗？'),
            h('span', { class: 'warning-msg' }, '提交后所有数据将不能修改。')
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.submitAuthenticityDetail()
        }).catch(() => {})
      }).catch((err) => {
        this.$message({
          type: 'error',
          message: err
        })
      })
    },
    async submitAuthenticityDetail() {
      try {
        this.submitLoading = true
        let collectionDetects = []
        for (let key in this.form) {
          const optionResult = this.form[key]
          let supplementaryMaterials = []
          // 处理辅助材料
          optionResult.support.forEach((filePath, index) => {
            supplementaryMaterials.push({
              originMaterial: filePath,
              compressMaterial: optionResult.supportThumbnail[index]
            })
          })
          collectionDetects.push({
            detectId: key,
            itemResult: optionResult.result,
            expert: optionResult.expert,
            iotEnvironment: optionResult.environment,
            equipment: optionResult.equipmentType,
            condition: optionResult.condition,
            testReport: optionResult.report,
            supplementaryMaterials: supplementaryMaterials
          })
        }
        const params = {
          identifyRealId: this.deptData.identifyRealId,
          archiveId: this.deptData.archiveId,
          deptId: this.deptData.deptId,
          identifyResult: this.summaryForm.result,
          conclusion: this.summaryForm.conclusion,
          file: this.summaryForm.report,
          collectionDetects: collectionDetects
        }
        console.log(params);
        const { data } = await this.$api.finalIdentify.submitAuthenticityDetail(params)
        if (data.code === 0) {
          this.$message({
            type: 'success',
            message: '提交成功'
          })
          this.submitLoading = false
          this.$emit('submitFinished')
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
          this.submitLoading = false
        }
      } catch (err) {
        this.submitLoading = false
        throw new Error(err)
      }
    }
  }
}
</script>

<style lang="scss">
.final-confirm-msg {
  .warning-msg {
    color: $--color-danger;
  }
}
</style>

<style lang="scss" scoped>
.option-detail-form {
  min-width: 1000px;
  color: $--color-text-primary;
  .title {
    display: flex;
    align-items: center;
    font-size: 20px;
    margin: 2px 0 24px;
    span {
      margin-right: 8px;
      font-weight: 700;
    }
  }
  .dept {
    border: 1px solid #EBEDF0;
    & + .dept {
      margin-top: 24px;
    }
    .dept-header {
      display: flex;
      justify-content: space-between;
      height: 56px;
      line-height: 56px;
      padding: 0 25px;
      font-size: 16px;
      font-weight: 600;
      background-color: #F7F8FA;
      .option {
        color: $--color-warning;
      }
      .header-right {
        .btn-save {
          width: 88px;
          color: #267CDC;
        }
      }
    }
    .dept-result {
      padding: 24px 24px 0;
      .el-form {
        .el-form-item {
          width: 460px;
          .show-info {
            width: 566px;
            text-align: justify;
          }
          &.item-textarea, &.item-report, &.item-support {
            width: 746px;
          }
          .report-tooltip, .support-tooltip {
            position: absolute;
            font-size: 16px;
            color: #C8C9CC;
          }
          .report-tooltip {
            top: 50%;
            transform: translateY(-50%);
            left: -76px;
          }
          .support-tooltip {
            top: 8px;
            left: -114px;
          }
        }
      }
    }
  }
  ::v-deep .summary-form {
    padding: 32px 26px 0;
    .el-form-item {
      width: 460px;
      .summary-report-tooltip {
        position: absolute;
        top: 8px;
        left: -104px;
        font-size: 16px;
        color: #C8C9CC;
      }
      &.item-option {
        width: 1000px;
        .el-form-item__content {
          line-height: 1;
          .option-tag {
            display: inline-block;
            padding: 4px 8px;
            font-size: 12px;
            color: #5C6578;
            background-color: #EFEFF1;
            margin: 6px 12px 0 0;
          }
        }
      }
      &.item-textarea, &.item-report {
        width: 746px;
      }
    }
  }
  ::v-deep.el-upload__tip {
    color: #a3a3a3;
    &.wait-coding {
      color: #D40000;
    }
  }
  ::v-deep.el-upload {
    width: 97px;
  }
  .btn-block {
    text-align: center;
    margin-top: 56px;
    margin-bottom: 38px;
    .btn-submit {
      width: 90px;
      height: 32px;
      padding: 8px 16px;
      font-size: 14px;
    }
  }
}
</style>