<template>
  <div class="user-list">

    <!-- 顶部搜索栏 -->
    <div class="tableList-form">
      <ymForm ref="tableForm" refCode="tableForm"
        :formValue="formValue"
        :formData="formData"
        :inline="true"
        @onSubmit="onSubmit">
      </ymForm>
      <div class="tableList-form-buttonBox">
        <el-button size="medium" @click="submitForm('tableForm')" type="primary">查询</el-button>
        <el-button size="medium" @click="resetForm('tableForm')" plain>重置</el-button>
      </div>
    </div>

    <!-- 列表组件 -->
    <ym-table-list
      stripe
      :headData="headData"
      :loading="loading"
      :data="tableData"
      :columns="columns"
      :pageData="pageData"
      row-key="id"
      @handlePageChange="handlePageChange">
      <!-- 使用列表插槽 -->
      <template #userName="{row}">
        {{row.userName ? row.userName : '-'}}
      </template>
      <template #groupNameList="{row}">
        {{row.groupNameList.join(',') }}
      </template>
    </ym-table-list>

    <el-dialog title="新密码" :visible.sync="dialogVisible" width="400px">
      <span style="margin-left:20px">新密码:</span>
      <el-input v-model="newPassword" readonly style="width:200px" class="newPassword"></el-input>
      <el-button type="text" @click="copyPassword($event, newPassword)">复 制</el-button>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

    <edit-user-dialog :editUserDialogVisible="editUserDialogVisible"
                      :userData="userData"
                     @handleUserDialogClose="handleUserDialogClose"
                     @onSuccess="getData"
                     v-if="editUserDialogVisible"></edit-user-dialog>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'
import ymForm from '@/components/YmForm'
import tableObj from './data.js'
import clipboard from '@/utils/clipboard.js'
import EditUserDialog from './editUserDialog/index.vue'
import { getUserInfo } from '@/utils/localStorage.js'

export default {
  components: {
    YmTableList,
    ymForm,
    EditUserDialog
  },
  data() {
    return {
      loading: false,
      newPassword: '',
      dialogVisible: false,
      formValue: {}, // 用以显示的form表单
      searchForm: {}, // 用以搜索的obj
      tableData: [],
      tableObj: tableObj,
      columns: tableObj.columnsData,
      pageData: tableObj.pageData,
      formData: tableObj.formData,
      headData: tableObj.headData,
      userData: {},
      editUserDialogVisible: false
    }
  },
  mounted() {
    this.showAction()
    this.pageData.page = 1
    this.getData()
  },
  methods: {

    // 点击提交按钮,触发提交事件
    submitForm(id) {
      this.$refs[id].onSubmit()
    },
    // 提交事件
    async onSubmit(form, ref) {
      this.searchForm = JSON.parse(JSON.stringify(this.formValue))
      this.formValue = {}
      let str = '搜索 “ '
      for (var key in this.searchForm) {
        if (this.searchForm[key] !== '') {
          str = str + this.formData[0].items[key].label + '=' + this.searchForm[key] + ' '
        }
      }
      this.headData.title = str + '” 的结果'
      this.getData()
    },
    // 重置表单
    resetForm(id) {
      this.formValue = {}
      this.searchForm = {}
      this.headData.title = '用户列表'
      this.getData()
    },
    // 删除列表某一条用户组
    async deleteRow(row) {
      const { data } = await this.$api.user.deleteUser(row.userId)
      if (data.code === 0) {
        this.$message({ message: '删除用户成功', type: 'success' })
        this.getData()
      } else {
        this.$message.error('删除用户失败: ' + data.message || '未知错误')
        this.getData()
      }
    },
    // 复制密码
    copyPassword(event, text) {
      let thisVue = this
      clipboard(event, text, function() {
        thisVue.$message.success('复制成功')
      }, function() {
        thisVue.$message.error('复制失败')
      })
    },
    // 重置用户密码
    async resetUserPassword(row) {
      const { data } = await this.$api.user.resetUserPassword(row.userId)
      if (data.code === 0 && data.data) {
        this.newPassword = data.data.password
        this.dialogVisible = true
      } else {
        this.$message.error('重置密码失败: ' + data.message || '未知错误')
      }
    },
    // 判断当前页是否是从其他动作跳转而来
    showAction() {
      let action = this.$route.params.action
      if (action && action === 'add-success') {
        this.$message({ message: '新增用户成功', type: 'success' })
      }
      if (action && action === 'edit-success') {
        this.$message({ message: '编辑用户成功', type: 'success' })
      }
    },
    // 获取表格数据
    async getData() {
      this.loading = true
      Object.assign(this.searchForm, this.pageData)
      try {
        const { data } = await this.$api.user.getUserListByPage(this.searchForm)
        console.log({ '表格数据': data })
        this.loading = false
        if (data.code !== 0 || data.data === null) {
          this.$message.error('获取用户列表失败: ' + data.data.message || '未知错误')
          return
        }
        if (data.data.records.length === 0 && data.data.total !== 0 && this.pageData.page > 1) {
          this.$set(this.pageData, 'page', this.pageData.page - 1)
          this.getData()
          return
        }
        this.tableData = data.data.records
        this.$set(this.pageData, 'total', Number.parseInt(data.data.total))
        this.tableData.forEach(item => {
          let groupDescArr = []
          item.groups.forEach(groupItem => {
            groupDescArr.push(groupItem.groupDescription ? groupItem.groupDescription : groupItem.groupName)
          })
          this.$set(item, 'groupNameList', groupDescArr)
        })
      } catch (e) {
        this.loading = false
        console.log(e)
        this.$message.error('获取用户列表失败')
      }
    },
    handlePageChange(goPage) {
      this.$set(this.pageData, 'page', goPage)
      this.getData()
    },
    handleUserDialogClose() {
      this.editUserDialogVisible = false
    },
    hasPermission() {
      const userInfo = JSON.parse(getUserInfo())
      return userInfo.permissionsTags.includes('admin')
    },
    isAdmin(info) {
      return info.groupIds.includes('1')
    }
  }
}
</script>

<style lang="scss" scoped>
.user-list{
  .tableList-form{
    background: #fff;
    border-radius: $--border-radius-base;
    padding: 28px 24px 0;
    border: $--border-width-base $--border-style-base $--border-color-light;
    margin-bottom: 24px;
    width: 100%;
    overflow: hidden;

    /deep/.ym-form{
      float: left;
      width: calc(100% - 230px);
    }
  }

  .tableList-form-buttonBox{
    float: right;
    button{
      // margin-right: 10px;
    }
  }

  /deep/.newPassword input{
    border: none;
    width: auto;
  }
}

</style>
