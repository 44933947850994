<template>
  <div class="final-identify-details">
    <div class="options-tab">
      <el-radio-group v-model="radio" @change="handleRadioChange">
        <el-radio label="summary" border>综合</el-radio>
        <el-radio label="authenticity" border v-if="pageType==='record' || (userInfo && userInfo.identifyType === '终鉴-确真') || hasPermission || isCenterLeader">确真</el-radio>
        <el-radio label="notarization" border v-if="pageType==='record' || (userInfo && userInfo.identifyType === '终鉴-确权') || hasPermission || isCenterLeader">确权</el-radio>
        <el-radio label="value" border v-if="pageType==='record' || (userInfo && userInfo.identifyType === '终鉴-确值') || hasPermission || isCenterLeader">确值</el-radio>
        <el-radio label="law" border v-if="pageType==='record' || (userInfo && userInfo.identifyType === '终鉴-法律') || hasPermission || isCenterLeader">法律</el-radio>
      </el-radio-group>
    </div>
    <div class="divider"></div>
    <steps class="steps" :finishFlag="status === '终鉴完成'"></steps>
    <div class="summary">
      <div class="block-left">
        <div class="info">
          <el-row :gutter="16">
            <el-col :span="10">
              <span class="label">藏品名称：</span>
              <span class="desc">{{basicInfo.name}}</span>
            </el-col>
            <el-col :span="5">
              <span class="label">分类：</span>
              <span class="desc">{{basicInfo.category}}</span>
            </el-col>
            <el-col :span="5">
              <span class="label">参考年代：</span>
              <span class="desc">{{basicInfo.referenceYear}}</span>
            </el-col>
          </el-row>
          <el-row :gutter="16" class="desc-row">
            <el-col :span="24">
              <span class="label">简述：</span>
              <p class="desc" v-html="showParagraph(basicInfo.description)"></p>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="block-right" v-if="radio === 'summary' && loadFinished">
        <div class="operator">
          <el-button class="btn" :disabled="!finalResult" v-if="!finalResult">提交完成</el-button>
          <el-button class="btn" type="primary" v-else-if="status !== '终鉴完成'" @click="finalCheck" :disabled="pageType !== 'record'">提交完成</el-button>
          <el-button class="btn certificate" v-else @click="viewCertificate">终鉴电子证书</el-button>

          <div class="tips" v-if="status !== '终鉴完成'">待三确与法律提交</div>
          <!-- <template v-else>
            <span class="result-title">确真综合结果</span>
            <div class="result fake">仿品</div>
            <div class="result">真品</div>
            <el-tooltip effect="light" placement="top" content="两个鉴定机构只要有一个机构鉴定结果为仿品则确真综合结果为仿品" popper-class="result-tooltip">
              <i class="tips-icon el-icon-question"></i>
            </el-tooltip>
          </template> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Steps from './steps.vue'
import { showParagraph } from '@/utils/handleParagraph.js'

export default {
  components: {
    Steps
  },
  props: {
    basicInfo: {
      type: Object,
      default: () => {}
    },
    pageType: {
      type: String,
      default: ''
    },
    userInfo: {
      type: Object,
      default: () => {}
    },
    finalResult: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: ''
    },
    loadFinished: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      radio: 'summary'
    }
  },
  computed: {
    // 所有相关材料提交完成
    allSubmitted() {
      return false
    },
    hasPermission() {
      return this.userInfo.permissionsTags.includes('admin') || this.userInfo.permissionsTags.includes('operator')
    },
    isCenterLeader() {
      return this.userInfo.permissionsTags.includes('leader') && !this.userInfo.identifyType
    }
  },
  methods: {
    showParagraph,
    handleRadioChange() {
      this.$emit('tabChange', this.radio)
    },
    changeRadio(flag) {
      this.radio = flag
    },
    // 提交所有终鉴数据
    finalCheck() {
      const h = this.$createElement
      this.$msgbox({
        title: '提示',
        message: h('p', { class: 'final-confirm-msg' }, [
          h('span', null, '确定提交终鉴数据吗？'),
          h('span', { class: 'warning-msg' }, '提交后所有数据将不能修改。')
        ]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$loading({
          lock: true,
          text: '正在提交',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.7)'
        })
        this.$emit('handleSubmitFinalInfo')
      }).catch(() => {})
    },
    async viewCertificate() {
      try {
        const { data } = await this.$api.finalIdentify.getFinalCertificate({
          archiveId: this.basicInfo.archiveId
        })
        if (data.code === 0) {
          window.open(this.FileUrl + data.data.certificateUrl, 'blank')
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        throw new Error(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.final-identify-details {
  .result {
    width: 65px;
    height: 36px;
    text-align: center;
    font-size: 20px;
    color: #4DB355;
    padding: 0 4px;
    border: 2px dashed #4DB355;
    background-color: #F2FFF6;
    border-radius: 4px;
    line-height: 32px;
    margin-top: 8px;
    &.fake {
      color: #FA1F21;
      background-color: #FEDADA;
      border-color: #FA1F21;
    }
  }
  .options-tab {
    margin-bottom: 28px;
    ::v-deep .el-radio {
      width: 90px;
      height: 32px;
      padding: 8px 29px;
      margin: 0 16px 0 0;
      text-align: center;
      border: none;
      border-radius: 2px;
      background-color: #F0F1F3;
      &.is-checked {
        background-color: #E9F2FC;
      }
      .el-radio__input {
        display: none;
        & + .el-radio__label {
          color: $--color-text-secondary;
        }
        &.is-checked + .el-radio__label {
          color: #267CDC;
        }
      }
      .el-radio__label {
        padding-left: 0;
        font-size: 16px;
      }
    }
  }
  .divider {
    border-top: 1px solid $--border-color-base;
  }
  .steps {
    margin-top: 32px;
    margin-bottom: 50px;
  }
  .summary {
    display: flex;
    width: 100%;
    min-height: 134px;
    padding: 20px 0;
    margin-bottom: 10px;
    font-size: $--font-size-base;
    color: $--color-text-primary;
    background: #F7F8FA;
    .block-left {
      width: 81.5%;
      padding: 12px 24px 0;
      .info {
        .el-row {
          .el-col {
            display: flex;
          }
        }
        .label {
          flex-shrink: 0;
          align-self: flex-start;
          color: $--color-text-secondary;
        }
        .desc-row {
          margin-top: 16px;
          .desc {
            text-align: justify;
            word-wrap: break-word;
          }
        }
      }
    }
    .block-right {
      display: flex;
      width: calc(100% - 81.5%);
      justify-content: center;
      align-items: center;
      border-left: 1px solid $--border-color-base;
      min-width: 280px;
      .operator {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .btn {
          margin: 0 auto 12px;
          width: 150px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          padding: 0;
          &.certificate {
            margin-bottom: 0;
          }
        }
        .tips {
          color: $--color-warning;
        }
        .result-title {
          font-size: 20px;
          font-weight: 600;
        }
        .tips-icon {
          position: absolute;
          top: 8px;
          right: -25px;
          font-size: 16px;
          color: $--color-text-secondary;
        }
      }
    }
  }
}
</style>
