
import timeFormat from '@/utils/timeFormat.js'

// 搜索表单配置
let formData = [
  {
    items: {
      loginAccount: {
        label: '账号',
        type: 'text',
        clearable: true,
        rules: [
          { max: 20, message: '最长输入 20 个字符', trigger: 'change' }
        ]
      },
      userName: {
        label: '昵称',
        type: 'text',
        clearable: true,
        rules: [
          { max: 20, message: '最长输入 20 个字符', trigger: 'change' }
        ]
      }
    }
  }
]

// 表头图标配置
let headData = {
  title: '用户列表',
  items: [
    /* 新增按钮 */
    // {
    //   style: 'button',
    //   label: '新增',
    //   icon: 'ym-icon-tianjia',
    //   size: 'medium',
    //   type: 'primary',
    //   clickEvent: (thisVue) => {
    //     thisVue.$router.push({ name: 'user-add' })
    //   }
    // }
  ]
}

// 列表columns配置
let columnsData = [
  {
    label: '用户id',
    prop: 'userId',
    show: false
  },
  {
    type: '占位栏',
    show: true,
    width: '22'
  },
  {
    label: '账号',
    prop: 'loginAccount',
    show: true
    // minWidth: '120'
  },
  {
    label: '姓名',
    prop: 'userName',
    show: true,
    minWidth: '80',
    enableSlot: true
  },
  {
    label: '用户角色',
    prop: 'groupNameList',
    show: true,
    enableSlot: true
  },
  {
    label: '创建时间',
    prop: 'createTime',
    show: true,
    minWidth: '120',
    formatter: (row, column, cellValue, index) => {
      return timeFormat(cellValue, 'YYYY-MM-DD HH:mm:ss')
    }
  },
  {
    label: '操作',
    prop: 'operators',
    minWidth: '120',
    fixed: 'right',
    children: {
      edit: {
        label: '编辑',
        icon: 'ym-icon-bianji',
        clickEvent: (thisVue, row) => {
          thisVue.userData = row
          thisVue.editUserDialogVisible = true
          // thisVue.$router.push({ name: 'user-edit', query: { userId: row.userId, userName: row.userName, groupIds: row.groupIds[0] } })
        },
        showFilter: (item, row, thisVue) => {
          return row.userId !== '1' && (!thisVue.$parent.isAdmin(row) || (thisVue.$parent.isAdmin(row) && thisVue.$parent.hasPermission()))
        }
      },
      delete: {
        label: '删除',
        icon: 'ym-icon-shanchu',
        color: '#FF8851',
        clickEvent: (thisVue, row) => {
          thisVue.$confirm('此操作将删除该用户,是否确定?', '警告', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            thisVue.deleteRow(row)
          }).catch(() => {

          })
        },
        showFilter: (item, row, thisVue) => {
          return row.userId !== '1' && (!thisVue.$parent.isAdmin(row) || (thisVue.$parent.isAdmin(row) && thisVue.$parent.hasPermission()))
        }
      },
      rset: {
        label: '重置密码',
        icon: 'ym-icon-shuaxin',
        color: '#FF8851',
        clickEvent: (thisVue, row) => {
          thisVue.$confirm('此操作将重置该用户密码,是否确定?', '警告', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            thisVue.resetUserPassword(row)
          }).catch(() => {

          })
        },
        showFilter: (item, row, thisVue) => {
          return row.userId !== '1' && (!thisVue.$parent.isAdmin(row) || (thisVue.$parent.isAdmin(row) && thisVue.$parent.hasPermission()))
        }
      }
    }
  }
]

// 分页配置项
let pageData = {
  size: 10, // 每页的数据量
  total: 0, // 数据总量
  layout: 'prev, pager, next, total, jumper, slot',
  page: 1 // 当前页
}

// 新增用户配置
let addFormData = [
  {
    items: {
      loginAccount: {
        label: '登录账号',
        type: 'text',
        rules: [
          { required: true, message: '请输入登录账号', trigger: 'change' },
          { min: 4, max: 20, message: '输入 4-20 个字符', trigger: 'change' },
          { pattern: /^[a-zA-Z]\w*$/, message: '只能输入数字字母下划线,且只能以字母开头' }
        ]
      },
      formPassword: {
        label: '登录密码',
        type: 'password',
        showPassword: true,
        rules: [
          { required: true, message: '请输入登录密码', trigger: 'change' },
          { min: 6, max: 16, message: '请输入 6 至 16 个字符', trigger: 'change' }
        ]
      },
      confirmPassword: {
        label: '确认密码',
        type: 'password',
        showPassword: true,
        rules: [
          { required: true, message: '请输入登录密码', trigger: 'change' },
          { min: 6, max: 16, message: '请输入 6 至 16 个字符', trigger: 'change' }
        ]
      },
      userName: {
        label: '用户昵称',
        type: 'text',
        rules: [
          { max: 20, message: '最长输入 20 个字符', trigger: 'change' }
        ]
      },
      deptId: {
        label: '所属机构',
        type: 'text',
        rules: [
          { required: true, message: '请输入所属机构', trigger: 'change' },
          { max: 20, message: '最长输入 20 个字符', trigger: 'change' }
        ]
      },
      userEmail: {
        label: '邮箱',
        type: 'text',
        rules: [
          { max: 20, message: '最长输入 20 个字符', trigger: 'change' }
        ]
      },
      mobilePhone: {
        label: '联系电话',
        type: 'text',
        rules: [
          { max: 20, message: '最长输入 20 个字符', trigger: 'change' },
          { pattern: /^[0-9]*$/, message: '只能输入数字' }
        ]
      },
      userGroup: {
        label: '所属用户组',
        type: 'select',
        rules: [
          { required: true, message: '请选择用户组', trigger: 'change' }
        ],
        children: []
      }

    }
  }
]

let editFormData = [
  {
    items: {
      userName: {
        label: '昵称',
        type: 'text',
        rules: [
          { max: 20, message: '最长输入 20 个字符', trigger: 'change' }
        ]
      },
      userGroup: {
        label: '所属用户组',
        type: 'select',
        rules: [
          { required: true, message: '请选择用户组', trigger: 'change' }
        ],
        children: [
        ]
      }
    }
  }
]

let tableObj = {
  'formData': formData, // 顶部搜索表单配置
  'columnsData': columnsData, // 表格列/操作列配置
  'headData': headData, // 表头图标配置
  'pageData': pageData, // 分页数据
  'addFormData': addFormData, // 新增用户表单
  'editFormData': editFormData // 编辑用户表单
}

export default tableObj
