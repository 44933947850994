<template>
  <div class='role-edit' v-loading="loading">
    <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="medium" class="role-edit-form">
      <el-form-item label="角色名" prop="roleName">
        <el-input v-model="form.roleName"></el-input>
      </el-form-item>
      <el-form-item label="角色描述" prop="roleDescription">
        <el-input v-model="form.roleDescription" type="textarea" :rows="3"></el-input>
      </el-form-item>
      <el-form-item label="权限配置">
        <div class="role-edit-form-tree">
          <el-tree class="filter-tree" :filter-node-method="filterNode" ref="menuTree" :data="treeData" node-key="id" show-checkbox default-expand-all></el-tree>
        </div>
      </el-form-item>

      <el-form-item label="功能配置">
        <div>
          <el-checkbox-group v-model="dataPermission">
            <el-checkbox v-for="item in dataPermissionList" :key="item" :label="item"></el-checkbox>
          </el-checkbox-group>
        </div>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitConfirm">确定</el-button>
        <el-button @click="goBack=>{this.$router.go(-1)}">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { toTree } from '@/utils/arrToTree'

export default {
  data () {
    return {
      loading: false, // loading加载框
      roleId: null, // 角色id
      form: {},
      rules: {
        roleName: [
          { required: true, message: '请输入角色名', trigger: 'change' },
          { min: 4, max: 16, message: '长度 4-16 个字符', trigger: 'change' },
          { pattern: /^[a-zA-Z]\w*$/, message: '只能输入数字字母下划线,且只能以字母开头' }
        ]
      },
      treeData: [],
      dataPermissionList: [],
      dataPermission: []
    }
  },
  mounted() {
    this.initRouter()
    this.initDataPermission()
  },
  methods: {
    submitConfirm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.onSubmit()
        }
      })
    },
    filterNode(value, data, node) {
      // return !data.hidden
      return true
    },
    // 初始化权限配置界面
    async initRouter() {
      const { data } = await this.$api.userRole.getRouter()
      if (!(data.code === 0 && data.data)) {
        this.$message.error('获取菜单目录失败')
        return
      }
      this.treeData = this.changeRouterToTree(data.data)
      this.$nextTick(() => {
        this.$refs.menuTree.filter()
      })
      this.roleId = this.$route.query.roleId
      // 编辑页面,回显数据
      if (this.roleId) {
        const { data } = await this.$api.userRole.getUserRole(this.roleId)
        if (data.code === 0) {
          this.form = data.data
          if (data.data.functionTags) {
            let treeChecked = JSON.parse(JSON.stringify(data.data.functionTags))
            for (let key in treeChecked) {
              if (treeChecked[key].children) {
                delete treeChecked[key]
              }
            }
            this.$refs.menuTree.setCheckedNodes(treeChecked)
          }
          if (data.data.permissionTags) {
            this.dataPermission = data.data.permissionTags
          }
        } else {
          this.$message.error('查询角色失败' + data.message)
        }
      }
    },
    // 初始化功能配置界面
    async initDataPermission() {
      try {
        const { data } = await this.$api.user.getDataPermission()
        if (data.code === 0) {
          this.dataPermissionList = data.data
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    // 将路由数据改为可以由el-tree显示的数据格式
    changeRouterToTree(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].meta && data[i].meta.title) {
          data[i].label = data[i].meta.title
        }
      }
      data = toTree(data, 'parentId', 'id')
      return data
    },
    // 提交事件
    async onSubmit() {
      this.loading = true
      try {
        // 获取选择的菜单
        this.form.functionTags = this.$refs.menuTree.getCheckedNodes(false, true)
        /*         for (let key in this.form.functionTags) {
          if (this.form.functionTags[key].children) delete this.form.functionTags[key].children
        } */
        console.log(this.form.functionTags)
        if (this.form.functionTags.length === 0) {
          this.$message('请选择关联菜单')
          this.loading = false
          return
        }
        if (this.roleId) {
        // 编辑
          this.$set(this.form, 'permissionTags', this.dataPermission)
          const { data } = await this.$api.userRole.updateUserRole(this.form)
          console.log({ '提交表单': this.form, '返回数据': data })
          if (data.code === 0 && data.message) {
            this.$router.push({ name: 'userRole-list', params: { action: 'edit-success' } })
          } else {
            this.$message.error('编辑角色失败' + (data.message || ''))
          }
        } else {
        // 新增
          this.$set(this.form, 'permissionTags', this.dataPermission)
          const { data } = await this.$api.userRole.addUserRole(this.form)
          console.log({ '提交表单': this.form, '返回数据': data })
          if (data.code === 0 && data.message) {
            this.$router.push({ name: 'userRole-list', params: { action: 'add-success' } })
          } else {
            this.$message.error('创建角色失败' + (data.message || ''))
          }
        }
      } catch (e) {
        this.loading = false
        console.log(e)
        this.$message.error('提交表单失败')
      }
      this.loading = false
    }
  }
}
</script>

<style lang='scss' scoped>
.role-edit {
  background: #fff;
  padding: 30px;

  .role-edit-form{
    max-width: 600px;

    .role-edit-form-tree{
      border: 1px solid #DCDFE6;
      padding: 15px;
      border-radius: 4px;
    }
  }
}
</style>
