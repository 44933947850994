<template>
  <div class="value-details">
    <template v-for="deptData in deptDataList">
      <value-detail-form :key="deptData.deptId" :deptData="deptData" v-loading="loading" :isOperatorOrRecorder="isOperatorOrRecorder" @submitFinished="submitFinished"></value-detail-form>
    </template>
  </div>
</template>

<script>
import ValueDetailForm from './component/valueDetailForm.vue'

export default {
  components: {
    ValueDetailForm
  },
  props: {
    archiveId: {
      type: String,
      default: ''
    },
    isOperatorOrRecorder: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      deptDataList: [],
      loading: false
    }
  },
  mounted() {
    this.getValueDetail()
  },
  methods: {
    async getValueDetail() {
      try {
        this.loading = true
        const { data } = await this.$api.finalIdentify.getValueDetail({
          archiveId: this.archiveId
        })
        if (data.code === 0) {
          this.loading = false
          this.deptDataList = data.data
          console.log(this.deptDataList)
        } else {
          this.loading = false
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        this.loading = false
        throw new Error(err)
      }
    },
    submitFinished() {
      this.$emit('submitFinished')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
