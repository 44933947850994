<template>
  <div class='user-add'>
    <ymForm ref="userForm" :formValue="formValue" :formData="formData" class="user-add-form" @onSubmit="onSubmit"></ymForm>
    <div class="user-add-btn">
      <el-button type="primary" @click="submitForm('userForm')">确定</el-button>
      <el-button @click="goBack=>{this.$router.go(-1)}">返回</el-button>
    </div>
  </div>
</template>

<script>
import ymForm from '@/components/YmForm'
import tableObj from './data.js'
const crypto = require('crypto')
export default {
  components: {
    ymForm
  },
  data () {
    return {
      formValue: {
        groupIds: []
      },
      formData: tableObj.addFormData
    }
  },
  mounted() {
    this.getUserGroup()
  },
  methods: {
    // 点击提交按钮,触发提交事件
    submitForm(id) {
      if (this.formValue.confirmPassword !== this.formValue.formPassword) {
        this.$message.error('两次密码不一致')
        return
      }
      this.$refs[id].onSubmit()
    },
    // 提交事件
    async onSubmit(formValue, ref) {
      this.loading = true
      try {
        let groupIds = []
        groupIds.push(this.formValue.userGroup)
        this.formValue.groupIds = groupIds
        this.$set(this.formValue, 'loginPassword', this.encrypt(this.formValue.formPassword))
        const { data } = await this.$api.user.addUser(this.formValue)
        if (data.code === 0 && data.message) {
          this.$router.push({ name: 'user-list', params: { action: 'add-success' } })
        } else {
          console.log(data)
          this.$message.error('新增用户失败' + (data.message || ''))
        }
      } catch (e) {
        this.loading = false
        console.log(e)
        this.$message.error('提交表单失败')
      }

      this.loading = false
    },
    // 哈希加密(SHA1)
    encrypt(password) {
      const hash = crypto.createHash('sha1')
      hash.update(password)
      return hash.digest('hex')
    },
    // 获取全部用户组列表, 初始化用户组下拉框
    async getUserGroup() {
      let { data } = await this.$api.userGroup.getUserGroup()
      console.log({ '查询全部用户组': data, '表单配置': this.formData })
      if (data.code === 0 && data.data) {
        let userGroupFormData = []
        for (var key in data.data) {
          userGroupFormData.push({ label: data.data[key].groupName, value: data.data[key].groupId })
        }
        this.formData[0].items.userGroup.children = userGroupFormData
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.user-add {
  background: #fff;
  padding: 30px 60px;
  .user-add-form{
    max-width: 600px;
  }

  .user-add-btn{
    margin: 10px 40px;
    button{
      margin: 0 20px;
    }
  }
}

</style>
