<template>
  <div class="application">
    <div class="ym-panel-radius clearfix">
      <div class="btns btns-top">
        <el-button type="primary" plain @click="handleBack">返回</el-button>
      </div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="终鉴申请" name="tab">
          <el-card v-loading="loading.overview">
            <div class="ym-head clearfix">
              <div class="ym-title-border">档案信息概览</div>
            </div>
            <div class="content">
              <div class="pic img">
                <el-image :src="picUrl"></el-image>
                <span class="demonstration">前视图</span>
              </div>
              <div class="info">
                <el-form :model="overviewForm" label-width="56px" size="small">
                  <el-row :gutter="16">
                    <el-col :span="12">
                      <el-form-item label="名称">
                        <p>{{overviewForm.name}}</p>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="16">
                    <el-col :span="12">
                      <el-form-item label="档案ID">
                        <p>{{archiveId}}</p>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="16">
                    <el-col :span="12">
                      <el-form-item label="分类">
                        <p>{{overviewForm.category}}</p>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="16">
                    <el-col :span="12">
                      <el-form-item label="存储地">
                        <p>{{overviewForm.storage}}</p>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
              <div class="qrcode img">
                <el-image :src="qrcodeUrl"></el-image>
                <span class="demonstration">明码</span>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="ym-head clearfix">
              <div class="ym-title-border">确真机构选择</div>
            </div>
            <div>
              <div class="search-bar">
                <el-row :gutter="16">
                  <el-col :span="6">
                    <el-input v-model="authenticity.deptName" size="small" prefix-icon="el-icon-search" placeholder="请输入机构名称" clearable @change="handleSearch"></el-input>
                  </el-col>
                  <el-col :span="12">
                    <el-select v-model="authenticity.options" size="small" placeholder="筛选鉴定项目" multiple clearable popper-class="identify-type-popper" class="identify-type-select" @change="handleSearch">
                      <el-option-group v-for="group in identifyList"
                                    :key="group.detectMethod"
                                    :label="group.detectMethod">
                        <el-option v-for="item in group.options"
                                  :key="item.detectItemId"
                                  :label="item.detectItemName"
                                  :value="item.detectItemId"></el-option>
                      </el-option-group>
                    </el-select>
                  </el-col>
                </el-row>
              </div>
              <div class="table-list">
                <ym-table-list :loading="loading.authenticity"
                               :data="listData"
                               :columns="listColumns"
                               :pageData="pageData"
                               :tableOption="tableOption"
                               :hasBottomInfo="true"
                               ref="authenticityList"
                               @selection-change="(val) => handleSelectionChange(val, 'authenticity')"
                               @select="checkSelectStatus"
                               @handlePageChange="handlePageChange">
                  <template #options="{row}">
                    <div class="options-col">
                      <!-- TODO: 多选栏折叠 -->
                      <el-checkbox-group v-model="options[row[dist.id]]"
                                        :ref="'options-' + row[dist.id]"
                                        size="small"
                                        @change="(val) => handleOptionSelect(val, row)"
                                        :disabled="checkDisabled(row[dist.id])"
                                        :class="['options-checkbox']"
                                        :max="5">
                        <el-checkbox v-for="item in row.detectItems" :key="item.detectItemId" :label="item.detectItemId" border>{{item.detectItemName}}</el-checkbox>
                      </el-checkbox-group>
                    </div>
                  </template>
                  <template #bottom>
                    <span class="select-tips">
                      <span>已选: <span class="select-tips__highlight">{{selectedDept.authenticity.length}} </span>个机构&nbsp;&nbsp;&nbsp;&nbsp;共<span class="select-tips__highlight"> {{optionsSum}} </span>项鉴定</span>
                    </span>
                  </template>
                </ym-table-list>
              </div>
            </div>
          </el-card>
          <!-- 确权机构 -->
          <selection-table title="确权机构选择" deptType="终鉴-确权" @selectChange="(val, info) => selectChange(val, info, 'notarization')"></selection-table>
          <!-- 确值机构 -->
          <selection-table title="确值机构选择" deptType="终鉴-确值" :maxSelected="3" @selectChange="(val, info) => selectChange(val, info, 'value')"></selection-table>
          <!-- 法律意见机构 -->
          <selection-table title="法律意见机构选择" deptType="终鉴-法律" @selectChange="(val, info) => selectChange(val, info, 'law')"></selection-table>
        </el-tab-pane>
        <div class="btn-block">
          <el-button type="primary" @click="handleApply">提交申请</el-button>
        </div>
      </el-tabs>
    </div>
    <el-dialog :visible.sync="dialogVisible"
               title="终鉴申请确认"
               top="120px"
               width="600px"
               custom-class="apply-dialog"
               :close-on-click-modal="false">
      <span class="tips">(请慎重确定，确定后不可以重选机构)</span>
      <el-form ref="form"
               label-width="125px"
               class="apply-form"
               :model="selectedDept">
        <el-row :gutter="16">
          <el-col :span="22">
            <el-form-item prop="authenticity" label="确真机构">
              <div class="dept-label">
                <template v-for="dept in temp">
                  <div class="label-tag" :key="dept[dist.id]">
                    <el-tag type="info">{{dept[dist.label]}}</el-tag>
                  </div>
                </template>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="22">
            <el-form-item prop="notarization" label="确权机构">
              <div class="label-tag"><el-tag type="info">{{selectedDeptName.notarization}}</el-tag></div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="22">
            <el-form-item prop="value" label="确值机构">
              <div class="dept-label">
                <template v-for="dept in selectedDeptName.value">
                  <div class="label-tag" :key="dept">
                    <el-tag type="info">{{dept}}</el-tag>
                  </div>
                </template>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="22">
            <el-form-item prop="law" label="法律机构">
              <div class="label-tag"><el-tag type="info">{{selectedDeptName.law}}</el-tag></div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="submitApply" :loading="loading.apply">{{this.loading.apply ? '申请中' : '确 定'}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'
import SelectionTable from './component/selectionTable.vue'

export default {
  components: {
    YmTableList,
    SelectionTable
  },
  data() {
    return {
      loading: {
        overview: false,
        authenticity: false,
        apply: false
      },
      archiveId: '', // 档案ID
      activeName: 'tab',
      overviewForm: {
        name: '',
        category: '',
        storage: ''
      }, // 概览
      picUrl: '', // 前视图url
      qrcodeUrl: '', // 明码url
      authenticity: {
        deptName: '',
        options: []
      },
      identifyList: [], // 确真鉴定项目
      tableOption: {
        'row-key': 'deptId'
      },
      listData: [],
      listColumns: [
        {
          type: 'selection',
          selectable: this.checkSelectable,
          'reserve-selection': true,
          show: true
        },
        {
          prop: 'deptName',
          label: '鉴定机构名称',
          show: true,
          'min-width': '150px',
          'show-overflow-tooltip': true
        },
        {
          prop: 'options',
          label: '点击选择鉴定项目',
          show: true,
          enableSlot: true,
          'class-name': 'options-col',
          'min-width': '500px'
        }
      ],
      options: {}, // 鉴定项目选择
      collapseBtnOff: {}, // 确真列表鉴定项目折叠按钮
      collapseMark: [],
      selectedDept: { // 存储所选机构的ID
        authenticity: [],
        notarization: '',
        value: [],
        law: ''
      },
      selectedDeptName: { // 存储所选机构的名字
        notarization: '',
        value: [],
        law: ''
      },
      temp: [], // 存储所选确真机构的信息
      pageData: {
        size: 10, // 每页的数据量
        total: 13, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      },
      dialogVisible: false,
      dist: {
        id: 'deptId',
        label: 'deptName',
        value: 'deptId'
      },
      optionsSum: 0 // 选择确真机构项目的总数
    }
  },
  mounted() {
    this.archiveId = this.$route.params.archiveId
    this.getRecord()
    this.getIdentifyItems()
    this.getDeptList('authenticity')
    console.log(this.archiveId)
  },
  methods: {
    handleBack() {
      this.$router.back()
    },
    async getRecord() {
      this.$set(this.loading, 'overview', true)
      try {
        const { data } = await this.$api.record.getRecord({
          archiveId: this.archiveId
        })
        if (data.code === 0) {
          console.log(data.data)
          this.picUrl = this.FileUrl + data.data.picture.mainPicture.front
          this.qrcodeUrl = this.FileUrl + data.data.plainCode
          this.$set(this.overviewForm, 'name', data.data.name)
          this.$set(this.overviewForm, 'category', data.data.category)
          this.$set(this.overviewForm, 'storage', data.data.storage)
          this.$set(this.loading, 'overview', false)
        } else {
          this.$set(this.loading, 'overview', false)
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        this.$set(this.loading, 'overview', false)
        throw new Error(err)
      }
    },
    async getDeptList(flag = 'authenticity') {
      if (flag === 'authenticity') {
        // 确真
        try {
          this.$set(this.loading, 'authenticity', true)
          const { data } = await this.$api.finalIdentify.getAuthenticityDeptList({
            page: this.pageData.currentPage,
            size: this.pageData.size,
            deptName: this.authenticity.deptName,
            detectItemIds: this.authenticity.options
          })
          if (data.code === 0) {
            this.listData = data.data.records
            this.pageData.total = data.data.total
            for (let i = 0; i < this.listData.length; i++) {
              // 初始化key为确真机构id的数组用于存放选择确真机构的检测项目
              !this.options[this.listData[i][this.dist.id]] && this.$set(this.options, this.listData[i][this.dist.id], [])
            }
            this.$set(this.loading, 'authenticity', false)
          } else {
            this.$set(this.loading, 'authenticity', false)
            this.$message({
              type: 'error',
              message: data.message
            })
          }
        } catch (err) {
          this.$set(this.loading, 'authenticity', false)
          throw new Error(err)
        }
      }
    },
    handleSearch() {
      this.$set(this.pageData, 'currentPage', 1)
      this.getDeptList()
    },
    // 判断鉴定机构选项数目是否超过一行
    checkColHeight(rowRef, id) {
      console.log(this.$refs[rowRef])
      if (this.$refs[rowRef] && this.$refs[rowRef].$el && this.$refs[rowRef].$el.offsetHeight > 38) {
        this.collapseMark.push(id)
        return true
      } else if (this.collapseMark.includes(id)) {
        return true
      } else {
        return false
      }
    },
    checkCollapseMark(id) {
      return this.collapseMark.includes(id)
    },
    // 点击展开/折叠按钮
    handleCollapseClick(id) {
      this.$set(this.collapseBtnOff, id, !this.collapseBtnOff[id])
      console.log(this.collapseBtnOff)
    },
    // 判断已选项是否超过2个
    checkSelectable(row, index) {
      return this.selectedDept.authenticity.length >= 2 ? this.selectedDept.authenticity.includes(row[this.dist.id]) : true
    },
    // 判断确真机构的项目是否可选
    checkDisabled(id) {
      if (this.selectedDept.authenticity.length < 2) {
        return false
      } else {
        return !this.selectedDept.authenticity.includes(id)
      }
    },
    // 确真机构选择更变
    handleSelectionChange(val, flag) {
      console.log(val)
      if (flag === 'authenticity') {
        let arr = []
        val.forEach(item => {
          arr.push(item[this.dist.id])
        })
        this.$set(this.selectedDept, flag, arr)
        this.temp = val
        this.countOptions()
      }
    },
    // 计算一共选择了多少确真机构的检测项目
    countOptions() {
      console.log(this.selectedDept)
      let count = 0
      this.selectedDept.authenticity.forEach(item => {
        count += this.options[item].length
      })
      this.optionsSum = count
    },
    // 查询勾选状态，若为取消勾选，则清空该机构的已选鉴定项目
    checkSelectStatus(selection, row) {
      console.log(selection)
      console.log(row)
      if (!selection.find(item => item[this.dist.id] === row[this.dist.id])) {
        this.$set(this.options, row[this.dist.id], [])
      }
    },
    // 机构的鉴定项目更变
    handleOptionSelect(val, row) {
      console.log(val)
      console.log(this.options)
      // 点击鉴定项目时，自动勾选当前机构
      this.$refs.authenticityList.$children[0].toggleRowSelection(row, true)
      this.countOptions()
    },
    handlePageChange(page) {
      this.$set(this.pageData, 'currentPage', page)
      console.log(this.pageData)
      this.getDeptList('authenticity')
    },
    // 子组件机构选择更变
    selectChange(val, info, deptType) {
      this.$set(this.selectedDept, deptType, val)
      this.$set(this.selectedDeptName, deptType, info)
    },
    // 打开确认弹窗
    handleApply() {
      this.dialogVisible = this.checkDeptSelect()
    },
    checkDeptSelect() {
      let lackDept = new Set()
      Object.keys(this.selectedDept).forEach(key => {
        if (Array.isArray(this.selectedDept[key]) && this.selectedDept[key].length === 0) {
          lackDept.add(key)
        } else if (key === 'authenticity' && this.selectedDept[key].length > 0) {
          this.selectedDept[key].forEach(deptId => {
            if (this.options[deptId].length === 0) {
              lackDept.add(key)
            }
          })
        } else if (!Array.isArray(this.selectedDept[key]) && !this.selectedDept[key]) {
          lackDept.add(key)
        }
      })
      console.log(lackDept)
      if (lackDept.size > 0) {
        this.showLackOfMessage(lackDept)
        return false
      } else {
        return true
      }
    },
    // 提交申请时对没有选择的鉴定类型的题型
    showLackOfMessage(lackDept) {
      let deptDist = {
        authenticity: '确真机构',
        notarization: '确权机构',
        value: '确值机构',
        law: '法律意见机构'
      }
      let arr = []
      lackDept.forEach(deptKey => {
        arr.push(deptDist[deptKey])
      })
      this.$message({
        type: 'error',
        message: `存在未选择机构或鉴定项目(${arr.toString()})，无法提交申请`
      })
    },
    async submitApply() {
      this.$set(this.loading, 'apply', true)
      const identifyRealList = []
      this.selectedDept.authenticity.forEach(deptId => {
        identifyRealList.push({
          deptId: deptId,
          detectItemIds: this.options[deptId]
        })
      })
      const identifyValueList = this.selectedDept.value.map(item => ({ deptId: item }))
      try {
        const { data } = await this.$api.finalIdentify.applyFinalIdentify({
          archiveId: this.archiveId,
          identifyRealList: identifyRealList,
          identifyAuthorityList: [{ deptId: this.selectedDept.notarization }],
          identifyValueList: identifyValueList,
          identifyLegalList: [{ deptId: this.selectedDept.law }]
        })
        if (data.code === 0) {
          this.$set(this.loading, 'apply', false)
          this.$message({
            type: 'success',
            message: '申请成功'
          })
          this.handleBack()
        } else {
          this.$set(this.loading, 'apply', false)
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        this.$set(this.loading, 'apply', false)
        throw new Error(err)
      }
    },
    // 获取终鉴鉴定项目列表
    async getIdentifyItems() {
      try {
        const { data } = await this.$api.department.getIdentifyItems()
        if (data.code === 0) {
          this.handleIdentifyItems(data.data)
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (error) {
        throw new Error(error)
      }
    },
    // 处理鉴定项目
    handleIdentifyItems(data) {
      let category = []
      data.forEach(item => {
        const res = category.find(type => type.detectMethod === item.detectMethod)
        if (!res) {
          category.push({
            detectMethod: item.detectMethod,
            options: [{
              detectItemName: item.detectItemName,
              detectItemId: item.detectItemId
            }]
          })
        } else {
          res.options.push({
            detectItemName: item.detectItemName,
            detectItemId: item.detectItemId
          })
        }
      })
      this.identifyList = category
    }
  }
}
</script>

<style lang="scss">
.identify-type-popper {
  .el-select-group__title {
    color: $--color-text-secondary;
  }
}
.apply-dialog {
  .el-dialog__body {
    padding-bottom: 0;
  }
  .dialog-footer {
    .el-button {
      width: 72px;
      &+ .el-button {
        margin-left: 16px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
$marginBottom: 6px;
.application {
  min-width: 837px;
  background-color: #fff;
  ::v-deep .el-card__body {
    padding: 24px 24px 24px 24px;
    // max-width: 960px;
  }
  ::v-deep .el-tabs {
    .el-tabs__header {
      margin-bottom: 28px;
      .el-tabs__nav-wrap {
        .el-tabs__item {
          font-size: 16px;
        }
        &:after {
          background-color: transparent;
        }
      }
    }
  }
  .ym-panel-radius {
    position: relative;
  }
  .btns{
    text-align: right;
    &.btns-top {
      position: absolute;
      right: 24px;
      z-index: 999;
    }
  }
  .content {
    display: flex;
    // flex: 1 0 auto;
    .img {
      display: flex;
      flex-direction: column;
      align-items: center;
      .el-image {
        margin: 10px 0;
      }
      span {
        font-size: 12px;
      }
    }
    .pic {
      width: calc(300px * 0.8);
      height: calc(200px * 0.8);
      color: $--color-text-secondary;
    }
    .qrcode {
      width: 160px;
      height: 160px;
      margin-right: 48px;
      color: $--color-text-secondary;
    }
    .info {
      flex: 1;
      margin-left: 10%;
      ::v-deep.el-form {
        .el-form-item {
          margin-bottom: 0;
          .el-form-item__label {
            text-align: justify;
            height: 24px;
            &:after {
              content: '';
              display: inline-block;
              width: 100%;
            }
          }
        }
      }
    }
  }
  .search-bar {
    margin-bottom: 12px;
    ::v-deep.identify-type-select {
      .el-select__tags {
        .el-select__tags-text {
          color: $--color-text-primary;
        }
        .el-tag__close {
          background-color: #f0f2f5;
          color: #a3a3a3;
        }
      }
    }
    .el-select {
      width: 100%;
    }
  }
  ::v-deep.table-list {
    .el-table__header-wrapper {
      .el-checkbox{
        display:none
      }
      .el-table__header {
        thead tr {
          .options-col {
            text-align: center;
          }
        }
      }
    }
    .el-table__row {
      td:last-child {
        padding-bottom: calc(12px - #{$marginBottom});
      }
    }
    .options-checkbox {
      // width: calc(100% - 38px);
      .el-checkbox {
        margin-right: 10px;
        margin-left: 0;
        margin-bottom: $marginBottom;
        padding: 5px 15px;
      }
      .el-checkbox__input {
        display: none;
      }
      .el-checkbox__label {
        padding-left: 0;
      }
    }
  }
  .btn-block {
    margin-top: 36px;
    margin-bottom: 16px;
    text-align: center;
  }
  .tips {
    position: absolute;
    color: $--color-danger;
    top: 14px;
    left: 106px;
    font-size: 12px;
  }
  ::v-deep.apply-form {
    .el-form-item {
      margin-bottom: 8px;
      .el-form-item__label, .el-form-item__content {
        line-height: 30px;
      }
    }
    .apply-label {
      border: 1px solid $--border-color-base;
    }
  }
  .dept-label {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .label-tag {
    margin-bottom: 8px;
    padding-right: 8px;
  }
  .el-tag {
    &.el-tag--info {
      background-color: #f4f4f5;
      border-color: #e9e9eb;
      color: #909399;
    }
  }
  .select-tips {
    font-size: 14px;
    line-height: 32px;
    color: $--color-text-secondary;
    &__highlight {
      color: $--color-primary;
    }
  }
}
</style>
