<template>
<!-- 公共上传文件组件 -->
<!-- uploadFileUrl是在src/config/index.js配置的文件上传路径 -->
<div class="upload-comp">
  <!-- 图片预览 -->
  <div v-if="previewList && previewList.length > 0" class="preview">
    <el-image :src="compressList[0]" :fit="fit" :preview-src-list="previewList" lazy></el-image>
    <div class="bottom">查看原图</div>
    <i v-if="!disabled" class="el-icon-circle-close close" @click="onRemove"></i>
  </div>
  <el-upload
    :ref="ref"
    v-if="!previewList || previewList.length < limit"
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    class="img-uploader"
    :with-credentials="false"
    :action="action"
    :name="name"
    :disabled="disabled"
    :limit="limit"
    :show-file-list="showFileList"
    :file-list="fileList"
    :on-exceed="onExceed"
    :on-success="onSuccess"
    :on-error="onError"
    :before-upload="beforeUpload"
    :on-remove="onRemove"
    :headers="headers">

    <slot>
      <el-button>上传</el-button>
    </slot>
    <span slot="tip" class="img-uploader__slot"><slot name="tip"></slot></span>

  </el-upload>
</div>
</template>

<script>
import { getToken } from '@/utils/localStorage.js'
import config from '@/config/index.js'

export default {
  name: 'upload',
  props: {
    action: {
      type: String,
      default: config.uploadFileUrl
    },
    fileList: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 预览的文件列表,因el-upload的fileList不可随意更改，所以fileList属性只用来初始化已有图片，previewList用来同步预览的文件列表
    previewList: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 缩略图
    compressList: {
      type: Array,
      default: () => {
        return []
      }
    },
    limit: {
      type: Number,
      default: 1
    },
    // 是否显示已上传文件列表
    showFileList: {
      type: Boolean,
      default: true
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 文件对应字段，看接口要求
    name: {
      type: String,
      default: 'multipartFile'
    },
    // 上传接口需要传入的businessType字段：业务类型 order=订单，company=公司文件，user=用户文件
    businessType: {
      type: String,
      default: 'company'
    },
    // 文件格式不匹配的错误提示文本
    typeErrorMsg: {
      type: String,
      default: '请按要求的格式上传文件'
    },
    // 文件大小限制，k为单位，默认限制1M以内，即1024k
    sizeLimit: {
      type: Number,
      default: 1024
    },
    // 用正则来校验fileName的后缀名，来校验文件类型，考虑Mac上的兼容性，通过fileName来判断文件类型，默认支持图片类型
    acceptTypes: {
      type: RegExp,
      default: () => {
        return /.jpg$|.jpeg$|.png$|.bmp$|.JPG$|.JPEG$|.PNG$|.BMP$/
      }
    },
    headers: {
      type: Object,
      default: () => {
        return { 'dreamkey-token': getToken() }
      }
    },
    fit: {
      type: String,
      default: 'cover'
    }
  },
  data() {
    return {
      loading: false,
      ref: 'dragUpload_' + Math.floor(Math.random() * 10000),
      uploadFileUrl: this.uploadFileUrl
    }
  },
  created() {
  },
  computed: {
  },
  watch: {
    params: {
      handler: function (val) {
        this.getList()
      },
      deep: true
    }
  },
  methods: {
    /** 文件上传成功 */
    onSuccess(res, file) {
      this.loading = false
      if (res.code === 0) {
        this.$emit('onSuccess', { res, file })
      } else {
        // 词句只适用于limit=1的场景
        this.$refs[this.ref].clearFiles()
        this.$message.error(res.message || '上传失败')
      }
    },
    onError(err, file, fileList) {
      console.error(err)
      this.loading = false
    },
    onRemove(file, fileList) {
      this.$emit('onRemove', { file, fileList })
    },
    onExceed() {
      this.$message.warning(`限制上传${this.limit}个文件,如果要重新上传，请先删除已选记录`)
      this.$emit('onExceed')
    },
    /**
     * 上传之前的文件检查
     * */
    beforeUpload(file) {
      const acceptTypes = this.acceptTypes
      const isLimit = file.size / 1024 < this.sizeLimit
      const fileName = file.name
      if (!fileName || !acceptTypes.test(fileName)) {
        this.$message.error(this.typeErrorMsg)
        return false
      }
      if (!isLimit) {
        this.$message.error(`上传图片大小不能超过 ${(this.sizeLimit / 1024).toFixed(2)}MB!`)
      } else {
        this.loading = true
      }

      return isLimit
    }

  }
}
</script>

<style lang="scss">
  .upload-comp{
    &>div+div{
      margin-left: 16px;
    }
    .img-uploader {
      display: inline-block;
      width: 100px;
      height: 100px;
      .el-upload{
        width: 100px;height: 100px;
        border: 1px dashed #d9d9d9;
      border-radius: 6px;cursor: pointer;
    position: relative;
    &:hover{
      border-color: $--color-primary;
    }
      }
      .avatar-uploader-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 28px;
        color: #8c939d;
      }

    }
     .el-upload-list{
        display: inline-block;
        vertical-align: top;
      }
      .img-uploader__slot{
        padding-left: 20px;
      }

    .preview{
      display: inline-block;
      width: 200px;
      height: 200px;
      position: relative;
      overflow: hidden;
      .el-image{
        width: 100%;height: 100%;
      }
      .bottom{
        cursor: context-menu;
        position: absolute;
        background-color: rgba(0,0,0,.6);
        color:#fff;
        bottom: -40px;left: 0;
        width: 100%;height: 40px;line-height: 40px;text-align: center;
        transition: all 0.3s ease;
      }
      &:hover{
        .bottom{
          transform:translate(0, -40px);
        }
      }
      .close{
        position: absolute;
        color: #ccc;
        top: 4px;right: 4px;
        font-size: 30px;
        cursor: pointer;
        &:hover{
          color: $--color-danger;
        }
      }
    }
  }
</style>
