<template>
  <div class="steps">
    <div class="step">
      <div class="icon">
        <i class="el-icon-success"></i>
        <div class="line line-right"></div>
      </div>
      <div class="content">
        <span>终鉴中</span>
      </div>
    </div>
    <div class="step">
      <div class="icon">
        <i v-if="!finishFlag" class="icon-processing">2</i>
        <i class="el-icon-success" v-else></i>
        <div :class="['line', 'line-left', { 'finish': finishFlag }]"></div>
      </div>
      <div class="content">
        <span>终鉴完成</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    finishFlag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.steps {
  display: flex;
  margin-left: -108px;
  // width: 369px;
  .step {
    width: 268px;
    .icon {
      position: relative;
      height: 28px;
      text-align: center;
      font-size: 24px;
      margin-bottom: 8px;
      color: #267CDC;
      .icon-processing {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 47%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        background-color: $--border-color-base;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        line-height: 24px;
      }
      .line {
        position: absolute;
        width: 112px;
        top: 50%;
        transform: translateY(-50%);
        border-top: 2px solid $--border-color-base;
        &-right {
          right: 0px;
          border-color: #267CDC;
        }
        &-left {
          left: 0px;
          &.finish {
            border-color: #267CDC;
          }
        }
      }

    }
    .content {
      text-align: center;
      color: $--color-text-primary;
    }

  }
}
</style>
