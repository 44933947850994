<template>
  <div class="record-details" ref="recordDetails">
    <div class="ym-panel-radius clearfix">
      <div class="btns btns-top">
        <el-button type="primary" plain @click="backConfirm" v-if="enableBack">返回</el-button>
      </div>
      <div class="final-identify-block" v-if="pageType==='record' && status === '已初鉴' && activeName === 'first' && (!hasPermission('recorder') || hasPermission('leader'))">
        <el-button type="primary" class="final-identify-btn" size="small" @click="handleApply">申请终鉴</el-button>
      </div>
      <el-tabs v-model="activeName"
               @tab-click="handleClick">
        <!-- 档案信息 -->
        <el-tab-pane label="档案信息"
                     name="first">
          <div class="fl left-content" v-loading="loading.info">
            <!-- 藏品信息 -->
            <el-form :model="form"
                     size="small"
                     :rules="rules"
                     ref="infoForm"
                     label-width="100px"
                     class="info-form">
            <el-card>
              <div class="ym-head clearfix">
              <div class="ym-title-border">藏品信息</div>
                <div class="right">
                  <el-button v-if="isEditBtn" size="small" type="primary" @click="changeEdit()">编辑</el-button>
                </div>
              </div>

                <el-row :gutter="16">
                  <el-col :span="12">
                    <el-form-item label="名称" prop="name">
                      <el-input v-if="isFormEdit" v-model="form.name" clearable placeholder="请输入名称" maxlength="40"></el-input>
                      <p v-else>{{form.name}}</p>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="归属" prop="institution">
                      <!-- <el-input v-if="isFormEdit" disabled v-model="form.institution" clearable></el-input> -->
                      <p>{{form.institution}}</p>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="16">
                  <el-col :span="12">
                    <el-form-item label="尺寸" prop="size">
                      <el-input v-if="isFormEdit" v-model="form.size" clearable placeholder="请输入尺寸" maxlength="50"></el-input>
                      <p v-else>{{form.size}}</p>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="存储地" prop="storage">
                      <el-input v-if="isFormEdit" v-model="form.storage" placeholder="请输入存储地" clearable maxlength="40"></el-input>
                      <!-- <ym-address v-if="isFormEdit"
                                  level="2"
                                  @changeData="onCityDataChange"
                                  type="text"
                                  :value="cityAddr"
                                  placeholder="请选择存储地"/> -->
                      <p v-else>{{form.storage}}</p>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="16">
                  <el-col :span="12">
                    <el-form-item label="重量" prop="weight">
                      <el-input v-if="isFormEdit" v-model="form.weight" clearable placeholder="请输入重量" maxlength="10"></el-input>
                      <p v-else>{{form.weight}}</p>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="是否认证" prop="authentication">
                      <el-select v-if="isFormEdit" v-model="form.authentication"
                                placeholder="是否认证"
                                clearable
                                filterable>
                        <el-option label="是" :value="true"></el-option>
                        <el-option label="否" :value="false"></el-option>
                      </el-select>
                      <p v-else>{{form.authentication ? '是' : '否'}}</p>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="16">
                  <el-col :span="12">
                    <el-form-item label="分类" prop="category">
                      <el-select v-if="isFormEdit" v-model="form.category"
                                placeholder="请选择分类"
                                clearable
                                filterable>
                        <el-option v-for="type in typeList"
                                  :key="type.value"
                                  :label="type.value"
                                  :value="type.value">
                        </el-option>
                      </el-select>
                      <p v-else>{{form.category}}</p>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="档案ID" prop="archiveId">
                      <!-- <el-input v-if="isFormEdit" disabled v-model="form.archiveId" clearable></el-input> -->
                      <p>{{form.archiveId}}</p>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="16">
                  <el-col :span="12">
                    <el-form-item label="参考年代" prop="referenceYear">
                      <el-input v-if="isFormEdit" v-model="form.referenceYear" clearable placeholder="请输入参考年代" maxlength="18"></el-input>
                      <p v-else>{{form.referenceYear}}</p>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-form-item label="简述" prop="description">
                  <el-input v-if="isFormEdit" type="textarea" v-model="form.description" maxlength="500" show-word-limit resize="none" :autosize="{ minRows: 10, maxRows: 10}" clearable></el-input>
                  <p v-else v-html="showParagraph(form.description)"></p>
                </el-form-item>

                <el-form-item v-if="chainInfo && chainInfo.chainId" label="区块链凭证" prop="chain">
                  <div class="desc">
                    <p> 拥有者：{{chainInfo.institution}}</p>
                    <p>区块ID：{{chainInfo.chainId}}</p>
                    <p>记录时间：{{chainInfo.timestamp | timeFilter2}}</p>
                  </div>
                </el-form-item>

                <div v-if="!isEditBtn && (status==='审核不通过' || (status==='建档中' && codeStatus === '待打码') || (status==='建档中' && codeStatus === '打码完成')) && pageType==='record'" class="btns">
                  <el-button @click="cancelSubmitInfo()">取消</el-button>
                  <el-button type="primary" @click="submitInfo('infoForm', 'edit')">确定</el-button>
                </div>

            </el-card>
            <!-- v0.8.1 -->
            <!-- 持有人信息 -->
            <el-card v-if="(pageType === 'record' || pageType === 'audit') && ownerInitFinish" class="owner-info">
              <div class="ym-head clearfix">
                <div class="ym-title-border">
                  <span>持有人信息</span>
                  <template v-if="stepActive >= 1">
                    <i class="ym-icon-light wrap-icon" v-if="showIdInfo" @click="showIdInfo = !showIdInfo"></i>
                    <i class="ym-icon-moon wrap-icon" v-else  @click="showIdInfo = !showIdInfo"></i>
                  </template>
                </div>
                <div class="right">
                  <el-button v-if="isEditBtnOwner" size="small" type="primary" @click="changeEdit('ownerInfo')">编辑</el-button>
                </div>
              </div>
              <el-row :gutter="16">
                <el-col :span="12">
                  <el-form-item label="姓名" prop="owner.ownerName">
                    <el-input v-if="isFormEditOwner" v-model="form.owner.ownerName" clearable placeholder="请输入姓名" maxlength="40"></el-input>
                    <template v-else>
                      <p v-if="showIdInfo">{{form.owner.ownerName}}</p>
                      <p v-else>********</p>
                    </template>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="手机号码" prop="owner.mobilePhone" class="owner-phone">
                    <el-input v-if="isFormEditOwner"
                              v-model="form.owner.mobilePhone"
                              clearable
                              maxlength="11"
                              placeholder="请输入手机号码">
                      <el-select v-model="form.owner.areaCode"
                                  slot="prepend"
                                  placeholder="区域码">
                        <el-option label="+86"
                                    key="86"
                                    value="86">
                          <span>中国&nbsp;&nbsp;+86</span>
                        </el-option>
                      </el-select>
                    </el-input>
                    <template v-else>
                      <p v-if="showIdInfo">{{form.owner.mobilePhone}}</p>
                      <p v-else>********</p>
                    </template>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="16">
                <el-col :span="12">
                  <el-form-item label="身份证号" prop="owner.idCardNumber">
                    <el-input v-if="isFormEditOwner" v-model="form.owner.idCardNumber" clearable placeholder="请输入身份证号码" maxlength="18"></el-input>
                    <template v-else>
                      <p v-if="showIdInfo">{{form.owner.idCardNumber}}</p>
                      <p v-else>********</p>
                    </template>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="16">
                <el-col :span="12">
                  <el-form-item label="身份证正面" prop="owner.idCardFront">
                    <template v-if="(stepActive < 1) || (stepActive >= 1 && showIdInfo) || isFormEditOwner">
                      <upload
                        :fit="'contain'"
                        :limit="1"
                        :disabled="!isFormEditOwner"
                        :sizeLimit="1024*20"
                        :file-list="fileList.idCardFront"
                        :previewList="idCardPreview.idCardFront"
                        :compressList="idCardPreview.idCardFrontThumbnail"
                        :acceptTypes="/.jpg$|.jpeg$|.JPG$|.JPEG$|.png$|.PNG$/"
                        :showFileList="false"
                        size="medium"
                        @onSuccess="onUploadSuccess($event, 'idCardFront')"
                        typeErrorMsg="请上传JPG、JPEG、PNG格式的文件"
                        @onRemove="onFileRemove($event, 'idCardFront')">
                          <i class="el-icon-plus avatar-uploader-icon"></i>
                      </upload>
                    </template>
                    <p v-else>********</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="身份证反面" prop="owner.idCardBack">
                    <template v-if="(stepActive < 1) || (stepActive >= 1 && showIdInfo) || isFormEditOwner">
                      <upload
                        :fit="'contain'"
                        :limit="1"
                        :disabled="!isFormEditOwner"
                        :sizeLimit="1024*20"
                        :file-list="fileList.idCardBack"
                        :previewList="idCardPreview.idCardBack"
                        :compressList="idCardPreview.idCardBackThumbnail"
                        :acceptTypes="/.jpg$|.jpeg$|.JPG$|.JPEG$|.png$|.PNG$/"
                        :showFileList="false"
                        size="medium"
                        @onSuccess="onUploadSuccess($event, 'idCardBack')"
                        typeErrorMsg="请上传JPG、JPEG、PNG格式的文件"
                        @onRemove="onFileRemove($event, 'idCardBack')">
                          <i class="el-icon-plus avatar-uploader-icon"></i>
                      </upload>
                    </template>
                    <p v-else>********</p>
                  </el-form-item>
                </el-col>
              </el-row>

              <div v-if="!isEditBtnOwner && (status==='审核不通过' || (status==='建档中' && codeStatus === '待打码') || (status==='建档中' && codeStatus === '打码完成')) && pageType==='record'" class="btns">
                <el-button @click="cancelSubmitInfo('ownerInfo')">取消</el-button>
                <el-button type="primary" @click="submitInfo('infoForm', 'editOwner')">确定</el-button>
              </div>

            </el-card>
            <!-- v0.8.1 -->
            </el-form>
            <!-- 暗 码 -->
            <el-card>
              <div v-if="(status === '建档中' && !codeStatus) || !status" class="no-code">
                暂 无 暗 码
                <el-button type="primary" @click.native="submitInfo('infoForm')" :loading="loading.cipherBtn">生成暗码</el-button>
                <!-- <el-button type="primary">手动绑定</el-button> -->
              </div>
              <div v-if="status === '建档中,待打码' || ((status === '建档中' && codeStatus === '待打码') && (!codeList || codeList.length === 0)) || codeStatus === '待打码'" class="no-code coding">
                待 打 暗 码
                <div class="qr-code code-img">
                  <img src="./../img/code.png" alt="">
                  <div class="hover-text"><span>全部打码完成后自动生成二维码</span></div>
                </div>
              </div>
              <div v-if="codeList && codeList.length > 0" class="has-code clearfix">

                <el-table :data="codeList" border :span-method="arraySpanMethod">
                  <el-table-column label="编号" type="index" align="center" width="60">
                  </el-table-column>
                  <el-table-column prop="cipher" label="暗码号" align="center">
                  </el-table-column>
                  <el-table-column prop="createTime" align="center" label="打码时间">
                    <template slot-scope="scope">{{scope.row.createTime | timeFilter}}</template>
                  </el-table-column>
                  <el-table-column prop="picture" align="center" label="打码图片">
                    <template slot-scope="scope">
                      <el-button type="text" @click="showCodeImgSrc=scope.row.picture,showCodeImgDialog = true;">查看</el-button>
                    </template>
                  </el-table-column>
                  <el-table-column prop="idCard" label="全球唯一身份证" align="center">
                  </el-table-column>
                </el-table>
                <div class="qr-code">

                <el-image :src="qrCode" fit="cover">
                  <div slot="error">
                    <img src="./../img/code.png" alt="">
                    <div class="hover-text"><span>全部打码完成后自动生成二维码</span></div>
                  </div>
                </el-image>
              </div>

              </div>
            </el-card>

            <el-form v-if="stepActive >= 1"
                    :model="imgForm"
                    :rules="imgRules"
                    ref="imgFrom"
                    label-width="90px"
                    label-position="left"
                    class="info-form">
            <!-- 主图 -->
            <el-card class="center">
              <div class="ym-title-border">展示主图</div>

              <el-form-item class="bold-label" label="前视图" prop="main">
                <upload
                  :limit="1"
                  :disabled="!isImgFromEdit"
                  :sizeLimit="1024*20"
                  :file-list="fileList.main"
                  :previewList="imgPreview.main"
                  :compressList="imgCompress.main"
                  :acceptTypes="/.jpg$|.jpeg$|.JPG$|.JPEG$|.png$|.PNG$/"
                  :showFileList="false"
                  size="medium"
                  @onSuccess="onUploadSuccess($event, 'main')"
                  typeErrorMsg="请上传JPG、JPEG、PNG格式的文件"
                  @onRemove="onFileRemove($event, 'main')">
                     <i class="el-icon-plus avatar-uploader-icon"></i>
                  </upload>
              </el-form-item>

              <el-form-item v-if="isImgFromEdit || imgForm.back" class="bold-label" label="后视图" prop="back">
                <upload
                  :limit="1"
                  :sizeLimit="1024*20"
                  :disabled="!isImgFromEdit"
                  :file-list="fileList.back"
                  :previewList="imgPreview.back"
                  :compressList="imgCompress.back"
                  :acceptTypes="/.jpg$|.jpeg$|.JPG$|.JPEG$|.png$|.PNG$/"
                  :showFileList="false"
                  size="medium"
                  @onSuccess="onUploadSuccess($event, 'back')"
                  typeErrorMsg="请上传JPG、JPEG、PNG格式的文件"
                  @onRemove="onFileRemove($event, 'back')">
                     <i class="el-icon-plus avatar-uploader-icon"></i>
                  </upload>
              </el-form-item>

              <el-form-item v-if="isImgFromEdit || imgForm.left" class="bold-label" label="左视图" prop="left">
                <upload
                  :limit="1"
                  :disabled="!isImgFromEdit"
                  :sizeLimit="1024*20"
                  :file-list="fileList.left"
                  :previewList="imgPreview.left"
                  :compressList="imgCompress.left"
                  :acceptTypes="/.jpg$|.jpeg$|.JPG$|.JPEG$|.png$|.PNG$/"
                  :showFileList="false"
                  size="medium"
                  @onSuccess="onUploadSuccess($event, 'left')"
                  typeErrorMsg="请上传JPG、JPEG、PNG格式的文件"
                  @onRemove="onFileRemove($event, 'left')">
                     <i class="el-icon-plus avatar-uploader-icon"></i>
                  </upload>
              </el-form-item>

              <el-form-item v-if="isImgFromEdit || imgForm.right" class="bold-label" label="右视图" prop="right">
                <upload
                  :limit="1"
                  :disabled="!isImgFromEdit"
                  :sizeLimit="1024*20"
                  :file-list="fileList.right"
                  :previewList="imgPreview.right"
                  :compressList="imgCompress.right"
                  :acceptTypes="/.jpg$|.jpeg$|.JPG$|.JPEG$|.png$|.PNG$/"
                  :showFileList="false"
                  size="medium"
                  @onSuccess="onUploadSuccess($event, 'right')"
                  typeErrorMsg="请上传JPG、JPEG、PNG格式的文件"
                  @onRemove="onFileRemove($event, 'right')">
                     <i class="el-icon-plus avatar-uploader-icon"></i>
                  </upload>
              </el-form-item>

              <el-form-item v-if="isImgFromEdit || imgForm.up" class="bold-label" label="顶部图" prop="up">
                <upload
                  :limit="1"
                  :disabled="!isImgFromEdit"
                  :sizeLimit="1024*20"
                  :file-list="fileList.up"
                  :previewList="imgPreview.up"
                  :compressList="imgCompress.up"
                  :acceptTypes="/.jpg$|.jpeg$|.JPG$|.JPEG$|.png$|.PNG$/"
                  :showFileList="false"
                  size="medium"
                  @onSuccess="onUploadSuccess($event, 'up')"
                  typeErrorMsg="请上传JPG、JPEG、PNG格式的文件"
                  @onRemove="onFileRemove($event, 'up')">
                     <i class="el-icon-plus avatar-uploader-icon"></i>
                  </upload>
              </el-form-item>

              <el-form-item v-if="isImgFromEdit || imgForm.down" class="bold-label" label="底部图" prop="down">
                <upload
                  :limit="1"
                  :disabled="!isImgFromEdit"
                  :sizeLimit="1024*20"
                  :file-list="fileList.down"
                  :previewList="imgPreview.down"
                  :compressList="imgCompress.down"
                  :acceptTypes="/.jpg$|.jpeg$|.JPG$|.JPEG$|.png$|.PNG$/"
                  :showFileList="false"
                  size="medium"
                  @onSuccess="onUploadSuccess($event, 'down')"
                  typeErrorMsg="请上传JPG、JPEG、PNG格式的文件"
                  @onRemove="onFileRemove($event, 'down')">
                     <i class="el-icon-plus avatar-uploader-icon"></i>
                  </upload>
              </el-form-item>

              <el-form-item  v-if="isImgFromEdit || imgForm.panoramicView" class="bold-label panoramas" label="全景图" prop="panoramicView">
                <upload
                  :limit="1"
                  :disabled="!isImgFromEdit"
                  :sizeLimit="1024*100"
                  :file-list="fileList.panoramicView"
                  :previewList="imgPreview.panoramicView"
                  :compressList="imgCompress.panoramicView"
                  :acceptTypes="/.jpg$|.jpeg$|.JPG$|.JPEG$/"
                  :showFileList="false"
                  size="medium"
                  @onSuccess="onUploadSuccess($event, 'panoramicView')"
                  typeErrorMsg="请上传JPG、JPEG格式的文件"
                  @onRemove="onFileRemove($event, 'panoramicView')">
                     <i class="el-icon-plus avatar-uploader-icon"></i>
                  </upload>
              </el-form-item>

            </el-card>

            <!-- 宏观描述 -->
            <el-card class="center">
              <div class="ym-title-border">宏观描述</div>

              <!-- 宏观描述的图文预览 -->
              <div class="details-img-preview">
                  <el-row :gutter="24" v-for="(item, index) in imgForm.macro" :key="index" style="border:1px solid #EBEEF5; padding:8px;" :ref="`macro-${index}`">
                    <el-col :span="12">
                      <div class="preview">
                        <el-image :src="FileUrl + item.urlThumbnail" fit="cover" :preview-src-list="[FileUrl + imgForm.macro[index].url]" lazy></el-image>
                        <div class="bottom">查看原图</div>
                        <i v-if="isImgFromEdit" class="el-icon-circle-close close" @click="onMacroRemove(index)"></i>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <p style="margin:8px 0;">描述点：</p>
                      <el-form-item
                        label-width="0"
                        label-position="top"
                        :key="index"
                        :prop="'macro.' + index + '.description'"
                        :rules="{
                          required: true, message: '请输入描述点', trigger: 'blur'
                        }"
                        >
                        <el-input
                          v-if="isImgFromEdit"
                          v-model="item.description"
                          type="textarea"
                          maxlength="300"
                          show-word-limit resize="none" :autosize="{ minRows: 7, maxRows: 7}"></el-input>
                          <p v-else style="text-align: justify;" v-html="showParagraph(item.description)"></p>
                      </el-form-item>
                    </el-col>
                  </el-row>
              </div>

              <el-form-item prop="macro">
                <drag-upload
                  ref="macroDragUpload"
                  v-if="isImgFromEdit"
                  class="upload-drag"
                  :limit="20"
                  :selectLimit="5"
                  :disabled="!isImgFromEdit"
                  :sizeLimit="1024*20"
                  :file-list="fileList.macro"
                  :acceptTypes="/.jpg$|.jpeg$|.JPG$|.JPEG$|.png$|.PNG$/"
                  :showFileList="false"
                  size="medium"
                  @onSuccess="onUploadSuccess($event, 'macro')"
                  typeErrorMsg="请上传JPG、JPEG、PNG格式的文件">
                </drag-upload>
              </el-form-item>
            </el-card>

            <!-- 微观描述 -->
            <el-card class="center">
              <div class="ym-title-border">微观描述</div>

              <!-- 微观描述的图文预览 -->
              <div class="details-img-preview">
                  <el-row :gutter="24" v-for="(item, index) in imgForm.details" :key="index" style="border:1px solid #EBEEF5; padding:8px;" :ref="`details-${index}`">
                    <el-col :span="12">
                      <div class="preview">
                        <el-image :src="FileUrl + item.urlThumbnail" fit="cover" :preview-src-list="[FileUrl + imgForm.details[index].url]" lazy></el-image>
                        <div class="bottom">查看原图</div>
                        <i v-if="isImgFromEdit" class="el-icon-circle-close close" @click="onDetailsRemove(index)"></i>
                      </div>
                    </el-col>
                    <el-col :span="12">
                      <p style="margin:8px 0;">描述点：</p>
                      <el-form-item
                        label-width="0"
                        label-position="top"
                        :key="index"
                        :prop="'details.' + index + '.description'"
                        :rules="{
                          required: true, message: '请输入描述点', trigger: 'blur'
                        }"
                        >
                        <el-input
                          v-if="isImgFromEdit"
                          v-model="item.description"
                          type="textarea"
                          maxlength="300"
                          show-word-limit resize="none" :autosize="{ minRows: 7, maxRows: 7}"></el-input>
                          <p v-else style="text-align: justify;" v-html="showParagraph(item.description)"></p>
                      </el-form-item>
                    </el-col>
                  </el-row>
              </div>

              <el-form-item prop="details">
                <drag-upload
                  ref="detailsDragUpload"
                  v-if="isImgFromEdit"
                  class="upload-drag"
                  :limit="20"
                  :selectLimit="5"
                  :disabled="!isImgFromEdit"
                  :sizeLimit="1024*20"
                  :file-list="fileList.details"
                  :acceptTypes="/.jpg$|.jpeg$|.JPG$|.JPEG$|.png$|.PNG$/"
                  :showFileList="false"
                  size="medium"
                  @onSuccess="onUploadSuccess($event, 'details')"
                  typeErrorMsg="请上传JPG、JPEG、PNG格式的文件">
                </drag-upload>
              </el-form-item>
            </el-card>

          </el-form>
          <!-- 审核管理中指派给鉴定机构 -->
          <el-form v-if="stepActive >= 4 || pageType === 'audit'"
                   :model="identifyForm"
                   :rules="identifyRules"
                   ref="indentifyForm"
                   label-width="90px"
                   class="info-form"
                   size="small">
            <el-card class="audit-info">
              <div class="ym-head clearfix">
                <div class="ym-title-border">藏品鉴定信息</div>
              </div>
              <el-row :gutter="16">
                <el-col :span="12">
                  <el-form-item label="鉴定流程" prop="process">
                    <el-select v-model="identifyForm.process" disabled v-if="status === '待审核'">
                      <el-option v-for="process in processList"
                                :key="process.value"
                                :value="process.value"
                                :label="process.value"></el-option>
                    </el-select>
                    <p v-else>{{assignedIdentifyInfo.identifyType}}</p>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="鉴定机构" prop="institute">
                    <el-select v-model="identifyForm.institute" v-if="status === '待审核'" filterable>
                      <el-option v-for="institute in identifyInstituteList"
                                :key="institute.deptId"
                                :value="institute.deptId"
                                :label="institute.deptName"></el-option>
                    </el-select>
                    <p v-else>{{assignedIdentifyInfo.deptName}}</p>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>
          </el-form>

          <el-form :model="firstIdentifyForm"
                    :rules="firstIdentifyRules"
                    ref="firstIdentifyForm"
                    class="info-form first-identify-form"
                    label-width="120px"
                    size="small"
                    v-if="checkFirstIdentifyAssign || ((pageType === 'record' || pageType === 'first-identify' || pageType === 'final-identify' || pageType === 'order-first-identify' || pageType === 'order-final-identify') && stepActive >= 5) || status === '存疑'">
            <el-card>
              <div class="ym-head clearfix">
                <div class="ym-title-border">初鉴内容</div>
              </div>
              <el-form-item label="证书编号" prop="certificateNumber">
                <el-input v-model="firstIdentifyForm.certificateNumber" placeholder="选填，如不填写则系统生成默认证书编号" maxlength="50" :disabled="(!checkFirstIdentifyAssign && status === '存疑')" v-if="!(stepActive >= 5)"></el-input>
                <p v-else>{{firstIdentifyForm.certificateNumber}}</p>
              </el-form-item>

              <el-form-item label="分类" prop="category">
                <el-select v-model="firstIdentifyForm.category"
                           placeholder="请选择分类"
                           filterable
                           v-if="!(stepActive >= 5)">
                  <el-option v-for="type in typeList"
                            :key="type.value"
                            :label="type.value"
                            :value="type.value">
                  </el-option>
                </el-select>
                <p v-else>{{firstIdentifyForm.category}}</p>
              </el-form-item>

              <el-form-item label="参考年代" prop="referenceYear">
                <el-input v-model="firstIdentifyForm.referenceYear" clearable placeholder="请输入参考年代" maxlength="18" v-if="!(stepActive >= 5)"></el-input>
                <p v-else>{{firstIdentifyForm.referenceYear}}</p>
              </el-form-item>

              <el-form-item label="鉴定评估意见" prop="opinion" :class="[ !(stepActive >= 5) ? '' : 'identify-show' ]">
                <el-input v-model="firstIdentifyForm.opinion" placeholder="请输入鉴定评估意见" maxlength="1000" type="textarea" resize="none" :autosize="{ minRows: 17, maxRows: 17}" clearable show-word-limit :disabled="(!checkFirstIdentifyAssign && status === '存疑')" v-if="!(stepActive >= 5) "></el-input>
                <div v-else class="identify-show-block">
                  <p v-html="showParagraph(firstIdentifyForm.opinion)"></p>
                </div>
              </el-form-item>
              <el-form-item label="备注" prop="remark" :class="[ !(stepActive >= 5) ? '' : 'identify-show' ]">
                <el-input v-model="firstIdentifyForm.remark" placeholder="选填，如不填则生成默认备注" maxlength="100" type="textarea" resize="none" :autosize="{ minRows: 8, maxRows: 8}" clearable show-word-limit :disabled="(!checkFirstIdentifyAssign && status === '存疑')" v-if="!(stepActive >= 5)"></el-input>
                <div class="identify-show-block" v-else>
                  <p v-html="showParagraph(firstIdentifyForm.remark)"></p>
                </div>
              </el-form-item>
              <div class="save-button">
                <el-button size="small" @click="saveFirstIdentifyForm" :disabled="!enableSave" v-if="stepActive < 5 && checkFirstIdentifyAssign" :loading="loading.firstIdentifySaveBtn">{{!countdownFlag ? '保存' : `已保存(${countdown}s)`}}</el-button>
                <el-button size="small" @click="viewCertificate" v-if="stepActive >= 5">证书查看</el-button>
              </div>
              <div class="certificate-part" v-if="stepActive < 5 && checkFirstIdentifyAssign">
                <el-form-item label="证书预览下载">
                  <el-button type="primary" icon="el-icon-download" @click="handleCertificateView" :disabled="codeStatus === '待打码'">点击预览</el-button>
                  <div class="tip wait-coding" v-if="codeStatus === '待打码'">未完成暗码打码，暂不能生成证书</div>
                  <div class="tip" v-else>证书预览将自动保存当前初鉴内容，请下载后签名盖章上传</div>
                </el-form-item>
                <el-form-item label="盖章证书上传" prop="certificate">
                  <file-upload :sizeLimit="1024*50"
                              typeErrorMsg="上传的格式必须为PNG/JPG/JPEG/BMP"
                              :file-list="certificateList"
                              @onSuccess="onUploadSuccess($event, 'certificate')"
                              @onProgress="onUploadProgress"
                              @onRemove="onFileRemove($event, 'certificate')"
                              @onError="onUploadError">
                    <el-button size="small" icon="el-icon-upload2" v-if="(!certificateList || certificateList.length < 1) && !progressing" :disabled="codeStatus === '待打码'">上传</el-button>
                    <template #tip v-if="(!certificateList || certificateList.length < 1) && !progressing">
                      <div class="el-upload__tip wait-coding" v-if="codeStatus === '待打码'">未完成暗码打码，暂不能上传证书</div>
                      <div class="el-upload__tip" v-else>格式必须为PNG/JPG/JPEG/BMP，不大于50MB</div>
                    </template>
                  </file-upload>
                </el-form-item>
              </div>
            </el-card>
          </el-form>

            <!-- 操作按钮 -->
            <template  v-if="stepActive >= 1">
              <el-card v-if="pageType === 'record' && status==='建档中,待打码'" class="center" shadow="never">
                <el-button type="primary" :loading="savePictureLoading" @click="savePicture()">临时保存</el-button>
              </el-card>
              <el-card v-if="pageType === 'record' && (status==='建档中,打码完成' || status==='审核不通过' || (status==='建档中' && codeStatus))" class="center" shadow="never">
                <el-button type="primary" :loading="savePictureLoading" @click="savePicture()">临时保存</el-button>
                <el-button type="primary" @click="submitInfo('imgFrom')" :loading="loading.submitAuditBtn">提交审核</el-button>
              </el-card>
              <audit-opts :archiveId="archiveId" v-if="pageType === 'audit' && status==='待审核'" ref="auditComp" @identifyFormCheck="identifyFormCheck"/>
              <identify-opts :archiveId="archiveId" v-if="pageType === 'first-identify' && (status==='待初鉴' || status === '存疑') && checkFirstIdentifyAssign" @validateForm="validateFirstIdentifyForm" @handleDoubt="handleDoubt" ref="identifyOpts" :codeStatus="codeStatus" />
             </template>
          </div>

          <!-- 步骤条 -->
          <div class="fl steps">
            <steps :stepActive="stepActive" />
          </div>
        </el-tab-pane>
        <!-- 终鉴信息 -->
        <el-tab-pane label="三确&法律信息" name="third" v-if="(status === '终鉴中' || status === '终鉴完成') && pageType !== 'order-first-identify'">
          <final-identify-details :basicInfo="form" @tabChange="handleTabChange" v-loading="loading.summary" ref="finalIdentifyDetails" :pageType="pageType" :userInfo="userInfo" :finalResult="finalResult" :status="status" :loadFinished="finalSummaryInfoLoadedFinished" @handleSubmitFinalInfo="handleSubmitFinalInfo"></final-identify-details>
        </el-tab-pane>
        <!-- 档案记录 -->
        <el-tab-pane label="档案记录" name="second" v-if="pageType === 'record'">
          <chain-table v-if="activeName === 'second'" :archiveId="archiveId"></chain-table>
        </el-tab-pane>
      </el-tabs>
    </div>
    <template v-if="activeName === 'third' && finalTab === 'summary'" v-loading="loading.summary">
      <authenticity :dataInfo="this.finalSummaryInfo" :userInfo="userInfo" @goSubmit="goSubmit"></authenticity>
      <notarization :dataInfo="this.finalSummaryInfo" :userInfo="userInfo" @goSubmit="goSubmit"></notarization>
      <value :dataInfo="this.finalSummaryInfo" :userInfo="userInfo" @goSubmit="goSubmit"></value>
      <law :dataInfo="this.finalSummaryInfo" :userInfo="userInfo" @goSubmit="goSubmit"></law>
    </template>
    <!-- 确真机构 录入 -->
    <authenticity-details v-if="activeName === 'third' && finalTab === 'authenticity'" ref="authenticityDetails" :archiveId="archiveId" :isOperatorOrRecorder="hasPermission('operator') || hasPermission('recorder')" @submitFinished="handleSubmitFinished"></authenticity-details>
    <!-- 确权机构 录入 -->
    <notarization-details v-if="activeName === 'third' && finalTab === 'notarization'" ref="notarizationDetails" :archiveId="archiveId" :isOperatorOrRecorder="hasPermission('operator') || hasPermission('recorder')" @submitFinished="handleSubmitFinished"></notarization-details>
    <!-- 确值机构 录入 -->
    <value-details v-if="activeName === 'third' && finalTab === 'value'" ref="valueDetails" :archiveId="archiveId" :isOperatorOrRecorder="hasPermission('operator') || hasPermission('recorder')" @submitFinished="handleSubmitFinished"></value-details>
    <!-- 法律机构 录入 -->
    <law-details v-if="activeName === 'third' && finalTab === 'law'" ref="lawDetails" :archiveId="archiveId" :isOperatorOrRecorder="hasPermission('operator') || hasPermission('recorder')" @submitFinished="handleSubmitFinished"></law-details>
    <img-dialog :show.sync="showCodeImgDialog" :src="showCodeImgSrc" />
    <!-- 所有鉴定信息提交完成，获取的终鉴综合页信息加载完成，藏品信息状态为终鉴中，才能生成证书，证书绘制后自动执行终鉴申请 -->
    <final-certificate :dataInfo="dataCache" :finalSummaryInfo="finalSummaryInfo" :loadFinished="finalSummaryInfoLoadedFinished" v-if="sumbitFinalInfoFlag && !uploadFinalCertificateFinished" @finalSubmitFinished="uploadFinalCertificateFinished = true" @sumitFail="sumbitFinalInfoFlag = false"></final-certificate>
  </div>
</template>

<script>
import upload from '../component/uploadFile.vue' // 图片上传
import dragUpload from '../component/dragUpload.vue' // 拖拽上传
import imgDialog from '../component/imgDialog.vue'
import steps from '../component/steps.vue'
import chainTable from './chain.vue'
import auditOpts from './audit-opts.vue'
import identifyOpts from './identify-opts.vue'
import timeFormat from '@/utils/timeFormat.js'
import fileUpload from '../component/uploadOnlyFile.vue' // 文件上传
import { getUserInfo } from '@/utils/localStorage.js'
import FinalIdentifyDetails from '../finalIdentifyDetails/index.vue'
import Authenticity from '../finalIdentifyDetails/authenticity/index.vue'
import AuthenticityDetails from '../finalIdentifyDetails/authenticity/details.vue'
import Notarization from '../finalIdentifyDetails/notarization/index.vue'
import NotarizationDetails from '../finalIdentifyDetails/notarization/details.vue'
import Value from '../finalIdentifyDetails/value/index.vue'
import ValueDetails from '../finalIdentifyDetails/value/details.vue'
import Law from '../finalIdentifyDetails/law/index.vue'
import LawDetails from '../finalIdentifyDetails/law/details.vue'
import FinalCertificate from '../finalIdentifyDetails/certificate/index.vue'
import { showParagraph } from '@/utils/handleParagraph.js'
// import YmAddress from '@/components/AreaCascade'
export default {
  components: {
    upload,
    dragUpload,
    chainTable,
    auditOpts,
    identifyOpts,
    // YmAddress,
    imgDialog,
    steps,
    fileUpload,
    FinalIdentifyDetails,
    Authenticity,
    AuthenticityDetails,
    Notarization,
    NotarizationDetails,
    Value,
    ValueDetails,
    Law,
    LawDetails,
    FinalCertificate
  },
  data() {
    // 校验持有人姓名
    let checkName = (rule, value, callback) => {
      const regExp = /^[a-zA-Z\u4e00-\u9fa5 ·]*$/
      if (!value) {
        callback(new Error('请输入姓名'))
      } else if (!regExp.test(value)) {
        callback(new Error('请填写正确的姓名'))
      } else {
        callback()
      }
    }
    // 校验持有人手机号
    let checkPhone = (rule, value, callback) => {
      const regExp = /^1\d{10}$/
      if (!value) {
        callback(new Error('请输入手机号码'))
      } else if (!regExp.test(value)) {
        callback(new Error('请填写正确的手机号码'))
      } else {
        callback()
      }
    }
    // 身份证校验
    let checkId = (rule, value, callback) => {
      const regExp = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/
      if (!value) {
        callback(new Error('请输入身份证号码'))
      } else if (!regExp.test(value)) {
        callback(new Error('请填写正确的身份证号码'))
      } else {
        callback()
      }
    }
    return {
      loading: {
        cipherBtn: false, // 生成暗码按钮
        submitAuditBtn: false, // 提交初步审核按钮
        firstIdentifySaveBtn: false, // 初鉴内容保存按钮
        summary: false, // 终鉴综合页
        info: false // 加载信息
      },
      countdownFlag: false, // 保存按钮显示倒计时
      enableBack: true, // 页面是否能够返回
      userInfo: {},
      permissionsTags: [],
      certificateUrl: '',
      showCodeImgDialog: false,
      showCodeImgSrc: '',
      activeName: 'first',
      archiveId: '', // 藏品id
      status: '', // 藏品状态
      codeStatus: '', // 打码状态
      qrCode: '',
      certificateId: '', // 证书ID
      isSaved: false,
      isFormEdit: false,
      isFormEditOwner: false,
      isImgFromEdit: false,
      isEditBtn: false,
      isEditBtnOwner: false,
      isCreatePage: false,
      cityAddr: [],
      chainInfo: {}, // 区块链凭证
      detailsData: {},
      ownerData: {},
      savePictureLoading: false,
      form: {
        name: '', // 名称
        institution: '', // 归属
        size: '', // 尺寸
        storage: '', // 存储地
        weight: '', // 重量
        authentication: '', // 是否认证
        category: '', // 分类
        archiveId: '', // 档案ID
        referenceYear: '', // 参考年代
        description: '', // 简述
        owner: {
          ownerName: '', // 持有人姓名
          areaCode: '86', // 国家代码
          mobilePhone: '', // 手机号
          idCardNumber: '', // 持有人身份证
          idCardFront: '', // 持有人身份证正面
          idCardBack: '', // 持有人身份证背面
          idCardFrontThumbnail: '', // 持有人身份证正面缩略图
          idCardBackThumbnail: '' // 持有人身份证背面缩略图
        }
      },
      idCardPreview: {
        idCardFront: [],
        idCardBack: [],
        idCardFrontThumbnail: [],
        idCardBackThumbnail: []
      },
      rules: {
        name: [
          { required: true, message: '请输入藏品名称', trigger: 'change' },
          { max: 40, message: '长度不超过 40 个字符', trigger: 'change' }
        ],
        size: [
          { required: true, message: '请输入藏品尺寸', trigger: 'change' },
          { max: 50, message: '长度不超过 50 个字符', trigger: 'change' }
        ],
        storage: [
          { required: true, message: '请输入藏品存储地', trigger: 'change' },
          { max: 40, message: '长度不超过 40 个字符', trigger: 'change' }
        ],
        weight: [
          { required: true, message: '请输入藏品重量', trigger: 'change' },
          { max: 10, message: '长度不超过 10 个字符', trigger: 'change' }
        ],
        authentication: [
          { required: true, message: '请选择是否已认证', trigger: 'change' }
        ],
        category: [
          { required: true, message: '请选择藏品分类', trigger: 'change' }
        ],
        referenceYear: [
          { required: true, message: '请填写参考年代', trigger: 'change' }
        ],
        description: [
          { required: true, message: '请输入藏品简述', trigger: 'change' }
        ],
        'owner.ownerName': [
          { required: true, validator: checkName, trigger: 'change' }
        ],
        'owner.mobilePhone': [
          { required: true, validator: checkPhone, trigger: 'change' }
        ],
        'owner.idCardNumber': [
          { required: true, validator: checkId, trigger: 'change' }
        ],
        'owner.idCardFront': [
          { required: true, message: '请上传身份证正面', trigger: 'change' }
        ],
        'owner.idCardBack': [
          { required: true, message: '请上传身份证反面', trigger: 'change' }
        ]
      },
      typeList: [
        { value: '陶瓷' },
        { value: '玉器玉雕' },
        { value: '古币纸钱' },
        { value: '铜器铜雕' },
        { value: '中国书画' },
        { value: '古典家具' },
        { value: '金银珠宝' },
        { value: '雕品工艺' }
      ],
      // 图片
      imgForm: {
        main: '',
        left: '',
        right: '',
        back: '',
        up: '', // 顶部
        down: '', // 底部
        panoramicView: '', // 全景图
        details: [], // 微观描述
        macro: [], // 宏观描述

        mainThumbnail: '', // 缩略图地址
        leftThumbnail: '',
        rightThumbnail: '',
        backThumbnail: '',
        upThumbnail: '',
        downThumbnail: '',
        panoramicViewThumbnail: ''
      },
      imgRules: {
        main: [
          { required: true, message: '请上传前视图', trigger: 'change' }
        ],
        left: [
          { required: false, message: '请上传左视图', trigger: 'change' }
        ],
        right: [
          { required: false, message: '请上传右视图', trigger: 'change' }
        ],
        back: [
          { required: false, message: '请上传后视图', trigger: 'change' }
        ],
        up: [
          { required: false, message: '请上传顶部图', trigger: 'change' }
        ],
        down: [
          { required: true, message: '请上传底部图', trigger: 'change' }
        ],
        panoramicView: [
          { required: false, message: '请上传全景图', trigger: 'change' }
        ],
        details: [
          { required: true, message: '请上传微观描述图', trigger: 'change' }
        ],
        macro: [
          { required: true, message: '请上传宏观描述图', trigger: 'change' }
        ]
      },
      codeList: [],
      // 图片初始化列表
      fileList: {
        main: [],
        left: [],
        right: [],
        back: [],
        up: [],
        down: [],
        panoramicView: [],
        details: [],
        macro: [],
        idCardFront: [],
        idCardBack: []
      },
      // 图片原图预览列表
      imgPreview: {
        main: [],
        left: [],
        right: [],
        back: [],
        up: [],
        down: [],
        panoramicView: []
      },
      // 图片缩略图
      imgCompress: {
        main: [],
        left: [],
        right: [],
        back: [],
        up: [],
        down: [],
        panoramicView: []
      },
      // 鉴定流程列表
      processList: [
        {
          value: '初鉴'
        }
      ],
      // 鉴定机构列表
      identifyInstituteList: [],
      // 藏品鉴定信息表单
      identifyForm: {
        process: '初鉴',
        institute: ''
      },
      identifyRules: {
        process: [{ required: true, message: '请选择鉴定流程', trigger: 'change' }],
        institute: [{ required: true, message: '请选择鉴定机构', trigger: 'change' }]
      },
      assignedIdentifyInfo: {}, // 获取改藏品分配的鉴定类型和鉴定机构
      // 初鉴内容
      firstIdentifyForm: {
        certificateNumber: '',
        opinion: '',
        remark: '本鉴定报告属于文物艺术品初级鉴定，鉴定结论为参考性意见，最终确定结论，需进行下一步程序的仪器检测和专家组集体鉴定。本机构在仪器检测和专家组集体鉴定后，方可作出最终确切结论。',
        certificate: '',
        category: '',
        referenceYear: ''
      },
      firstIdentifyRules: {
        opinion: [
          { required: true, message: '鉴定意见不能为空', trigger: 'change' }
        ],
        category: [
          { required: true, message: '请选择藏品分类', trigger: 'change' }
        ],
        referenceYear: [
          { required: true, message: '请填写参考年代', trigger: 'change' }
        ],
        certificate: [
          { required: true, message: '请先上传盖章证书', trigger: 'change' }
        ]
      },
      certificateList: [],
      enableSave: true,
      countdown: 5,
      deptId: '', // 所属机构ID
      createDeptId: '', // 创建档案机构ID
      ownerInitFinish: false, // 避免编辑文档时出现持有人信息校验
      pathTypeList: ['record', 'audit', 'identify/first-identify', 'identify/final-identify', 'order/first-identify', 'order/final-identify'],
      finalTab: 'summary', // 终鉴类型tab
      progressing: false, // 初鉴证书正在上传中
      finalSummaryInfo: {}, // 终鉴综合信息
      finalSummaryInfoLoadedFinished: false, // 综合页信息是否加载完成
      finalResult: false, // 判断终鉴需要的所有信息是否已经提交完成
      dataCache: {}, // 藏品数据缓存，用于存放生成终鉴证书的数据
      sumbitFinalInfoFlag: false, // 为true时，提交终鉴证书及终鉴提交完成
      uploadFinalCertificateFinished: false, // 证书上传及终鉴提交的标识，为true时标识已完成提交
      showIdInfo: false // 是否显示持有人身份信息
    }
  },
  beforeRouteLeave(to, from, next) {
    console.log('进入beforeRouteLeave')
    if (this.isSaved) {
      next()
    }
    this.isSaved = true
    if ((this.pageType === 'record' && this.isCreatePage) || (this.status === '建档中' && !this.codeStatus)) {
      if (this.form.name || this.form.size || this.form.weight || this.form.category || this.form.storage || this.form.authentication || this.form.description || this.form.archiveId || this.form.description) {
        setTimeout(() => {
          this.$confirm('退出界面是否保存已输入信息？', '提示', {
            confirmButtonText: '保存',
            cancelButtonText: '不保存',
            type: 'warning',
            showClose: false
          }).then(() => {
          // 保存藏品信息
            this.editRecord()
            next()
          }).catch(() => {
            next()
          })
        }, 200)
      } else {
        next()
      }
    } else {
      next()
    }
  },
  created() {
    this.archiveId = this.$route.query.archiveId
    // this.status = this.$route.query.status
    if (this.pageType === 'record' && !this.archiveId) {
      // 是新建页面
      this.isCreatePage = true
      this.ownerInitFinish = true
      this.createRecord()
      this.isFormEdit = true
      this.isFormEditOwner = true
    } else {
      this.getRecord()
      this.getChain()
    }
    // TODO: 测试无误后删除
    // if (this.status === '建档中') {
    //   this.isFormEdit = true
    // }
    // if (this.pageType === 'record' && (this.status === '建档中,打码完成' || this.status === '审核不通过')) {
    //   this.isEditBtn = true
    //   this.isEditBtnOwner = true
    //   this.isImgFromEdit = true
    // }
    // if (this.pageType === 'record' && this.status === '建档中,待打码') {
    //   this.isImgFromEdit = true
    // }
    // console.log(this.status)
    // TODO: 测试无误后删除
  },
  mounted() {
    this.userInfo = JSON.parse(getUserInfo())
    console.log(this.userInfo)
    this.permissionsTags = this.userInfo && this.userInfo.permissionsTags
    if (this.pageType === 'audit') {
      this.getidentifyInstituteList()
    }
    this.enableBack = window.history.length > 1
    this.$refs.recordDetails.parentElement.scrollTop = 0
  },
  watch: {
    status: {
      handler(newVal, oldVal) {
        console.log(this.status)
        if (this.status === '待指派' || this.status === '待初鉴' || this.status === '已初鉴' || this.status === '存疑' || this.status === '艺术品' || this.status === '终鉴中' || this.status === '终鉴完成') {
          this.getAuditAssignInfo()
        }
      }
    }
  },
  computed: {
    pageType() {
      const path = this.$route.path
      let pageType = ''
      // console.log('path')
      // console.log(path)

      // switch (path) {
      //   case '/record/details': pageType = 'record'; break
      //   case '/audit/details': pageType = 'audit'; break
      //   case '/identify/details': pageType = 'identify'; break
      // }
      this.pathTypeList.forEach(type => {
        const regExp = new RegExp('/' + type + '/')
        if (regExp.test(path)) {
          switch (type) {
            case 'identify/first-identify':
              pageType = 'first-identify'
              break
            case 'identify/final-identify':
              pageType = 'final-identify'
              break
            case 'order/first-identify':
              pageType = 'order-first-identify'
              break
            case 'order/final-identify':
              pageType = 'order-final-identify'
              break
            default:
              pageType = type
          }
          console.log('pageType: ', pageType)
        }
      })
      return pageType
    },
    stepActive() {
      let active = 0
      // switch (this.status) {
      //   case '建档中': active = 0; break
      //   case '建档中,待打码': active = 1; break
      //   case '建档中,打码完成': active = 2; break
      //   case '审核不通过': active = 2; break
      //   case '待审核': active = 3; break
      //   case '待指派': active = 4; break
      //   case '待初鉴': active = 4; break
      //   case '存疑': active = 4; break
      //   case '已初鉴': active = 5; break
      //   case '艺术品': active = 5; break
      // }
      if (this.status === '建档中' && !this.codeStatus) {
        active = 0
      } else if ((this.status === '建档中' && this.codeStatus === '待打码') || this.status === '建档中,待打码') {
        active = 1
      } else if ((this.status === '建档中' && this.codeStatus === '打码完成') || this.status === '建档中,打码完成') {
        active = 2
      } else if (this.status === '审核不通过') {
        active = 2
      } else if (this.status === '待审核') {
        active = 3
      } else if (this.status === '待指派') {
        active = 4
      } else if (this.status === '待初鉴') {
        active = 4
      } else if (this.status === '存疑') {
        active = 4
      } else if (this.status === '已初鉴') {
        active = 5
      } else if (this.status === '艺术品' || this.status === '终鉴中' || this.status === '终鉴完成') {
        active = 5
      }
      this.getSavedFirstIdentifyForm(active)
      console.log('步骤', active)
      return active
    },
    // 校验该藏品单的被指派人是否为该登录账号
    checkFirstIdentifyAssign() {
      console.log(this.userInfo)
      console.log(this.assignedIdentifyInfo)
      return this.userInfo.userId === this.assignedIdentifyInfo.userId
    }
  },
  methods: {
    showParagraph,
    // onCityDataChange(data) {
    //   console.log(data)
    //   this.cityAddr = data
    //   this.form.storage = data.join(' / ')
    // },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 4) {
        return {
          rowspan: this.codeList.length,
          colspan: 1
        }
      }
    },
    cancelSubmitInfo(owner) {
      // 初始化基本信息
      if (!owner) {
        for (let i in this.form) {
          if (i !== 'owner') {
            this.form[i] = this.detailsData[i]
          }
        }
        this.isFormEdit = false
        this.isEditBtn = true
      } else {
        console.log(this.ownerData)
        this.$set(this.form.owner, 'ownerName', this.ownerData.ownerName ? this.ownerData.ownerName : '')
        this.$set(this.form.owner, 'areaCode', '86')
        this.$set(this.form.owner, 'mobilePhone', this.ownerData.mobilePhone)
        this.$set(this.form.owner, 'idCardNumber', this.ownerData.idCardNumber)
        this.$set(this.form.owner, 'idCardFront', this.ownerData.idCardFront ? this.ownerData.idCardFront : '')
        this.$set(this.form.owner, 'idCardBack', this.ownerData.idCardBack ? this.ownerData.idCardBack : '')
        this.$set(this.form.owner, 'idCardFrontThumbnail', this.ownerData.idCardFrontThumbnail ? this.ownerData.idCardFrontThumbnail : '')
        this.$set(this.form.owner, 'idCardBackThumbnail', this.ownerData.idCardBackThumbnail ? this.ownerData.idCardBackThumbnail : '')

        this.idCardPreview.idCardFront = this.ownerData.idCardFront ? [this.FileUrl + this.ownerData.idCardFront] : []
        this.idCardPreview.idCardBack = this.ownerData.idCardBack ? [this.FileUrl + this.ownerData.idCardBack] : []
        this.idCardPreview.idCardFrontThumbnail = this.ownerData.idCardFrontThumbnail ? [this.FileUrl + this.ownerData.idCardFrontThumbnail] : []
        this.idCardPreview.idCardBackThumbnail = this.ownerData.idCardBackThumbnail ? [this.FileUrl + this.ownerData.idCardBackThumbnail] : []

        this.fileList.idCardFront = this.ownerData.idCardFront ? [{ url: this.FileUrl + this.ownerData.idCardFront }] : []
        this.fileList.idCardBack = this.ownerData.idCardBack ? [{ url: this.FileUrl + this.ownerData.idCardBack }] : []

        this.isFormEditOwner = false
        this.isEditBtnOwner = true
        console.log(this.form)
      }
      this.$refs['infoForm'].clearValidate()
    },
    changeEdit(owner) {
      console.log(owner)
      if (owner) {
        this.isEditBtnOwner = false
        this.isFormEditOwner = true
      } else {
        this.isFormEdit = true
        this.isEditBtn = false
      }
    },
    submitInfo(formName, edit) {
      this.$refs[formName].validate(async (valid) => {
        if (!valid) {
          return false
        }
        if (formName === 'infoForm' && edit) {
          if (edit === 'editOwner') {
            this.isEditBtnOwner = true
            this.isFormEditOwner = false
          } else {
            this.isEditBtn = true
            this.isFormEdit = false
          }
          this.editRecord()
        } else if (formName === 'infoForm') {
          this.createCode()
        } else {
          this.commitAudit()
        }
      })
    },
    // 页面返回前询问是否报错更改
    backConfirm() {
      this.$router.back()
    },
    handleClick() {
      console.log(this.activeName)
      if (this.activeName === 'third') {
        switch (this.finalTab) {
          case 'summary':
            this.getSummaryInfo()
            break

          default:
            break
        }
      }
    },
    // 图片上传成功回调
    onUploadSuccess(e, type) {
      const res = e.res
      if (res.code === 0) {
        if (type === 'details' || type === 'macro') {
          // console.log(res)
          // console.log(this.FileUrl)
          this.imgForm[type].push({ url: res.data.originalPath, urlThumbnail: res.data.compressPath, description: '' })
          // this.fileList[type].push({ name: res.data.filename, url: this.FileUrl + res.data.originalPath })
          this.$refs.imgFrom.validateField(type)
          // console.log(this.imgForm)
          // console.log(this.fileList)
          // 页面移动至指定锚点
          this.$nextTick(() => {
            this.$refs[`${type}-${this.imgForm[type].length - 1}`][0].$el.scrollIntoView()
          })
        } else if (type === 'idCardFront' || type === 'idCardBack') {
          this.idCardPreview[type].push(this.FileUrl + res.data.originalPath)
          this.idCardPreview[`${type}Thumbnail`].push(this.FileUrl + res.data.compressPath)
          this.$set(this.form.owner, type, res.data.originalPath)
          this.$set(this.form.owner, `${type}Thumbnail`, res.data.compressPath)
          this.fileList[type].push({ name: res.data.filename, url: this.FileUrl + res.data.originalPath })
          console.log(this.form)
          this.$refs.infoForm.validateField(`owner.${type}`)
        } else if (type === 'certificate') {
          this.$set(this.firstIdentifyForm, 'certificate', res.data)
          this.certificateList = [{
            name: e.file.name,
            url: this.FileUrl + res.data.originalPath
          }]
          this.$refs.firstIdentifyForm.validateField(type)
          console.log(this.firstIdentifyForm)
          console.log(this.certificateList)
          this.progressing = false
        } else {
          this.imgPreview[type].push(this.FileUrl + res.data.originalPath)
          this.imgCompress[type].push(this.FileUrl + res.data.compressPath)
          this.imgForm[type] = res.data.originalPath
          this.imgForm[`${type}Thumbnail`] = res.data.compressPath
          this.fileList[type].push({ name: res.data.filename, url: this.FileUrl + res.data.originalPath })
          console.log(this.fileList)
          this.$refs.imgFrom.validateField(type)
        }
      }
    },
    onFileRemove (e, type) {
      if (type === 'idCardFront' || type === 'idCardBack') {
        this.idCardPreview[type] = []
        this.idCardPreview[`${type}Thumbnail`] = []
        this.$set(this.form.owner, type, '')
        this.$set(this.form.owner, `${type}Thumbnail`, '')
        this.$set(this.fileList, type, [])
        // this.$refs.infoForm.validateField(`owner.${type}`)
      } else if (type === 'certificate') {
        // const url = this.firstIdentifyForm.certificate
        // console.log(url)
        this.certificateList = []
        this.$set(this.firstIdentifyForm, 'certificate', '')
        this.progressing = false
        // this.removeFile(url)
      } else {
        console.log(this.fileList)
        // const url = this.fileList[type][0].url
        this.fileList[type] = []
        this.imgPreview[type] = []
        this.imgCompress[type] = []
        this.imgForm[type] = ''
        this.imgForm[`${type}Thumbnail`] = ''
        this.fileList[type] = []
        // this.removeFile(url)
      }
    },
    onUploadProgress(event, file, fileList) {
      this.progressing = true
    },
    onUploadError() {
      this.progressing = false
    },
    onDetailsRemove(index) {
      // const url = this.fileList.details[index].url
      this.imgForm.details.splice(index, 1)
      this.fileList.details.splice(index, 1)
      // this.removeFile(url)
    },
    onMacroRemove(index) {
      // const url = this.fileList.macro[index].url
      this.imgForm.macro.splice(index, 1)
      this.fileList.macro.splice(index, 1)
      // this.removeFile(url)
    },

    // 创建藏品（档案）
    async createRecord() {
      try {
        this.$set(this.loading, 'info', true)
        const { data } = await this.$api.record.createRecord()
        if (data.code === 0) {
          this.form.archiveId = data.data.archiveId
          this.form.institution = data.data.institution
          this.archiveId = data.data.archiveId
          this.deptId = data.data.deptId
          this.createDeptId = data.data.createDeptId
          this.$set(this.loading, 'info', false)
          console.log(this.form)
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
          this.$set(this.loading, 'info', false)
        }
      } catch (err) {
        this.$set(this.loading, 'info', false)
        throw new Error(err)
      }
    },

    // 根据ID查询对应区块链信息
    async getChain() {
      try {
        const { data } = await this.$api.record.getChain({ archiveId: this.archiveId })
        if (data.code === 0) {
          this.chainInfo = data.data || null
          console.log(data.data)
        } else {
          this.$message({
            type: 'error',
            message: data.message || '查询对应区块链信息失败'
          })
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    // 根据ID获取藏品详细信息
    async getRecord() {
      try {
        this.$set(this.loading, 'info', true)
        const { data } = await this.$api.record.getRecord({ archiveId: this.archiveId })
        if (data.code === 0) {
          this.detailsData = data.data
          // 初始化表单是否可编辑状态
          this.status = data.data.status
          this.codeStatus = data.data.codeStatus
          if (this.pageType === 'record' && ((this.status === '建档中' && this.codeStatus === '待打码') || this.status === '建档中,打码完成' || this.status === '建档中，待打码' || (this.status === '建档中' && this.codeStatus === '打码完成') || this.status === '审核不通过')) {
            this.isEditBtn = true
            this.isEditBtnOwner = true
            this.isImgFromEdit = true
          } else if (this.status === '建档中') {
            this.isFormEdit = true
            this.isFormEditOwner = true
          }

          // TODO: 测试无误后删除
          // if (this.status === '建档中') {
          //   this.isFormEdit = true
          //   this.isFormEditOwner = true
          // }
          // if (this.pageType === 'record' && (this.status === '建档中,打码完成' || this.status === '审核不通过')) {
          //   this.isEditBtn = true
          //   this.isEditBtnOwner = true
          //   this.isImgFromEdit = true
          // }
          // if (this.pageType === 'record' && this.status === '建档中,待打码') {
          //   this.isImgFromEdit = true
          // }
          // TODO: 测试无误后删除

          // 初始化基本信息
          for (let i in this.form) {
            if (i !== 'owner') {
              this.form[i] = data.data[i]
            }
            this.$set(this.firstIdentifyForm, 'category', data.data.category ? data.data.category : '')
            this.$set(this.firstIdentifyForm, 'referenceYear', data.data.referenceYear ? data.data.referenceYear : '')
          }
          // 初始化存储地选择
          // this.cityAddr = data.data.storage.split(' / ')
          // console.log('this.cityAddr')
          // console.log(this.cityAddr)

          // 初始化暗码
          this.codeList = data.data.ciphers || []
          // 给暗码第一条数据添加全球唯一身份证
          this.codeList.forEach(item => {
            item.idCard = data.data.idCard || ''
          })
          console.log(this.codeList)
          // this.status = data.data.status
          this.qrCode = this.FileUrl + data.data.plainCode
          // 初始化图片信息
          if (data.data.picture && Object.keys(data.data.picture).length > 0) {
            this.fileList.main = data.data.picture.mainPicture.front ? [{ url: this.FileUrl + data.data.picture.mainPicture.front }] : []
            this.fileList.left = data.data.picture.mainPicture.left ? [{ url: this.FileUrl + data.data.picture.mainPicture.left }] : []
            this.fileList.right = data.data.picture.mainPicture.right ? [{ url: this.FileUrl + data.data.picture.mainPicture.right }] : []
            this.fileList.up = data.data.picture.mainPicture.up ? [{ url: this.FileUrl + data.data.picture.mainPicture.up }] : []
            this.fileList.down = data.data.picture.mainPicture.down ? [{ url: this.FileUrl + data.data.picture.mainPicture.down }] : []
            this.fileList.back = data.data.picture.mainPicture.back ? [{ url: this.FileUrl + data.data.picture.mainPicture.back }] : []
            this.fileList.panoramicView = data.data.picture.mainPicture.panoramicView ? [{ url: this.FileUrl + data.data.picture.mainPicture.panoramicView }] : []
            // 原图
            this.imgPreview.main = data.data.picture.mainPicture.front ? [this.FileUrl + data.data.picture.mainPicture.front] : []
            this.imgPreview.left = data.data.picture.mainPicture.left ? [this.FileUrl + data.data.picture.mainPicture.left] : []
            this.imgPreview.right = data.data.picture.mainPicture.right ? [this.FileUrl + data.data.picture.mainPicture.right] : []
            this.imgPreview.up = data.data.picture.mainPicture.up ? [this.FileUrl + data.data.picture.mainPicture.up] : []
            this.imgPreview.down = data.data.picture.mainPicture.down ? [this.FileUrl + data.data.picture.mainPicture.down] : []
            this.imgPreview.back = data.data.picture.mainPicture.back ? [this.FileUrl + data.data.picture.mainPicture.back] : []
            this.imgPreview.panoramicView = data.data.picture.mainPicture.panoramicView ? [this.FileUrl + data.data.picture.mainPicture.panoramicView] : []
            // 缩略图
            this.imgCompress.main = data.data.picture.mainPicture.frontThumbnail ? [this.FileUrl + data.data.picture.mainPicture.frontThumbnail] : []
            this.imgCompress.left = data.data.picture.mainPicture.leftThumbnail ? [this.FileUrl + data.data.picture.mainPicture.leftThumbnail] : []
            this.imgCompress.right = data.data.picture.mainPicture.rightThumbnail ? [this.FileUrl + data.data.picture.mainPicture.rightThumbnail] : []
            this.imgCompress.up = data.data.picture.mainPicture.upThumbnail ? [this.FileUrl + data.data.picture.mainPicture.upThumbnail] : []
            this.imgCompress.down = data.data.picture.mainPicture.downThumbnail ? [this.FileUrl + data.data.picture.mainPicture.downThumbnail] : []
            this.imgCompress.back = data.data.picture.mainPicture.backThumbnail ? [this.FileUrl + data.data.picture.mainPicture.backThumbnail] : []
            this.imgCompress.panoramicView = data.data.picture.mainPicture.panoramicViewThumbnail ? [this.FileUrl + data.data.picture.mainPicture.panoramicViewThumbnail] : []

            this.imgForm.main = data.data.picture.mainPicture.front ? data.data.picture.mainPicture.front : ''
            this.imgForm.left = data.data.picture.mainPicture.left ? data.data.picture.mainPicture.left : ''
            this.imgForm.right = data.data.picture.mainPicture.right ? data.data.picture.mainPicture.right : ''
            this.imgForm.up = data.data.picture.mainPicture.up ? data.data.picture.mainPicture.up : ''
            this.imgForm.down = data.data.picture.mainPicture.down ? data.data.picture.mainPicture.down : ''
            this.imgForm.back = data.data.picture.mainPicture.back ? data.data.picture.mainPicture.back : ''
            this.imgForm.panoramicView = data.data.picture.mainPicture.panoramicView ? data.data.picture.mainPicture.panoramicView : ''

            this.imgForm.mainThumbnail = data.data.picture.mainPicture.frontThumbnail ? data.data.picture.mainPicture.frontThumbnail : ''
            this.imgForm.leftThumbnail = data.data.picture.mainPicture.leftThumbnail ? data.data.picture.mainPicture.leftThumbnail : ''
            this.imgForm.rightThumbnail = data.data.picture.mainPicture.rightThumbnail ? data.data.picture.mainPicture.rightThumbnail : ''
            this.imgForm.upThumbnail = data.data.picture.mainPicture.upThumbnail ? data.data.picture.mainPicture.upThumbnail : ''
            this.imgForm.downThumbnail = data.data.picture.mainPicture.downThumbnail ? data.data.picture.mainPicture.downThumbnail : ''
            this.imgForm.backThumbnail = data.data.picture.mainPicture.backThumbnail ? data.data.picture.mainPicture.backThumbnail : ''
            this.imgForm.panoramicViewThumbnail = data.data.picture.mainPicture.panoramicViewThumbnail ? data.data.picture.mainPicture.panoramicViewThumbnail : ''

            this.imgForm.details = data.data.picture.microPicture || []
            // 用作更新信息时，清空当前fileList
            this.fileList.details = []
            this.imgForm.details.forEach(item => {
              this.fileList.details.push({ name: item.id, url: this.FileUrl + item.url })
            })

            this.imgForm.macro = data.data.picture.macroPicture || []
            // 用作更新信息时，清空当前fileList
            this.fileList.macro = []
            this.imgForm.macro.forEach(item => {
              this.fileList.macro.push({ name: item.id, url: this.FileUrl + item.url })
            })
          }
          if (this.pageType === 'record' || this.pageType === 'audit') {
            this.getOwnerInfo()
          }
          if (this.pageType === 'final-identify') {
            this.activeName = 'third'
            this.handleClick()
          }
          // 存放终鉴证书数据
          if (this.status === '终鉴中' || this.status === '终鉴完成') {
            this.dataCache = {
              idCard: data.data.idCard,
              name: data.data.name,
              plainCode: data.data.plainCode,
              nftHash: data.data.nftHash,
              front: data.data.picture.mainPicture.front,
              up: data.data.picture.mainPicture.up ? data.data.picture.mainPicture.up : '',
              ciphers: data.data.ciphers
            }
          }
          this.$set(this.loading, 'info', false)
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
          this.$set(this.loading, 'info', false)
        }
      } catch (err) {
        this.$set(this.loading, 'info', false)
        throw new Error(err)
      }
    },
    // 编辑/保存藏品信息
    async editRecord() {
      console.log(this.form)
      try {
        this.deptId && this.$set(this.form, 'deptId', this.deptId)
        this.createDeptId && this.$set(this.form, 'createDeptId', this.createDeptId)
        const { data } = await this.$api.record.editRecord(this.form)
        if (data.code === 0) {
          this.isSaved = true
          this.isFormEdit = false
          this.$message.success('保存成功')
          this.getRecord()
          // this.$router.back()
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }

        // this.getRecord()
      } catch (err) {
        throw new Error(err)
      }
    },
    // 获取持有人信息
    async getOwnerInfo() {
      try {
        const { data } = await this.$api.record.getOwnerInfo({ archiveId: this.archiveId })
        if (data.code === 0) {
          this.ownerData = data.data
          console.log(this.ownerData)
          this.$set(this.form.owner, 'ownerName', data.data.ownerName ? data.data.ownerName : '')
          this.$set(this.form.owner, 'areaCode', '86')
          this.$set(this.form.owner, 'mobilePhone', data.data.mobilePhone)
          this.$set(this.form.owner, 'idCardNumber', data.data.idCardNumber)
          this.$set(this.form.owner, 'idCardFront', data.data.idCardFront ? data.data.idCardFront : '')
          this.$set(this.form.owner, 'idCardBack', data.data.idCardBack ? data.data.idCardBack : '')
          this.$set(this.form.owner, 'idCardFrontThumbnail', data.data.idCardFrontThumbnail ? data.data.idCardFrontThumbnail : '')
          this.$set(this.form.owner, 'idCardBackThumbnail', data.data.idCardBackThumbnail ? data.data.idCardBackThumbnail : '')

          this.idCardPreview.idCardFront = data.data.idCardFront ? [this.FileUrl + data.data.idCardFront] : []
          this.idCardPreview.idCardBack = data.data.idCardBack ? [this.FileUrl + data.data.idCardBack] : []
          this.idCardPreview.idCardFrontThumbnail = data.data.idCardFrontThumbnail ? [this.FileUrl + data.data.idCardFrontThumbnail] : []
          this.idCardPreview.idCardBackThumbnail = data.data.idCardBackThumbnail ? [this.FileUrl + data.data.idCardBackThumbnail] : []

          this.fileList.idCardFront = data.data.idCardFront ? [{ url: this.FileUrl + data.data.idCardFront }] : []
          this.fileList.idCardBack = data.data.idCardBack ? [{ url: this.FileUrl + data.data.idCardBack }] : []

          this.ownerInitFinish = true
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    // 打码并生成暗码
    async createCode() {
      try {
        this.deptId && this.$set(this.form, 'deptId', this.deptId)
        this.createDeptId && this.$set(this.form, 'createDeptId', this.createDeptId)
        this.$set(this.loading, 'cipherBtn', true)
        const { data } = await this.$api.record.createCode(this.form)
        if (data.code === 0) {
          this.$message.success('提交成功')
          this.isSaved = true
          this.getRecord()
          this.isCreatePage = false
          this.isFormEdit = false
          this.isFormEditOwner = false
          this.$set(this.loading, 'cipherBtn', false)
          this.$router.replace({
            path: '/record/details',
            query: { archiveId: this.archiveId }
          })
        } else {
          this.$set(this.loading, 'cipherBtn', false)
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        this.$set(this.loading, 'cipherBtn', false)
        throw new Error(err)
      }
    },
    // 提交初步审核-提交档案图文信息
    async commitAudit() {
      if (!this.isEditBtn && !this.isEditBtnOwner && (this.status === '审核不通过' || this.status === '建档中,打码完成')) {
        this.$message.error('请确定建档中的藏品信息')
        return
      }
      let params = {
        archiveId: this.archiveId,
        mainPicture: {
          front: this.imgForm.main,
          left: this.imgForm.left,
          right: this.imgForm.right,
          back: this.imgForm.back,
          up: this.imgForm.up,
          down: this.imgForm.down,
          panoramicView: this.imgForm.panoramicView,
          frontThumbnail: this.imgForm.mainThumbnail,
          leftThumbnail: this.imgForm.leftThumbnail,
          rightThumbnail: this.imgForm.rightThumbnail,
          backThumbnail: this.imgForm.backThumbnail,
          upThumbnail: this.imgForm.upThumbnail,
          downThumbnail: this.imgForm.downThumbnail,
          panoramicViewThumbnail: this.imgForm.panoramicViewThumbnail
        },
        macroPicture: this.imgForm.macro,
        microPicture: this.imgForm.details
      }

      try {
        this.$loading({
          lock: true,
          text: '正在提交',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.7)'
        })
        this.$set(this.loading, 'submitAuditBtn', true)
        const { data } = await this.$api.record.commitAudit(params)
        if (data.code === 0) {
          this.$loading().close()
          this.$message.success('提交成功')
          this.isSaved = true
          this.$set(this.loading, 'submitAuditBtn', false)
          this.$router.push({ path: '/record/list' })
          // this.$router.back()
        } else {
          this.$loading().close()
          this.$set(this.loading, 'submitAuditBtn', false)
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        this.$loading().close()
        this.$set(this.loading, 'submitAuditBtn', false)
        throw new Error(err)
      }
    },
    // 保存档案图文信息
    async savePicture() {
      // if (!this.imgForm.main &&
      //     !this.imgForm.left &&
      //     !this.imgForm.right &&
      //     !this.imgForm.back &&
      //     !this.imgForm.up &&
      //     !this.imgForm.down &&
      //     !this.imgForm.panoramicView &&
      //     this.imgForm.macro.length === 0 &&
      //     this.imgForm.details.length === 0) {
      //   this.$message.error('没有可保存的图片')
      //   return
      // }
      let params = {
        archiveId: this.archiveId,
        mainPicture: {
          front: this.imgForm.main,
          left: this.imgForm.left,
          right: this.imgForm.right,
          back: this.imgForm.back,
          up: this.imgForm.up,
          down: this.imgForm.down,
          panoramicView: this.imgForm.panoramicView,
          frontThumbnail: this.imgForm.mainThumbnail,
          leftThumbnail: this.imgForm.leftThumbnail,
          rightThumbnail: this.imgForm.rightThumbnail,
          backThumbnail: this.imgForm.backThumbnail,
          upThumbnail: this.imgForm.upThumbnail,
          downThumbnail: this.imgForm.downThumbnail,
          panoramicViewThumbnail: this.imgForm.panoramicViewThumbnail
        },
        macroPicture: this.imgForm.macro,
        microPicture: this.imgForm.details
      }
      try {
        this.savePictureLoading = true
        const { data } = await this.$api.record.savePicture(params)

        this.savePictureLoading = false
        if (data.code === 0) {
          this.$message.success('图片已保存')

          this.getRecord()
          // this.isSaved = true
          // this.$router.push({ path: '/record/list' })
          // this.$router.back()
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        this.savePictureLoading = false
        throw new Error(err)
      }
    },
    // 获取可指派的鉴定机构列表
    async getidentifyInstituteList() {
      try {
        const { data } = await this.$api.department.getAllDepartmentList({
          deptType: '鉴定机构',
          verifyType: '初鉴'
        })
        if (data.code === 0) {
          this.identifyInstituteList = data.data
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }
        console.log(data)
      } catch (err) {
        throw new Error(err)
      }
    },
    // 审核指派初鉴机构表单校验
    identifyFormCheck() {
      this.$refs['indentifyForm'].validate((valid) => {
        if (valid) {
          const deptName = this.identifyInstituteList.find(item => item.deptId === this.identifyForm.institute).deptName
          this.identifyForm.deptName = deptName
          console.log(this.identifyForm)
          this.$refs.auditComp.passConfirm(this.identifyForm)
        } else {
          return false
        }
      })
    },
    // 指定的鉴定类型和指定的机构
    async getAuditAssignInfo() {
      try {
        const { data } = await this.$api.record.getAuditAssignInfo({
          archiveId: this.archiveId,
          identifyType: '初鉴'
        })
        if (data.code === 0) {
          this.assignedIdentifyInfo = data.data
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    async saveFirstIdentify(flag) {
      // flag 标识保存时的操作状态：
      //    certificate为预览证书时
      //    doubt为点击存疑后
      //    null为正常点击保存的时候
      //    beforeSubmit为提交初鉴结果前的资料保存
      try {
        this.$set(this.loading, 'firstIdentifySaveBtn', true)
        const { data } = await this.$api.record.saveFirstIdentify({
          archiveId: this.archiveId,
          certificateNumber: this.firstIdentifyForm.certificateNumber,
          opinion: this.firstIdentifyForm.opinion || '意见未填写',
          remark: this.firstIdentifyForm.remark,
          category: this.firstIdentifyForm.category,
          referenceYear: this.firstIdentifyForm.referenceYear
        })
        if (data.code === 0) {
          this.certificateId = data.data
          this.countdown = 5
          this.countdownFlag = true
          this.$set(this.loading, 'firstIdentifySaveBtn', false)
          if (flag !== 'doubt' && flag !== 'beforeSubmit') {
            this.$message({
              type: 'success',
              message: '保存成功'
            })
          }
          this.getSavedFirstIdentifyForm(this.stepActive)
          let timer = setInterval(() => {
            this.countdown--
            if (this.countdown === 0) {
              clearInterval(timer)
              this.enableSave = true
              this.countdownFlag = false
            }
          }, 1000)
          if (flag === 'certificate') {
            this.openCertificateViewPage()
          } else if (flag === 'doubt') {
            this.$refs.identifyOpts.doubtConfirm()
          }
        } else {
          this.$set(this.loading, 'firstIdentifySaveBtn', false)
          this.$message({
            type: 'error',
            message: data.message
          })
          this.enableSave = true
        }
      } catch (err) {
        this.$set(this.loading, 'firstIdentifySaveBtn', false)
        this.enableSave = true
        throw new Error(err)
      }
    },
    // 保存初鉴表单
    saveFirstIdentifyForm(flag = null) {
      console.log(this.firstIdentifyForm)
      if (this.enableSave || flag) {
        const fieldList = ['category', 'referenceYear', 'opinion']
        const _this = this
        Promise.all(
          fieldList.map(field => {
            let p = new Promise((resolve, reject) => {
              _this.$refs.firstIdentifyForm.validateField(field, (err) => {
                if (err) {
                  resolve(err)
                } else {
                  resolve('check-passed')
                }
              })
            })
            return p
          })
        ).then((res) => {
          console.log(res)
          let count = 0
          res.forEach(item => {
            if (item === 'check-passed') {
              count++
            }
          })
          if (count === res.length) {
            this.enableSave = false
            this.saveFirstIdentify(flag)
          } else {
            return false
          }
        })

        // this.$refs.firstIdentifyForm.validateField('opinion', (error) => {
        //   if (error) {
        //     return false
        //   } else {
        //     this.enableSave = false
        //     this.saveFirstIdentify(flag)
        //   }
        // })
      }
    },
    // async saveFirstIdentifyCategoryAndReferenceYear(flag) {
    //   this.$set(this.loading, 'firstIdentifySaveBtn', true)
    //   try {
    //     const { data } = await this.$api.record.editRecord({
    //       archiveId: this.archiveId,
    //       category: this.firstIdentifyForm.category,
    //       referenceYear: this.firstIdentifyForm.referenceYear
    //     })
    //     if (data.code === 0) {
    //       this.saveFirstIdentify(flag)
    //     } else {
    //       this.$set(this.loading, 'firstIdentifySaveBtn', false)
    //       this.$message({
    //         type: 'error',
    //         message: data.message
    //       })
    //       this.enableSave = true
    //     }
    //   } catch (err) {
    //     this.$set(this.loading, 'firstIdentifySaveBtn', false)
    //     this.enableSave = true
    //     throw new Error(err)
    //   }
    // },
    viewCertificate() {
      // console.log(this.certificateUrl)
      window.open(this.certificateUrl, 'blank')
    },
    // 预览证书前先保存当前输入内容
    handleCertificateView() {
      this.saveFirstIdentifyForm('certificate')
    },
    // 证书预览下载
    openCertificateViewPage() {
      // 打开新窗口
      let path = this.$router.resolve({
        name: 'certificate-template',
        params: { archiveId: this.archiveId }
      })
      window.open(path.href, '_blank')
    },
    // 删除上传的文件
    async removeFile(url) {
      try {
        const { data } = await this.$api.record.removeFile({ params: { url: url } })
        console.log(data)
      } catch (err) {
        throw new Error(err)
      }
    },
    // 校验初鉴内容必填项
    validateFirstIdentifyForm(flag) {
      this.$refs.firstIdentifyForm.validate((valid) => {
        if (!valid) {
          return false
        } else {
          this.saveAndSubmitResult(flag)
          // this.$refs.identifyOpts.validatePass(flag, this.firstIdentifyForm.certificate)
        }
      })
    },
    async saveAndSubmitResult(flag) {
      await this.saveFirstIdentify('beforeSubmit')
      this.$refs.identifyOpts.validatePass(flag, this.firstIdentifyForm.certificate)
    },
    // 回显已保存的初鉴内容
    async getSavedFirstIdentifyForm(active) {
      if (active >= 4 && this.status !== '待指派') {
        try {
          const { data } = await this.$api.certificate.previewCertificate({
            archiveId: this.archiveId
          })
          if (data.code === 0) {
            if (data.data) {
              if (data.data.certificateNumber) {
                this.$set(this.firstIdentifyForm, 'certificateNumber', data.data.certificateNumber)
              }
              if (data.data.opinion) {
                this.$set(this.firstIdentifyForm, 'opinion', data.data.opinion)
              }
              if (data.data.remark) {
                this.$set(this.firstIdentifyForm, 'remark', data.data.remark)
              }
              if (data.data.certificateUrl) {
                this.certificateUrl = this.FileUrl + data.data.certificateUrl
              }
            }
          } else {
            this.$message({
              type: 'error',
              message: data.message
            })
          }
        } catch (err) {
          throw new Error(err)
        }
      }
    },
    // 存疑操作
    handleDoubt() {
      this.saveFirstIdentify('doubt')
    },
    // 权限查询
    hasPermission(tag) {
      return this.permissionsTags.includes(tag)
    },
    // 前往申请终鉴页面
    handleApply() {
      this.$router.push({
        name: 'application',
        params: {
          archiveId: this.archiveId
        }
      })
    },
    handleTabChange(tab) {
      if (tab === 'summary') {
        this.getSummaryInfo()
      }
      this.finalTab = tab
    },
    // 获取终鉴详情综合页信息
    async getSummaryInfo() {
      try {
        this.$set(this.loading, 'summary', true)
        const { data } = await this.$api.finalIdentify.getSummaryInfo({
          archiveId: this.archiveId
        })
        if (data.code === 0) {
          console.log(data.data)
          console.log(this.userInfo)
          this.finalSummaryInfo = data.data
          this.finalResult = this.checkFinalSubmitStatement()
          console.log(this.finalResult)
        }
        this.$set(this.loading, 'summary', false)
        this.finalSummaryInfoLoadedFinished = true
      } catch (err) {
        this.$set(this.loading, 'summary', false)
        throw new Error(err)
      }
    },
    // 跳转至终鉴详情(确真、确权、确值、法律)
    goSubmit(flag) {
      this.$refs.finalIdentifyDetails.changeRadio(flag)
      this.finalTab = flag
      this.$nextTick(() => {
        this.$refs[`${flag}Details`].$el.scrollIntoView()
      })
    },
    // 返回综合页
    handleSubmitFinished() {
      // this.handleTabChange('summary')
      this.$router.go(0)
    },
    // 检验确真、确权、确值和法律相关鉴定是否提交完成
    checkFinalSubmitStatement() {
      return this.checkSinglePartStatement('identifyRealVos') &&
             this.checkSinglePartStatement('identifyAuthorities') &&
             this.checkSinglePartStatement('identifyValues') &&
             this.checkSinglePartStatement('identifyLegals')
    },
    checkSinglePartStatement(flag) {
      let finished = true
      this.finalSummaryInfo[flag].forEach(dept => {
        if (!dept.file) {
          finished = false
        }
      })
      return finished
    },
    handleSubmitFinalInfo() {
      this.sumbitFinalInfoFlag = true
    }
  },
  filters: {
    timeFilter(val) {
      if (!val) {
        return ''
      }
      return timeFormat(val, 'YYYY-MM-DD HH:mm')
    },
    timeFilter2(val) {
      if (!val) {
        return ''
      }
      return timeFormat(val, 'YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>

<style lang="scss">
.el-textarea .el-input__count {
  background-color: transparent;
  color: $--color-text-secondary;
}
</style>

<style lang="scss" scoped>
::v-deep .el-form + .el-card{
  margin-top: 32px;
}
::v-deep .el-card +.el-form {
  margin-top: 32px;
}
::v-deep .el-form +.el-form {
  margin-top: 32px;
}
::v-deep .el-tabs {
  .el-tabs__header {
    margin-bottom: 28px;
    .el-tabs__nav-wrap {
      .el-tabs__item {
        font-size: 16px;
        color: $--color-text-secondary;
        &.is-active {
          color: #267CDC;
        }
      }
      &:after {
        background-color: transparent;
      }
    }
  }
}
.record-details {
  min-width: 1000px;
  // background-color: #fff;
  ::v-deep .el-card__body {
    padding: 24px 24px 24px 24px;
    // max-width: 960px;
  }
  .left-content {
    position: relative;
    width: calc(100% - 190px);
    min-width: 570px;
    max-width: 1000px;
  }
  .steps {
    width: 150px;
    height: 432px;
    margin-left: 40px;
  }
  .ym-panel-radius {
    position: relative;
  }
  .final-identify-block {
    position: relative;
    width: calc(100% - 190px);
    min-width: 570px;
    max-width: 1000px;
    .final-identify-btn {
      position: absolute;
      right: 0;
      top: 4px;
      z-index: 99999;
    }
  }
}
.no-code {
  text-align: center;
  position: relative;
  font-size: 30px;
  font-weight: 600;
  color: $--color-warning;
  .el-button {
   margin-left: 40px;
   vertical-align: middle;
    font-size: 16px;
  }
}
.has-code {
 ::v-deep .el-table {
    width: 600px;
    float: left;
    .cell{
      max-height: 46px !important;
    }
  }
}
.coding {
  height: 200px;
  line-height: 200px;
}
.qr-code {
  float: right;
  width: 150px;
  height: 150px;
  line-height: 150px;
  position: relative;
  .el-image{
    position: initial;
    display: block;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .hover-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    font-size: $--font-size-base;
    span{
      position: absolute;
      width: 75%;
      top: 50%;
      text-align: center;
      left: 50%;
      transform: translate(-50%, -50%);
      display: inline-block;
      line-height: 1.4;
    }
  }
  &.code-img{
    img{
      width: 60%;
    height: 60%;
    vertical-align: middle;
    }
  }
}

.desc {
  padding: 16px;
  background-color: $--color-primary-light-9;
  word-break: break-all;
}

::v-deep {
  .el-form-item__content {
    word-break: break-all;
    &>p{
      line-height: 1.5;
      margin-top: 6px;
      text-align: justify;
    }
    .el-select{
      width: 100%;
    }
}
  .center .el-card__body{
    text-align: center;
  }

 .bold-label .el-form-item__label{
  font-size: $--font-size-large;
  color: $--color-text-primary;
  font-weight: 600;
  }
  .upload-drag{
    margin-top: 32px;
  }
  .panoramas{
  .upload-comp .preview{
    width: 100%;
    max-width: 700px
  }
}
}

.btns{
  text-align: right;
  &.btns-top {
    position: absolute;
    right: 24px;
    z-index: 999;
  }
}
.details-img-preview{
  .el-row {
    display: flex;
    align-items: center;
  }
  .preview{
      display: inline-block;
      width: 100%;
      max-width: 300px;
      height: 200px;
      position: relative;
      overflow: hidden;
      .el-image{
        width: 100%;height: 100%;
      }
      .bottom{
        cursor: context-menu;
        position: absolute;
        background-color: rgba(0,0,0,.6);
        color:#fff;
        bottom: -40px;left: 0;
        width: 100%;height: 40px;line-height: 40px;text-align: center;
        transition: all 0.3s ease;
      }
      &:hover{
        .bottom{
          transform:translate(0, -40px);
        }
      }
      .close{
        position: absolute;
        color: #ccc;
        top: 4px;right: 4px;
        font-size: 30px;
        cursor: pointer;
        &:hover{
          color: $--color-danger;
        }
      }
    }
}

::v-deep.owner-phone {
        .el-input-group__prepend {
          max-width: 72px;
          width: 72px;
          background-color: #fff;
          div.el-select {
            .el-input__inner {
              width: 80px;
              color: $--color-text-regular;
            }
            .el-input__suffix {
              right: -32px;
            }
          }
        }
      }

.audit-info {
  margin-top: 32px;
}

.first-identify-form {
  ::v-deep.el-upload {
    display: block;
    text-align: left;
  }
  ::v-deep.el-button {
    width: 100px;
  }
  .save-button {
    text-align: center;
    ::v-deep.el-button {
      margin-bottom: 18px;
    }
  }
  .certificate-part {
    border-top: 1px solid $--border-color-base;
    padding-top: 18px;
    ::v-deep.el-upload--text {
      width: 100px;
    }
  }
  .tip {
    margin-top: 7px;
    font-size: 12px;
    color: #a3a3a3;
    &.wait-coding {
      color: #D40000;
    }
  }
  ::v-deep.el-upload__tip {
    color: #a3a3a3;
    &.wait-coding {
      color: #D40000;
    }
  }
  ::v-deep.el-upload-list {
    position: relative;
    top: -6px;
    .el-upload-list__item {
        transition: none !important;
      }
  }
}

::v-deep.identify-show {
  .el-form-item__label {
    line-height: 1.5;
  }
  .identify-show-block {
    padding-right: 15px;
    line-height: 1.5;
    p {
      text-align: justify;
    }
  }
}

.owner-info {
  user-select: none;
  .wrap-icon {
    margin-left: 8px;
    color: #267CDC;
    cursor: pointer;
  }
}
</style>
