<template>
  <div class="card-item">
    <el-card class="cell" v-for="item in data" :key="item.archiveId">
      <div class="content" @click="toDetails(item)">
        <div class="img">
          <el-image :src="FileUrl + item.mainPicture" fit="contain" lazy>
            <img class="alt-img" slot="placeholder" src="../img/logo-header.png" alt="">
            <img class="alt-img" slot="error" src="../img/logo-header.png" alt="">
          </el-image>
        </div>
        <div class="text">
          <div class="text-flex">
            <div class="flex-left">
              <p class="ellipsis name" :title="item.name">{{item.name}}</p>
              <p class="ellipsis" :title="item.archiveId">ID：{{item.archiveId}}</p>
            </div>
            <div class="flex-right">
              <el-tag disable-transitions size="mini" v-if="item.status === '建档中' || item.status === '建档中,待打码' || item.status === '建档中,打码完成'" class="tag-editing">建档中</el-tag>
              <el-tag disable-transitions size="mini" v-if="item.status === '建档中,待打码'" class="tag-wait-coding">待打码</el-tag>
              <el-tag disable-transitions size="mini" v-if="item.status === '建档中,打码完成'" class="tag-finish-coding">打码完成</el-tag>
              <el-tag size="mini" v-if="item.status === '待审核'" class="tag-wait-audit">待审核</el-tag>
              <el-tag disable-transitions size="mini" v-if="item.status === '待指派'" class="tag-wait-assign">待指派</el-tag>
              <el-tag disable-transitions size="mini" v-if="item.status === '待初鉴'" class="tag-wait-identify">待初鉴</el-tag>
              <el-tag disable-transitions size="mini" v-if="item.status === '已初鉴'" class="tag-wait-triple-confirm">已初鉴</el-tag>
              <el-tag disable-transitions size="mini" v-if="item.status === '存疑'" class="tag-not-sure">存疑</el-tag>
              <el-tag disable-transitions size="mini" v-if="item.status === '艺术品'" class="tag-artwork">艺术品</el-tag>
              <el-tag disable-transitions size="mini" v-if="item.status === '审核不通过'" class="tag-not-approved">审核不通过</el-tag>
              <el-tag disable-transitions size="mini" v-if="item.codeStatus === '待打码'" class="tag-wait-coding">待打码</el-tag>
              <el-tag disable-transitions size="mini" v-if="item.status === '终鉴中'" class="tag-final-progressing">终鉴中</el-tag>
              <el-tag disable-transitions size="mini" v-if="item.status === '终鉴完成'" class="tag-final-finish">终鉴完成</el-tag>
            </div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    toDetails(item) {
      this.$emit('toDetails', item)
    }
  }
}
</script>

<style lang="scss" scoped>
$img-width: 290px;
.card-item {
  width:calc(100% + 32px);
  margin-left: -16px;
  font-size: 0;
  .cell{
    display: inline-block;
    font-size: $--font-size-base;
    margin-left: 12px;
    margin-right: 12px;
    border-radius: 8px;
    &.el-card {
      margin-bottom: 24px;
      &+ .el-card {
        margin-top: 0;
      }
    }

    ::v-deep.el-card__body {
      padding: 0;
    }
  }
  .content{
    width: $img-width;
    cursor: pointer;
  }
  .img{
    width: 100%;
    height: ($img-width / 3 * 2);
    overflow: hidden;
    .el-image{
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
  .text{
    width: 100%;
    height: 64px;
    padding: 9px 12px;
    background: linear-gradient(270deg, #E9ECF4 0%, #F9FBFF 100%);
    color:#000;
    .text-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      width: 100%;
    }
    .flex-left {
      overflow: hidden;
      p + p {
        margin-top: 4px;
      }
    }
    .flex-right {
      align-self: flex-start;
      .el-tag {
        text-align: center;
      }
    }
  }
  ::v-deep .text .el-tag{
    display: block;
    &+.el-tag{
      margin-top: 4px;
    }
  }
  .alt-img{
    width: 100%;
    filter: grayscale(0.6);
    opacity: .5;
  }
}
</style>
