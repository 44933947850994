<template>
  <div class="selection-table">
    <el-card>
      <div class="ym-head clearfix">
        <div class="ym-title-border">{{title}}</div>
      </div>
      <div>
        <div class="search-bar">
          <el-row :gutter="16">
            <el-col :span="6">
              <el-input v-model="search" placeholder="请输入机构名称" prefix-icon="el-icon-search" size="small" clearable @change="handleSearch"></el-input>
            </el-col>
          </el-row>
        </div>
        <no-record v-if="deptList.length === 0" class="no-record"></no-record>
        <div class="table-list" v-loading="loading" v-else>
          <!-- 单选 -->
          <el-radio-group v-model="selectedDept" class="selection-group" v-if="deptType !== '终鉴-确值'" @change="handleSelectedDeptChange">
            <template v-for="dept in deptList" >
              <el-card class="selection-block" :key="dept[dist.id]" :title="dept[dist.label]" shadow="hover">
                <el-radio :label="dept[dist.id]" size="small" class="ellipsis">{{dept[dist.label]}}</el-radio>
              </el-card>
            </template>
          </el-radio-group>
          <!-- 多选 -->
          <el-checkbox-group v-model="selectedDeptArr" class="selection-group" :max="maxSelected" @change="handleSelectedDeptArrChange" v-else>
            <template v-for="dept in deptList">
              <el-card class="selection-block" :key="dept[dist.id]" :title="dept[dist.label]" shadow="hover">
                <el-checkbox :label="dept[dist.id]" size="small" class="ellipsis">{{dept[dist.label]}}</el-checkbox>
              </el-card>
            </template>
          </el-checkbox-group>
          <div class="ym-table-bottom">
            <slot name="bottom"></slot>
            <YmPagination :pageData="pageData" @handlePageChange="handlePageChange"></YmPagination>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import YmPagination from '@/components/TableList/YmPagination.vue'

export default {
  components: {
    YmPagination
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    deptType: {
      type: String,
      default: ''
    },
    maxSelected: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      search: '',
      selectedDept: '', // 存储单选机构ID
      selectedDeptArr: [], // 存储多选机构ID
      selectedDeptInfoArr: [], // 存储多选机构的所有信息(id, 名称等)
      hasPagination: false,
      deptList: [],
      pageData: {
        size: 10, // 每页的数据量
        total: 15, // 数据总量
        layout: 'prev, pager, next, total, jumper, slot',
        currentPage: 1 // 当前页
      },
      dist: {
        id: 'deptId',
        label: 'deptName',
        value: 'deptId'
      },
      loading: false
    }
  },
  mounted() {
    this.getList()
  },
  watch: {
    pageData: {
      handler(val) {
        this.hasPagination = this.checkPagination()
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 点击页码跳转页面
    handlePageChange(page) {
      this.$set(this.pageData, 'currentPage', page)
      this.getList()
    },
    // 查询是否具有分页
    checkPagination() {
      return this.pageData && Object.keys(this.pageData).length && this.pageData.total && this.pageData.size && (this.pageData.total > this.pageData.size)
    },
    handleSearch() {
      this.$set(this.pageData, 'currentPage', 1)
      this.getList()
    },
    async getList() {
      try {
        this.loading = true
        const { data } = await this.$api.finalIdentify.getDeptList({
          page: this.pageData.currentPage,
          size: this.pageData.size,
          verifyType: this.deptType,
          deptName: this.search
        })
        if (data.code === 0) {
          this.deptList = data.data.records
          this.pageData.total = data.data.total
          this.loading = false
        } else {
          this.loading = false
          this.$$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        this.loading = false
        throw new Error(err)
      }
    },
    // 单选选项发生改变
    handleSelectedDeptChange(val) {
      let deptInfo = this.deptList.find(item => item[this.dist.id] === this.selectedDept)
      this.$emit('selectChange', this.selectedDept, deptInfo[this.dist.label])
    },
    // 多选选项发生改变
    handleSelectedDeptArrChange(val) {
      this.saveDeptInfo()
      let deptInfoArr = []
      this.selectedDeptArr.forEach(dept => {
        let temp = this.selectedDeptInfoArr.find(info => info[this.dist.id] === dept)
        deptInfoArr.push(temp[this.dist.label])
      })
      this.$emit('selectChange', this.selectedDeptArr, deptInfoArr)
    },
    // 获取当前页中的机构信息
    getDeptInfo(id) {
      return this.deptList.find(dept => dept[this.dist.id] === id)
    },
    saveDeptInfo() {
      // 将多选项的信息存入暂存空间，防止翻页后机构列表数据丢失导致无法找出对应机构名称
      this.selectedDeptArr.forEach(deptId => {
        if (!this.selectedDeptInfoArr.find(deptInfo => deptInfo[this.dist.id] === deptId)) {
          let info = this.getDeptInfo(deptId)
          info && this.selectedDeptInfoArr.push(info)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.selection-table {
  margin-top: 32px;
  .search-bar {
    margin-bottom: 12px;
  }
  .table-list {
    ::v-deep.selection-group {
      width: 100%;
      .selection-block {
        width: 20%;
        display: inline-block;
        .el-card__body {
          padding: 0;
          .ellipsis {
            width: 100%;
            padding: 24px;
            .el-radio__label, .el-checkbox__label {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              line-height: 1.5;
            }
            .el-checkbox__label {
              display: inline;
            }
          }
        }
      }
      .el-card {
        border-radius: 0;
        &+ .el-card {
          margin-top: 0;
        }
      }
    }
  }
  .ym-table-bottom {
    padding: 24px 32px;
    position: relative;
    height: 70px;
  }
  .no-record {
    font-size: 14px;
  }
}
</style>
