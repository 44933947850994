// 待指派列表
export const assignColumns = [
  {
    label: '藏品前视图',
    prop: 'main',
    align: 'center',
    width: 200,
    show: true,
    enableSlot: true
  },
  {
    label: '藏品名称',
    prop: 'name',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '分类',
    prop: 'category',
    align: 'center',
    show: true
  },
  {
    label: '派单中心',
    prop: 'institution',
    align: 'center',
    show: true
  },
  {
    label: '派单类型',
    prop: 'assignType',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '派单时间',
    prop: 'assignTime',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '操作',
    prop: 'operators',
    fixed: 'right',
    width: 160,
    align: 'center',
    children: {
      detail: {
        label: '藏品详情',
        color: '#267CDC',
        clickEvent: (thisVue, row) => {
          thisVue.toDetailsBlank(row)
        }
      },
      assign: {
        label: '指派',
        color: '#267CDC',
        clickEvent: (thisVue, row) => {
          thisVue.$set(thisVue.assignForm, 'archiveId', row.archiveId)
          thisVue.$set(thisVue.assignForm, 'name', row.name)
          thisVue.$set(thisVue.assignForm, 'expert', '')
          thisVue.getExpertList()
          thisVue.dialogVisible = true
        },
        showFilter: function(item, row, thisVue) {
          // return row.status === '待指派'
          // return true
          return thisVue.$parent.hasPermission('leader') || thisVue.$parent.hasPermission('admin')
        }
      }
    }
  }
]
// 待初鉴列表
export const firstIdentifyColumns = [
  {
    label: '藏品前视图',
    prop: 'main',
    align: 'center',
    width: 200,
    show: true,
    enableSlot: true
  },
  {
    label: '藏品名称',
    prop: 'name',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '分类',
    prop: 'category',
    align: 'center',
    show: true
  },
  {
    label: '派单中心',
    prop: 'institution',
    align: 'center',
    show: true
  },
  {
    label: '派单类型',
    prop: 'assignType',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '初鉴状态',
    prop: 'status',
    align: 'center',
    show: true
  },
  {
    label: '打码状态',
    prop: 'codeStatus',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '派单时间',
    prop: 'assignTime',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '初鉴专家',
    prop: 'expert',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '操作',
    prop: 'operators',
    fixed: 'right',
    width: 200,
    align: 'center',
    children: {
      identify: {
        label: '藏品初鉴',
        color: '#267CDC',
        clickEvent: (thisVue, row) => {
          thisVue.toDetails(row)
        },
        // 对机构负责人隐藏
        showFilter: (item, row, thisVue) => {
          // 只有当前藏品的受指派人才能显示
          return thisVue.$parent.checkAssign(row)
        }
      },
      detail: {
        label: '藏品详情',
        color: '#267CDC',
        clickEvent: (thisVue, row) => {
          thisVue.toDetailsBlank(row)
        },
        // 对机构负责人隐藏
        showFilter: (item, row, thisVue) => {
          // 当领导不是当前藏品的受指派人
          return !thisVue.$parent.checkAssign(row) && (thisVue.$parent.hasPermission('leader') || thisVue.$parent.hasPermission('operator') || thisVue.$parent.hasPermission('admin'))
        }
      },
      assign: {
        label: '修改指派',
        color: '#267CDC',
        clickEvent: (thisVue, row) => {
          console.log(row)
          thisVue.$set(thisVue.assignForm, 'archiveId', row.archiveId)
          thisVue.$set(thisVue.assignForm, 'name', row.name)
          thisVue.$set(thisVue.assignForm, 'expert', row.userId)
          thisVue.getExpertList()
          thisVue.dialogVisible = true
        },
        showFilter: (item, row, thisVue) => {
          // 只有机构负责人才显示该操作
          // return row.status === '待指派'
          return thisVue.$parent.hasPermission('leader') || thisVue.$parent.hasPermission('admin')
        }
      }
    }
  }
]
// 已初鉴列表
export const finishIdentifyColumns = [
  {
    label: '藏品前视图',
    prop: 'main',
    align: 'center',
    width: 200,
    show: true,
    enableSlot: true
  },
  {
    label: '藏品名称',
    prop: 'name',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '分类',
    prop: 'category',
    align: 'center',
    show: true
  },
  {
    label: '派单中心',
    prop: 'institution',
    align: 'center',
    show: true
  },
  {
    label: '初鉴结果',
    prop: 'result',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '初鉴专家',
    prop: 'expert',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '初鉴时间',
    prop: 'identifyTime',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '操作',
    prop: 'operators',
    fixed: 'right',
    width: 160,
    align: 'center',
    children: {
      detail: {
        label: '藏品详情',
        color: '#267CDC',
        clickEvent: (thisVue, row) => {
          thisVue.toDetailsBlank(row)
        }
      }
    }
  }
]
