<template>
<!-- 公共上传文件组件 -->
<!-- uploadFileUrl是在src/config/index.js配置的文件上传路径 -->
<div class="upload-only-file">
  <el-upload
    :ref="ref"
    :with-credentials="false"
    :action="uploadFileUrl"
    :name="name"
    :disabled="disabled"
    :limit="limit"
    :show-file-list="showFileList"
    :file-list="fileList"
    :on-exceed="onExceed"
    :on-success="onSuccess"
    :on-error="onError"
    :on-progress="onProgress"
    :before-upload="beforeUpload"
    :on-remove="onRemove"
    :headers="headers"
    :on-preview="onPreview">

    <slot>
      <!-- <el-button size="small" icon="el-icon-upload2">上传</el-button> -->
    </slot>
    <span slot="tip"><slot name="tip"></slot></span>

  </el-upload>
</div>
</template>

<script>
import { getToken } from '@/utils/localStorage.js'

export default {
  name: 'uploadFileComp',
  props: {
    fileList: {
      type: Array,
      default: () => {
        return []
      }
    },
    limit: {
      type: Number,
      default: 1
    },
    // 是否显示已上传文件列表
    showFileList: {
      type: Boolean,
      default: true
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 文件对应字段，看接口要求
    name: {
      type: String,
      default: 'multipartFile'
    },
    // 上传接口需要传入的businessType字段：业务类型 order=订单，company=公司文件，user=用户文件
    businessType: {
      type: String,
      default: 'company'
    },
    // 文件格式不匹配的错误提示文本
    typeErrorMsg: {
      type: String,
      default: '请按要求的格式上传文件'
    },
    // 文件大小限制，k为单位，默认限制1M以内，即1024k
    sizeLimit: {
      type: Number,
      default: 1024
    },
    // 用正则来校验fileName的后缀名，来校验文件类型，考虑Mac上的兼容性，通过fileName来判断文件类型，默认支持图片类型
    acceptTypes: {
      type: RegExp,
      default: () => {
        return /.jpg$|.jpeg$|.png$|.bmp$|.JPG$|.JPEG$|.PNG$|.BMP$/
      }
    },
    headers: {
      type: Object,
      default: () => {
        return { 'dreamkey-token': getToken() }
      }
    }
  },
  data() {
    return {
      // loading: false,
      ref: 'fileUpload_' + Math.floor(Math.random() * 10000)
    }
  },
  created() {
  },
  computed: {
  },
  watch: {
    params: {
      handler: function (val) {
        this.getList()
      },
      deep: true
    }
  },
  methods: {
    /** 文件上传成功 */
    onSuccess(res, file) {
      // this.loading = false
      if (res.code === 0) {
        this.$emit('onSuccess', { res, file })
      } else {
        // 词句只适用于limit=1的场景
        this.$refs[this.ref].clearFiles()
        this.$message.error(res.message || '上传失败')
      }
    },
    onError(err, file, fileList) {
      // this.loading = false
      this.$emit('onError', err, file, fileList)
    },
    onRemove(file, fileList) {
      this.$emit('onRemove', { file, fileList })
    },
    onExceed() {
      this.$message.warning(`限制上传${this.limit}个文件,如果要重新上传，请先删除已选记录`)
      this.$emit('onExceed')
    },
    onProgress(event, file, fileList) {
      this.$emit('onProgress', event, file, fileList)
    },
    /**
     * 上传之前的文件检查
     * */
    beforeUpload(file) {
      const acceptTypes = this.acceptTypes
      const isLimit = file.size / 1024 < this.sizeLimit
      const fileName = file.name
      if (!fileName || !acceptTypes.test(fileName)) {
        this.$message.error(this.typeErrorMsg)
        return false
      }
      if (!isLimit) {
        this.$message.error(`上传文件大小不能超过 ${(this.sizeLimit / 1024).toFixed(2)}MB!`)
      } else {
        // this.loading = true
      }

      return isLimit
    },
    onPreview(file) {
      console.log(file)
      window.open(file.url, 'blank')
    }

  }
}
</script>

<style lang="scss">
  .upload-only-file {
    .el-upload {
      display: block;
      text-align: left;
    }
    .el-upload-list {
      position: relative;
      top: -6px;
      .el-upload-list__item {
        transition: none !important;
      }
      &.disabled {
        .el-upload-list__item-status-label {
          display: none;
        }
      }
    }
  }
</style>

<style lang="scss" scoped>
.upload-only-file {
    ::v-deep.el-upload-list {
      &.is-disabled {
        .el-upload-list__item-status-label {
          display: none;
        }
      }
    }
  }
</style>
