<template>
  <div class="record-list" ref="recordList">
      <!-- 搜索栏 -->
      <div class="block">
        <div class="top-left">
          <!-- 类型选择菜单 -->
          <div class="selector">
            <el-select v-model="selectedCenter"
                       placeholder="分中心"
                       clearable
                       filterable
                       @change="handleSearch">
              <el-option v-for="center in centerList"
                         :key="center.deptId"
                         :label="center.deptName"
                         :value="center.deptId">
              </el-option>
            </el-select>
          </div>

          <div class="selector">
            <el-select v-model="selectedOrganization"
                       placeholder="鉴定机构"
                       clearable
                       filterable
                       @change="handleSearch">
              <el-option v-for="organization in organizationList"
                         :key="organization.deptId"
                         :label="organization.deptName"
                         :value="organization.deptId">
              </el-option>
            </el-select>
          </div>

          <div class="selector">
            <el-select v-model="selectedStatus"
                       placeholder="状态"
                       clearable
                       filterable
                       @change="handleSearch">
              <el-option v-for="status in statusList"
                         :key="status.value"
                         :label="status.value"
                         :value="status.value">
              </el-option>
            </el-select>
          </div>

          <div class="selector">
            <el-select v-model="selectedType"
                       placeholder="分类"
                       clearable
                       filterable
                       @change="handleSearch">
              <el-option v-for="type in typeList"
                         :key="type.value"
                         :label="type.value"
                         :value="type.value">
              </el-option>
            </el-select>
          </div>

        </div>

        <div class="search-bar top-right">
          <el-input v-model="search"
                    class="search-input"
                    placeholder="请输入档案名称或者ID"
                    prefix-icon="el-icon-search"
                    clearable
                    @clear="handleSearch"
                    @keyup.enter.native="handleSearch">
                    <el-button slot="append" @click="handleSearch"
                       type="primary">搜索</el-button>
          </el-input>
          <div class="button-box">
            <el-button @click="resetSearchForm" plain>重置</el-button>
            <el-button style="margin-left:40px" icon="el-icon-sort" type="text" @click="sort()">{{ascend?'倒序':'正序'}}</el-button>
          </div>
        </div>
      </div>
      <!-- 主体内容 -->
      <div class="ym-panel-radius clearfix" v-loading="loading">
        <div class="ym-head">
          <div class="flex-left">全部{{pageData.total}}藏品 已加载{{data.length}}藏品</div>
          <!-- 创建档案 -->
          <div class="flex-right">
            <el-button type="primary" @click="toDetails()">创建档案</el-button>
          </div>
        </div>
        <card-item :data="data" @toDetails="toDetails"></card-item>
        <no-record v-if="!data || data.length===0">暂无档案记录</no-record>
        <YmPagination :pageData="pageData" v-if="showPagination" :page-size="pageData.size" @handlePageChange="handlePageChange" @handleSizeChange="handleSizeChange"></YmPagination>
      </div>
  </div>
</template>

<script>

import YmPagination from '@/components/TableList/YmPagination'
import CardItem from './component/cardItem.vue'
import store from '@/store'
import debounce from '@/utils/debounce.js'

export default {
  components: {
    YmPagination,
    CardItem
  },
  data() {
    return {
      loading: false,
      search: '',
      selectedCenter: '',
      selectedOrganization: '',
      selectedType: '',
      selectedStatus: '',
      ascend: false,
      centerList: [],
      organizationList: [],
      typeList: [
        { value: '陶瓷' },
        { value: '玉器玉雕' },
        { value: '古币纸钱' },
        { value: '铜器铜雕' },
        { value: '中国书画' },
        { value: '古典家具' },
        { value: '金银珠宝' },
        { value: '雕品工艺' }
      ],
      statusList: [
        { value: '建档中' },
        // { value: '待打码' },
        // { value: '打码完成' },
        { value: '待审核' },
        { value: '审核不通过' },
        { value: '待指派' },
        { value: '待初鉴' },
        { value: '已初鉴' },
        { value: '存疑' },
        { value: '艺术品' },
        { value: '终鉴中' },
        { value: '终鉴完成' }
      ],
      data: [],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, sizes, jumper, slot',
        currentPage: 1 // 当前页
      },
      listName: 'record-list',
      useListInfo: false, // 是否使用Vuex中的列表页码和搜索信息
      showPagination: false
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.path.includes('/record/details')) {
      next(vm => { vm.useListInfo = true })
    }
    next()
  },
  created() {
    this.getList = debounce(this.getList.bind(this), 200)
  },
  mounted() {
    this.getDepartmentListByType('分中心', this.centerList)
    this.getAllDepartmentList('鉴定机构', this.organizationList)
    if (this.useListInfo) {
      this.initListInfo(this.listName)
    }
    this.loading = true
    this.getList()
  },
  computed: {
  },
  methods: {
    sort() {
      this.ascend = !this.ascend
      this.setListInfo()
      this.loading = true
      this.getList()
    },
    toDetails(items) {
      this.setListInfo()
      if (items) {
        this.$router.push({
          path: '/record/details',
          query: { archiveId: items.archiveId }
        })
      } else {
        this.$router.push({ path: '/record/details' })
      }
    },
    // 重置表单
    resetSearchForm() {
      this.search = ''
      this.selectedCenter = ''
      this.selectedOrganization = ''
      this.selectedType = ''
      this.selectedStatus = ''
      this.ascend = false
      this.handleSearch()
    },
    // 搜索栏搜索
    handleSearch() {
      this.$set(this.pageData, 'currentPage', 1)
      this.setListInfo()
      this.loading = true
      this.getList()
    },
    // 分页跳转
    handlePageChange(page) {
      this.$set(this.pageData, 'currentPage', page)
      this.setListInfo()
      this.loading = true
      this.getList()
    },
    // 每页大小跳转
    handleSizeChange(size) {
      this.$set(this.pageData, 'size', size)
      this.setListInfo()
      this.loading = true
      this.getList()
    },
    // 获取当前用户下拉筛选项
    async getDepartmentListByType(deptType, list) {
      try {
        const { data } = await this.$api.record.getDepartmentListByType({ deptType: deptType })
        if (data.code === 0) {
          if (deptType === '分中心') {
            this.centerList = data.data
          } else if (deptType === '鉴定机构') {
            this.organizationList = data.data
          }
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    // 获取所有下拉选项
    async getAllDepartmentList(deptType, list) {
      try {
        const { data } = await this.$api.department.getAllDepartmentList({ deptType: deptType })
        if (data.code === 0) {
          if (deptType === '分中心') {
            this.centerList = data.data
          } else if (deptType === '鉴定机构') {
            this.organizationList = data.data
          }
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    // 获取藏品列表
    async getList() {
      try {
        const { data } = await this.$api.record.getList({
          page: this.pageData.currentPage,
          size: this.pageData.size,
          status: this.selectedStatus,
          category: this.selectedType,
          nameOrId: this.search.trim(),
          subCenter: this.selectedCenter,
          institution: this.selectedOrganization,
          ascend: this.ascend
        })
        if (data.code === 0) {
          this.data = data.data.records
          this.data.forEach(item => {
            if (item.frontThumbnail) {
              item.mainPicture = item.frontThumbnail
            }
          })
          this.pageData.total = data.data.total
          this.loading = false
          this.$refs.recordList.parentElement.scrollTop = 0
          this.showPagination = true
        } else {
          this.loading = false
          this.showPagination = true
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        this.loading = false
        this.showPagination = true
        throw new Error(err)
      }
    },
    // 若 Vuex 中保存有当前列表的页码及搜索信息，则获取所有信息并赋值
    initListInfo(listName) {
      const listInfo = store.getters.listInfo
      const index = store.getters.listInfo.findIndex(item => item.listName === listName)
      if (index !== -1) {
        const list = listInfo[index]
        this.$set(this.pageData, 'currentPage', list.pageData.currentPage)
        this.$set(this.pageData, 'size', list.pageData.size)
        this.search = list.search.search
        this.selectedStatus = list.search.selectedStatus
        this.selectedType = list.search.selectedType
        this.selectedCenter = list.search.selectedCenter
        this.selectedOrganization = list.search.selectedOrganization
        this.ascend = list.search.ascend
      }
    },
    // 将当前页面信息保存至Vuex中
    setListInfo() {
      const listInfo = {
        listName: this.listName,
        pageData: this.pageData,
        search: {
          search: this.search,
          selectedStatus: this.selectedStatus,
          selectedType: this.selectedType,
          selectedCenter: this.selectedCenter,
          selectedOrganization: this.selectedOrganization,
          ascend: this.ascend
        }
      }
      this.$store.dispatch('setListInfo', listInfo)
    }
  }
}
</script>

<style lang="scss" scoped>
$img-width: 290px;
.record-list {
  .block {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 20px 24px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .top-left {
      display: flex;
      margin-right: 8px;
      .selector + .selector {
        margin-left: 8px;
      }
    }
  }
  .button-box{
    margin-left: 10px;
    min-width: 160px;
  }
  .search-bar {
    display: flex;
    ::v-deep .search-input {
      width: 350px;
    }
  }
  ::v-deep .el-input-group__append{
    background-color: $--color-primary;
    color: #fff;
  }
  .ym-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .flex-left {
      align-self: flex-end;
    }
  }
}
</style>
