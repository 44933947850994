<template>
  <div class="ym-panel-radius clearfix">
    <div class="stage">
      <div v-loading="loading" class="stage-iframe">
        <iframe :src="url" scrolling="auto"></iframe>
        <!-- <div class="stage-iframe-tip">
          <p>扫描预览</p>
          <p>
            <el-image class="qr-image" :src="FileUrl + qrCode"></el-image>
          </p>
        </div> -->
      </div>
      <div class="stage-container">
        <h3>链接分享：</h3>
        <p>
          <el-input v-model="url" type="text" placeholder="加载中..." readonly>
            <el-button v-if="!loading" slot="append" type="primary" @click="copyPassword($event, url)">复制链接</el-button>
          </el-input>
        </p>
        <h3>扫码分享：</h3>
        <p>
          <el-image class="qr-image" v-loading="loading" :src="FileUrl + qrCode"></el-image>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import clipboard from '@/utils/clipboard.js'
export default {
  data () {
    return {
      loading: false,
      qrCode: '',
      secret: '',
      url: ''
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  created () {
    this.loading = true
    this.$api.cloudShow.h5Secret().then(res => {
      this.qrCode = res.data.data.qrCode
      this.secret = res.data.data.secret
      this.url = res.data.data.url
      this.loading = false
    })
  },
  methods: {
    copyPassword(event, text) {
      return clipboard(event, text, () => {
        this.$message.success('复制成功')
      }, function() {
        this.$message.error('复制失败')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ym-panel-radius {
  display: flex;
  align-items: center;
  justify-content: center;
}
.stage {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  max-width: 1200px;
  margin: auto;
  .stage-iframe {
    position: relative;
    display: block;
    width: 360px;
    height: 640px;
    border: 1px solid #DCDEE0;
    border-radius: 6px;
    margin-right: 40px;
    overflow: hidden;
    @media (max-width: 768px) {
      display: none;
    }
    iframe {
      display: block;
      width: 100%;
      height: 100%;
      border: none;
    }
    .stage-iframe-tip {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      transition: opacity 0.3s;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: #fff;
      font-size: 16px;
      p {
        margin-bottom: 10px;
      }
      .qr-image {
        max-width: 200px;
      }
    }
    &:hover {
      .stage-iframe-tip {
        opacity: 1;
      }
    }
  }
  .stage-container {
    flex: 1;
    h3 {
      margin: 40px 0 20px;
    }
    p {
      margin: 12px 0;
    }
    .qr-image {
      display: block;
      width: 200px;
      height: 200px;
      border: solid 1px #DCDEE0;
      box-sizing: border-box;
    }
  }
}
</style>
