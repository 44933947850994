<template>
  <div class="details-value">
    <div class="ym-panel-radius">
      <div class="title">
        <span>确值结果</span>
      </div>
      <template v-for="dept in dataInfo.identifyValues">
        <div class="dept" :key="dept.deptId">
          <div class="dept-header">{{dept.deptName}}</div>
          <div class="dept-result">
            <div class="block-left">
              <el-row class="row-item">
                <el-col>
                  <div class="label">预估价值：</div>
                  <div class="content">
                    <template v-if="!dept.file">
                      <span class="tips-warning">(待提交)</span>
                      <el-button type="primary" size="small" @click="goSubmit('value')" v-if="hasPermission || (userInfo && (userInfo.deptId === dept.deptId))">去提交</el-button>
                    </template>
                    <template v-else>
                      <span class="price">{{price[dept.deptId]}}</span>
                    </template>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div class="block-right">
              <div class="operator">
                <el-button class="btn" :disabled="!dept.file" @click="viewReport(dept.deptId)">确值报告</el-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    submitted: {
      type: Boolean,
      default: false
    },
    dataInfo: {
      type: Object,
      default: () => {}
    },
    userInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      price: {}
    }
  },
  watch: {
    'dataInfo.identifyValues': {
      handler() {
        if (this.dataInfo.identifyValues.length > 0) {
          this.dataInfo.identifyValues.forEach(dept => {
            if (dept.file) {
              if ((dept.lowestPrice || Number(dept.lowestPrice) === 0) && (dept.highestPrice || Number(dept.highestPrice) === 0)) {
                // 存在最大和最小估值
                this.$set(this.price, dept.deptId, `￥${this.numberFormat(dept.lowestPrice)} ~ ￥${this.numberFormat(dept.highestPrice)}`)
              } else {
                // 只存在一种估值
                if (dept.lowestPrice || Number(dept.lowestPrice) === 0) {
                  this.$set(this.price, dept.deptId, `￥${this.numberFormat(dept.lowestPrice)}`)
                } else if (dept.highestPrice || Number(dept.highestPrice) === 0) {
                  this.$set(this.price, dept.deptId, `￥${this.numberFormat(dept.highestPrice)}`)
                }
              }
            } else {
              this.$set(this.price, dept.deptId, '')
            }
          })
          console.log(this.price)
        }
      },
      deep: true
    }
  },
  computed: {
    hasPermission() {
      return this.userInfo.permissionsTags.includes('admin')
    }
  },
  methods: {
    viewReport(deptId) {
      const deptInfo = this.dataInfo.identifyValues.find(dept => dept.deptId === deptId)
      if (deptInfo && deptInfo.file) {
        const url = this.FileUrl + deptInfo.file
        window.open(url, 'blank')
      }
    },
    goSubmit(flag) {
      this.$emit('goSubmit', flag)
    },
    numberFormat(str) {
      return Number(str).toLocaleString('zh', { minimumFractionDigits: 2 })
    }
  }
}
</script>

<style lang="scss" scoped>
.details-value {
  color: $--color-text-primary;
  .title {
    display: flex;
    align-items: center;
    font-size: 20px;
    margin: 2px 0 24px;
    span {
      margin-right: 8px;
      font-weight: 700;
    }
  }
  .dept {
    border: 1px solid #EBEDF0;
    & + .dept {
      margin-top: 24px;
    }
    .dept-header {
      height: 56px;
      line-height: 56px;
      padding: 0 25px;
      font-size: 16px;
      font-weight: 600;
      background-color: #F7F8FA;
    }
    .dept-result {
      display: flex;
      padding: 20px 0px;
      .block-left {
        width: 81.5%;
        padding: 0 25px;
        .row-item {
          font-size: 14px;
          margin-bottom: 12px;
          .el-col {
            display: flex;
            align-items: center;
          }
          .label {
            width: 70px;
            min-width: 70px;
            color: $--color-text-secondary;
            &.owner {
              min-width: 90px;
            }
          }
          .content {
            .el-button {
              width: 60px;
              height: 24px;
              padding: 0;
              margin-left: 12px;
              text-align: center;
              line-height: 24px;
            }
            .tips-warning {
              color: $--color-warning;
            }
          }
        }
      }
      .block-right {
        display: flex;
        width: calc(100% - 81.5%);
        justify-content: center;
        align-items: center;
        border-left: 1px solid $--border-color-base;
        min-width: 280px;
        .operator {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .btn {
            margin: 0 auto;
            width: 130px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            padding: 0 6px;
            font-size: 14px;
            span {
              display: inline-block;
              width: 100%;
            }
            & + .btn {
              margin-top: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
