<template>
  <div class="tableList">

    <!-- 顶部搜索栏 -->
    <div class="tableList-form">
      <ymForm ref="tableForm" refCode="tableForm" :inline="true"
        :formValue="formValue"
        :formData="formData"
        @onSubmit="onSubmit">
      </ymForm>
      <div class="tableList-form-buttonBox">
        <el-button size="medium" @click="submitForm('tableForm')" type="primary">查询</el-button>
        <el-button size="medium" @click="resetForm('tableForm')" plain>重置</el-button>
      </div>
    </div>

    <!-- 列表组件 -->
    <ym-table-list
      stripe
      :headData="headData"
      :loading="loading"
      :data="tableData"
      :columns="columns"
      :pageData="pageData"
      row-key="id"
      @handlePageChange="handlePageChange">
      <!-- 使用列表插槽 -->
    </ym-table-list>

    <el-dialog :visible.sync="dialogVisible" :title="usertableTitle" width="600px">
      <div style="margin: -10px 10px 0" >
       <el-table :data="usertableData" border style="width: 100%" max-height="280">
        <el-table-column prop="loginAccount" label="账号"></el-table-column>
        <el-table-column prop="userName" label="昵称"></el-table-column>
      </el-table>
      </div>
<!--       <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span> -->
    </el-dialog>

  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'
import ymForm from '@/components/YmForm'
import tableObj from './data.js'
import { getUserInfo } from '@/utils/localStorage.js'

export default {
  components: {
    YmTableList,
    ymForm
  },
  data() {
    return {
      loading: false,
      formValue: {}, // 用以显示的form表单
      searchForm: {}, // 用以搜索的obj
      tableData: [],
      dialogVisible: false, // 显示用户
      tableObj: tableObj,
      usertableData: [],
      usertableTitle: '用户列表',
      columns: tableObj.columnsData,
      pageData: tableObj.pageData,
      formData: tableObj.formData,
      headData: tableObj.headData
    }
  },
  mounted() {
    this.showAction()
    this.pageData.page = 1
    this.getData()
  },
  methods: {

    // 点击提交按钮,触发提交事件
    submitForm(id) {
      this.$refs[id].onSubmit()
    },
    // 提交事件
    onSubmit(form, ref) {
      this.searchForm = JSON.parse(JSON.stringify(this.formValue))
      this.formValue = {}
      let groupName = this.searchForm.groupName
      this.headData.title = '搜索 “' + (groupName || '') + '” 的结果'
      this.getData()
    },

    // 重置表单
    resetForm(id) {
      this.formValue = {}
      this.searchForm = {}
      this.headData.title = '用户组列表'
      this.getData()
    },

    // 判断当前页是否是从其他动作跳转而来
    showAction() {
      let action = this.$route.params.action
      if (action && action === 'add-success') {
        this.$message({ message: '新增用户组成功', type: 'success' })
      }
      if (action && action === 'edit-success') {
        this.$message({ message: '编辑用户组成功', type: 'success' })
      }
    },

    // 查看用户组下全部用户
    async showUser(row) {
      const { data } = await this.$api.userGroup.getUserByGroup(row.groupId)
      if (!data || !data.data) {
        this.message.error('接口请求失败')
        return
      }
      this.usertableData = data.data
      this.dialogVisible = true
      this.usertableTitle = '显示 ' + row.groupName + ' 组中包含全部用户'
    },

    // 删除列表某一条用户组
    async deleteRow(row) {
      const { data } = await this.$api.userGroup.deleteUserGroup(row.groupId)
      if (data.code === 0) {
        this.$message({ message: '删除用户组成功', type: 'success' })
        this.getData()
      } else {
        this.$message.error('删除用户组失败: ' + data.message)
        this.getData()
      }
    },

    // 获取表格数据
    async getData() {
      this.loading = true
      Object.assign(this.searchForm, this.pageData)
      try {
        const { data } = await this.$api.userGroup.getUserGroupByPage(this.searchForm)
        this.loading = false
        if (!(data && data.data && data.data.records)) {
          this.$message.error('查询用户组失败: ' + data.data.message)
          return
        }
        if (data.data.records.length === 0 && data.data.total !== 0 && this.pageData.page > 1) {
          this.$set(this.pageData, 'page', this.pageData.page - 1)
          this.getData()
          return
        }
        this.tableData = data.data.records
        this.$set(this.pageData, 'total', Number.parseInt(data.data.total))
      } catch (e) {
        this.loading = false
        console.log(e)
        this.$message.error('获取用户组列表失败')
      }
    },
    handlePageChange(goPage) {
      this.$set(this.pageData, 'page', goPage)
      this.getData()
    },
    isSuperAdmin() {
      return JSON.parse(getUserInfo()).userId === '1'
    }
  }
}
</script>

<style lang="scss" scoped>
.tableList-form{
  background: #fff;
  border-radius: $--border-radius-base;
  padding: 28px 24px 0;
  border: $--border-width-base $--border-style-base $--border-color-light;
  margin-bottom: 24px;
  width: 100%;
  overflow: hidden;

  /deep/.ym-form{
    float: left;
    width: calc(100% - 230px);
    max-width: 300px;
  }

}

/deep/.el-table{
  .el-table__row{
    .el-button--text{
      margin-right: 12px;
    }
  }
}
</style>
