<template>
<div class="final-certificate-template" ref="test">
  <div class="final-certificate" v-if="loadFinished" ref="finalCertificate">
    <div class="inner-border">
      <div class="border-top">
        <img src="./img/border-top.png" alt="">
        <img class="logo" src="@/assets/img/logo-header.png" alt="">
      </div>
      <div class="content">
        <div class="idcard">全球唯一身份证：{{dataInfo.idCard}}</div>
        <div class="certificate-id">
          <span class="label">证书编号：</span>
          <span>{{finalSummaryInfo.certificateNumber}}</span>
        </div>
        <div class="name-block">
          <span class="label">藏品名称：</span>
          <span class="name">{{dataInfo.name}}</span>
        </div>
        <div class="qr-block">
          <div class="qr">
            <img :src="FileUrl + dataInfo.plainCode + '?timestamp=' + new Date().getTime()" crossorigin="anonymous" alt="">
          </div>
          <div class="qr-content">
            <span class="hash-label">区块链HashCode</span>
            <span class="hash">{{dataInfo.nftHash}}</span>
          </div>
        </div>
        <div class="pic-block">
          <div class="main-pic">
            <div class="title">
              <span>藏品照片</span>
            </div>
            <div class="pics">
              <div class="pic-bg">
                <img :src="FileUrl + dataInfo.front + '?timestamp=' + new Date().getTime()" crossorigin="anonymous" alt="">
              </div>
              <template v-if="dataInfo.up">
                <div class="pic-bg">
                  <img :src="FileUrl + dataInfo.up + '?timestamp=' + new Date().getTime()" crossorigin="anonymous" alt="">
                </div>
              </template>
            </div>
          </div>
          <div class="cipher">
            <div class="title">
              <span>暗码位置截图</span>
            </div>
            <div class="pics">
              <template v-for="pic in dataInfo.ciphers">
                <div class="pic-bg" :key="pic.cipher">
                  <img :src="pic.picture + '?timestamp=' + new Date().getTime()" crossorigin="anonymous" alt="">
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="divider">
          <img src="./img/divider.png" alt="">
        </div>
        <div class="final-identify-block">
          <div class="authenticity">
            <div class="title">
              <span>确真综合鉴定：</span>
              <template v-if="handleFinalResult === '真品'">
                <span class="real">真品</span>
              </template>
              <template v-else>
                <span class="fake">仿品</span>
              </template>
            </div>
            <template v-for="res in finalSummaryInfo.identifyRealVos">
              <div class="identify-result result-bgc" :key="res.identifyRealId">
                <img class="result-img" src="./img/real.png" alt="" v-if="res.identifyResult === '真品'">
                <img class="result-img" src="./img/fake.png" alt="" v-else>
                <div class="result-content">
                  <span class="label">鉴定机构：</span>
                  <div class="detail">
                    <div>{{res.deptName}}</div>
                    <div class="expert-list">
                      <span v-for="option in res.collectionDetectVos" :key="option.detectId" class="detail-item">{{option.expert}}</span>
                    </div>
                  </div>
                </div>
                <div class="result-content">
                  <span class="label">鉴定项目：</span>
                  <div class="detail">
                    <span v-for="option in res.collectionDetectVos" :key="option.detectId" class="detail-item__identify">{{option.detectItemName}}</span>
                  </div>
                </div>
                <div class="result-content">
                  <span class="label">确真结果：</span>
                  <span class="detail real" v-if="res.identifyResult === '真品'">真品</span>
                  <span class="detail fake" v-else>仿品</span>
                </div>
                <div class="result-content">
                  <span class="label">鉴定结论：</span>
                  <span class="detail conclusion" v-html="showParagraph(res.conclusion)"></span>
                </div>
              </div>
            </template>
          </div>
          <div class="notarization">
            <div class="title">
              <span>确权</span>
            </div>
            <template v-for="res in finalSummaryInfo.identifyAuthorities">
              <div class="identify-result result-bgc half-block-content" :key="res.deptId">
                <div class="result-content">
                  <span class="label">鉴定机构：</span>
                  <span class="detail">{{res.deptName}}</span>
                </div>
                <div class="result-content">
                  <span class="label">所属人/机构：</span>
                  <span class="detail">{{res.owner}}</span>
                </div>
                <div class="result-content">
                  <span class="label">证件信息：</span>
                  <span class="detail">{{res.certificateInfo}}</span>
                </div>
              </div>
            </template>
          </div>
          <div class="value">
            <div class="title">
              <span>确值</span>
            </div>
            <template v-for="res in finalSummaryInfo.identifyValues">
              <div class="identify-result result-bgc half-block-content" :key="res.deptId">
                <div class="result-content">
                  <span class="label">鉴定机构：</span>
                  <span class="detail">{{res.deptName}}</span>
                </div>
                <div class="result-content">
                  <span class="label price-label">预估价值：</span>
                  <span class="detail price-detail" v-if="price[res.deptId].min && price[res.deptId].max">
                    <span class="unit">￥</span>
                    <span>{{price[res.deptId].min}}</span>
                    <span> ~ </span>
                    <span class="unit">￥</span>
                    <span>{{price[res.deptId].max}}</span>
                  </span>
                  <span v-else-if="price[res.deptId].min" class="detail price-detail">
                    <span class="unit">￥</span>
                    <span>{{price[res.deptId].min}}</span>
                  </span>
                  <span v-else class="detail price-detail">
                    <span class="unit">￥</span>
                    <span>{{price[res.deptId].max}}</span>
                  </span>
                </div>
              </div>
            </template>
          </div>
          <div class="law">
            <div class="title">
              <span>法律</span>
            </div>
            <template v-for="res in finalSummaryInfo.identifyLegals">
              <div class="identify-result result-bgc half-block-content" :key="res.deptId">
                <div class="result-content">
                  <span class="label">法律机构：</span>
                  <span class="detail">{{res.deptName}}</span>
                </div>
                <div class="result-content">
                  <span class="label">法律意见：</span>
                  <span class="detail" v-html="showParagraph(res.opinion)"></span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="border-bottom">
        <img src="./img/border-bottom.png" alt="">
        <div class="stamp">
          <span>三博会盖章栏</span>
          <img src="./img/stamp.png" alt="" class="stamp-img">
        </div>
        <div class="date">日期：{{currentDate}}</div>
      </div>
    </div>
  </div>
  <!-- <img :src="dataUrl" alt="" v-if="finishedFlag && !uploadFail"> -->
</div>
</template>

<script>
import timeFormat from '@/utils/timeFormat.js'
import axios from '@/plugins/axios'
import html2canvas from 'html2canvas'
import { showParagraph } from '@/utils/handleParagraph.js'

export default {
  props: {
    dataInfo: {
      type: Object,
      default: () => {}
    },
    finalSummaryInfo: {
      type: Object,
      default: () => {}
    },
    loadFinished: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      price: {},
      currentDate: '',
      dataUrl: '',
      finishedFlag: false, // 是否已经生成好canvas画布
      uploading: false, // 上传标识
      url: {
        certificateUrl: '',
        certificateUrlThumbnail: ''
      },
      loading: ''
    }
  },
  watch: {
    'finalSummaryInfo': {
      handler() {
        if (this.finalSummaryInfo.identifyValues.length > 0) {
          this.finalSummaryInfo.identifyValues.forEach(dept => {
            if (dept.file) {
              this.$set(this.price, dept.deptId, {})
              if ((dept.lowestPrice || Number(dept.lowestPrice) === 0) && (dept.highestPrice || Number(dept.highestPrice) === 0)) {
                // 存在最大和最小估值
                this.$set(this.price[dept.deptId], 'min', `${this.numberFormat(dept.lowestPrice)}`)
                this.$set(this.price[dept.deptId], 'max', `${this.numberFormat(dept.highestPrice)}`)
              } else {
                // 只存在一种估值
                if (dept.lowestPrice || Number(dept.lowestPrice) === 0) {
                  this.$set(this.price[dept.deptId], 'min', `${this.numberFormat(dept.lowestPrice)}`)
                  this.$set(this.price[dept.deptId], 'max', '')
                } else if (dept.highestPrice || Number(dept.highestPrice) === 0) {
                  this.$set(this.price[dept.deptId], 'min', '')
                  this.$set(this.price[dept.deptId], 'max', `${this.numberFormat(dept.highestPrice)}`)
                }
              }
            } else {
              this.$set(this.price, dept.deptId, '')
            }
          })
        }
      },
      deep: true,
      immediate: true
    },
    loadFinished: {
      handler() {
        if (this.loadFinished) {
          this.$nextTick(() => {
            this.generateCanvas()
          })
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.currentDate = timeFormat(this.finalSummaryInfo.identifyTime, 'YYYY/MM/DD')
    // this.generateCanvas()
  },
  computed: {
    // 统计所有机构的检测结果
    handleFinalResult() {
      console.log(this.finalSummaryInfo.identifyRealVos)
      if (this.finalSummaryInfo.identifyRealVos) {
        let count = 0
        let fakeMark = false
        for (let i = 0; i < this.finalSummaryInfo.identifyRealVos.length; i++) {
          if (this.finalSummaryInfo.identifyRealVos[i].identifyResult === '仿品') {
            fakeMark = true
            break
          } else if (this.finalSummaryInfo.identifyRealVos[i].identifyResult === '真品') {
            count++
          }
        }
        if (fakeMark) {
          return '仿品'
        } else if (count === this.finalSummaryInfo.identifyRealVos.length) {
          return '真品'
        } else {
          return ''
        }
      }
      return ''
    }
  },
  methods: {
    showParagraph,
    numberFormat(str) {
      return Number(str).toLocaleString('zh', { minimumFractionDigits: 2 })
    },
    generateCanvas() {
      this.$nextTick(() => {
        html2canvas(this.$refs.finalCertificate, {
          useCORS: true
        }).then(canvas => {
          let imgUrl = canvas.toDataURL('image/png')
          console.log(imgUrl)

          const img = new Image()
          img.style.width = '2894px'
          img.crossOrigin = 'Anonymous'
          img.src = imgUrl
          this.imgNodeCache = img
          this.$refs.test.appendChild(img)

          this.dataURL = imgUrl
          this.finishedFlag = true
          this.uploadCertificate(imgUrl)
        }).catch(error => {
          console.log(error)
          this.$emit('sumitFail')
          this.$loading().close()
          throw new Error(error)
        })
      })
    },
    // 上传证书图片，获取原图及压缩图片存储地址
    uploadCertificate(dataUrl) {
      this.uploading = true
      const file = this.convertBase64UrlToBlob(dataUrl)
      const formData = new FormData()
      // formData.append('enctype', 'multipart/form-data')
      formData.append('multipartFile', file)
      axios.post('/v1/upload', formData, {
        baseURL: this.FileUrl,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        if (res.data.code === 0) {
          this.$set(this.url, 'certificateUrl', res.data.data.originalPath)
          this.$set(this.url, 'certificateUrlThumbnail', res.data.data.compressPath)
          this.uploading = false
          this.submitFinalInfo()
        } else {
          this.$loading().close()
          this.$message({
            type: 'error',
            message: res.data.message
          })
          this.$emit('sumitFail')
          this.uploading = false
        }
      }).catch(error => {
        console.log(error)
        this.$loading().close()
        this.$emit('sumitFail')
        this.uploading = false
        throw new Error(error)
      })
    },
    // 将base64的图片转换为file文件
    convertBase64UrlToBlob(dataurl) {
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let suffix = mime.split('/')[1]
      let bstr = atob(arr[1])
      let len = bstr.length
      let u8arr = new Uint8Array(len)
      while (len--) {
        u8arr[len] = bstr.charCodeAt(len)
      }
      return new File([u8arr], `.${suffix}`, { type: mime })
    },
    // 终鉴提交完成
    async submitFinalInfo() {
      try {
        const { data } = await this.$api.finalIdentify.submitFinalInfo({
          archiveId: this.finalSummaryInfo.archiveId,
          certificateNumber: this.finalSummaryInfo.certificateNumber,
          certificateUrl: this.url.certificateUrl,
          certificateUrlThumbnail: this.url.certificateUrlThumbnail
        })
        if (data.code === 0) {
          // views/record/finalIdentifyDetails/index.vue 中提交终鉴时开启了loading
          this.$loading().close()
          this.$message({
            type: 'success',
            message: '提交成功'
          })
          // 删除生成证书的html
          setTimeout(() => {
            this.$emit('finalSubmitFinished')
            this.$router.go(0)
          }, 1000)
        } else {
          this.$loading().close()
          this.$message({
            type: 'error',
            message: data.message
          })
          this.$emit('sumitFail')
          // 删除生成证书的html
          this.$emit('finalSubmitFinished')
        }
      } catch (err) {
        this.$loading().close()
        // 删除生成证书的html
        this.$emit('sumitFail')
        this.$emit('finalSubmitFinished')
        throw new Error(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$inner-border-width: 10px;
.final-certificate-template {
  .final-certificate {
    width: 2894px;
    padding: 50px;
    background-color: #fff;
    color: $--color-text-primary;
    .inner-border {
      border: $inner-border-width solid #d39a62;
      text-align: center;
      padding-top: calc(50px - #{$inner-border-width});
      padding-bottom: calc(50px - #{$inner-border-width});
      .border-top {
        position: relative;
        margin-bottom: 34px;
        .logo {
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translateX(-50%);
          width: 313px;
          height: 140px;
        }
      }
      .content {
        position: relative;
        border-left: 5px dashed #d39a62;
        border-right: 5px dashed #d39a62;
        margin: 0 40px 34px 39px;
        padding: 136px 124px 72px;
        .idcard {
          position: absolute;
          top: -12px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 66px;
          font-weight: 800;
          color: #A64900;
        }
        .certificate-id {
          font-size: 42px;
          margin-bottom: 108px;
          .label {
            color: #A64900;
          }
        }
        .name-block {
          display: flex;
          align-items: flex-end;
          margin-bottom: 80px;
          .label {
            flex-shrink: 0;
            font-size: 38px;
            font-weight: 500;
            letter-spacing: 4px;
          }
          .name {
            font-size: 66px;
            font-weight: 500;
            letter-spacing: 2px;
          }
        }
        .qr-block {
          display: flex;
          margin-bottom: 80px;
          .qr {
            width: 188px;
            height: 188px;
            border: 4px solid #F6ECE5;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .qr-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: #A64900;
            text-align: left;
            margin-left: 38px;
            .hash-label {
              margin-bottom: 25px;
              font-size: 42px;
              font-weight: 700;
              letter-spacing: 2px;
            }
            .hash {
              font-size: 32px;
            }
          }
        }
        .pic-block {
          display: flex;
          margin-bottom: 130px;
          .main-pic, .cipher {
            .title {
              font-size: 50px;
              margin-bottom: 50px;
              span {
                font-weight: 500;
                letter-spacing: 4px;
              }
            }
          }
          .main-pic {
            width: 642px;
          }
          .cipher {
            flex: 1;
            margin-left: 120px;
          }
          .pics {
            display: flex;
            justify-content: center;
            .pic-bg {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 300px;
              height: 200px;
              background: url("./img/image-background.png");
              background-size: 300px 200px;
              img {
                width: 90%;
                height: 90%;
              }
              &+.pic-bg {
                margin-left: 42px;
              }
            }
          }
        }
        .divider {
          text-align: center;
          margin-bottom: 156px;
        }
        .final-identify-block {
          text-align: left;
          .authenticity {
            margin-bottom: 112px;
            font-weight: 500;
            .title {
              text-align: center;
              font-size: 50px;
              letter-spacing: 4px;
              margin-bottom: 110px;
              .real {
                color: #4EB155;
                letter-spacing: 4px;
              }
              .fake {
                color: #f3515d;
                letter-spacing: 4px;
              }
            }
            .identify-result {
              position: relative;
              .result-img {
                position: absolute;
                top: 55px;
                right: 50px;
              }
            }
            .result-content {
              display: flex;
              font-size: 42px;
              &+ .result-content {
                margin-top: 60px;
              }
              .label {
                flex-shrink: 0;
                width: 230px;
                font-weight: 500;
                letter-spacing: 4px;
              }
              .detail {
                font-weight: 400;
                .detail-item__identify + .detail-item__identify {
                  margin-left: 50px;
                }
                .expert-list {
                  display: flex;
                  flex-wrap: wrap;
                }
                .detail-item {
                  margin-right: 50px;
                }
                &.real {
                  color: #4EB155;
                }
                &.fake {
                  color: #f3515d;
                }
                &.conclusion {
                  word-break: break-all;
                  text-align: justify;
                }
              }
            }
          }
          .notarization, .value, .law {
            margin-bottom: 112px;
            .title {
              text-align: center;
              font-size: 50px;
              letter-spacing: 4px;
              margin-bottom: 55px;
            }
            .result-content {
              display: flex;
              font-size: 42px;
              &+ .result-content {
                margin-top: 60px;
              }
              .label {
                flex-shrink: 0;
                font-weight: 500;
                letter-spacing: 2px;
                &.price-label {
                  padding-top: 11px;
                }
              }
              .detail {
                font-weight: 400;
                word-break: break-all;
                text-align: justify;
                .detail-item + .detail-item {
                  margin-left: 50px;
                }
                &.price-detail {
                  color: #A64900;
                  font-size: 64px;
                  .unit {
                    font-size: 46px;
                  }
                }
              }
            }
          }
          .law {
            margin-bottom: 72px;
          }
          .result-bgc {
            padding: 50px;
            background-color: #F9FAFB;
            &+.result-bgc {
              position: relative;
              border-top: 3px dashed $--border-color-light;
              &::after, &::before {
                content: '';
                position: absolute;
                display: block;
                width: 64px;
                height: 3px;
                background-color: #F9FAFB;
              }
              &::before {
                top: -3px;
                left: 0;
              }
              &::after {
                top: -3px;
                right: 0;
              }
            }
          }
        }
      }
      .border-bottom {
        position: relative;
        .stamp {
          position: absolute;
          top: 16px;
          left: calc(176px - #{$inner-border-width});
          width: 436px;
          height: 158px;
          line-height: 158px;
          border: 1px dashed rgba(#A64900, .5);
          color: rgba(#A64900, .5);
          text-align: center;
          font-size: 42px;
          font-weight: 500;
          &-img {
            position: absolute;
            width: 406px;
            height: 406px;
            top: -140px;
            left: 236px;
          }
        }
        .date {
          position: absolute;
          top: 130px;
          right: calc(176px - #{$inner-border-width});
          font-size: 42px;
        }
      }
    }
  }
}
</style>
