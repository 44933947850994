<template>
  <exception title="403">
   <el-button type="primary" @click="$router.back()">返回上一页</el-button>
  </exception>
</template>

<script>
import Exception from '@/components/Exception/index.vue'
export default {
  components: {
    'exception': Exception
  },
  methods: {
    goHome() {
      this.$router.push({ path: '/home' })
    }
  }
}
</script>
