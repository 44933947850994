<template>
  <div>
    <hr v-if="!groupData.firstTitle && groupData.showHr" class="group-hr">
    <!-- 标题 -->
    <h5 v-if="groupData.title" class="ym-form-title" :style="groupData.firstTitle?{'margin-top':'10px'}:{'margin-top':'40px'}"  >{{groupData.title}}</h5>

    <template v-for="(itemData,itemKey) in groupData.items">
        <!-- 表单项 -->
        <component v-model="form[itemKey]" :formData="formData" :groupData="groupData" :itemData="itemData" :itemKey="itemKey" :form="form" :is="itemData.type+'FormItem'" :key="itemKey">
          <slot :name="itemData.name"></slot>
        </component>
    </template>
  </div>
</template>

<script>

export default {
  props: {

    // 表单组配置项,用于配置渲染表单组
    groupData: {
      type: Object,
      default: () => {
        return {}
      }
    },

    // 表单配置项,此层不做操作,用于传输到下一层作为函数事件参数
    formData: Array,

    // 表单数据,此层不做操作,用于传输到下一层作为函数事件参数
    form: Object

  },
  components: {
    // 使用components的is属性进行动态加载
    textFormItem: () => import('./module/textFormItem'),
    inputSelectFormItem: () => import('./module/inputSelectFormItem'),
    textareaFormItem: () => import('./module/textFormItem'),
    passwordFormItem: () => import('./module/textFormItem'),
    numberFormItem: () => import('./module/numberFormItem'),
    radioFormItem: () => import('./module/radioFormItem'),
    checkboxFormItem: () => import('./module/checkboxFormItem'),
    selectFormItem: () => import('./module/selectFormItem'),
    selectMoreFormItem: () => import('./module/selectMoreFormItem'),
    timeFormItem: () => import('./module/timeFormItem'),
    dateFormItem: () => import('./module/dateFormItem'),
    datetimeFormItem: () => import('./module/dateFormItem'),
    sliderFormItem: () => import('./module/sliderFormItem'),
    switchFormItem: () => import('./module/switchFormItem'),
    rateFormItem: () => import('./module/rateFormItem'),
    slotFormItem: () => import('./module/slotFormItem')
  }
}
</script>

<style lang="scss">
.group-hr{
    border: none;
    border-bottom: 1px solid #eee;
}
</style>
