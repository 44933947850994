<template>
  <el-card class="center identify-opts-bottom-btns" shadow="never">
    <div class="btn-group">
      <el-button type="primary" @click="passCheck" :disabled="codeStatus === '待打码'">鉴定通过</el-button>
      <el-button plain @click="doubtCheck">存疑</el-button>
      <el-button type="danger" @click="artCheck" :disabled="codeStatus === '待打码'">鉴定不通过</el-button>
    </div>
    <div class="wait-coding" v-if="codeStatus === '待打码'">未完成暗码打码，暂不能提交初鉴结果</div>
  </el-card>
</template>

<script>

export default {
  name: 'identifyOpts',
  components: {
  },
  props: {
    archiveId: {
      type: String,
      default: ''
    },
    codeStatus: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    passConfirm(certificateUrl) {
      this.$confirm('请确认初鉴内容与上传证书一致，鉴定通过将会自动进入已初鉴状态，确定该档案鉴定通过吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
      //  审核通过操作
        this.eyeAudit('鉴定通过', certificateUrl)
      }).catch(() => {})
    },
    // 鉴定为存疑藏品
    doubtConfirm() {
      this.$confirm('确定鉴定为存疑藏品吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.eyeAudit('存疑')
      }).catch(() => {})
    },
    // 鉴定为现代品
    artConfirm(certificateUrl) {
      this.$confirm('请确认初鉴内容与上传证书一致，确定鉴定为艺术品吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.eyeAudit('艺术品', certificateUrl)
      }).catch(() => {})
    },
    // 提交初审
    async eyeAudit(status, certificateUrl) {
      let params
      if (status !== '存疑') {
        params = {
          archiveId: this.archiveId,
          status: status,
          certificateUrl: certificateUrl.originalPath,
          certificateUrlThumbnail: certificateUrl.compressPath
        }
      } else {
        params = {
          archiveId: this.archiveId,
          status: status
        }
      }
      try {
        this.$loading({
          lock: true,
          text: '正在提交',
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.7)'
        })
        const { data } = await this.$api.record.eyeAudit(params)
        if (data.code === 0) {
          this.$loading().close()
          this.$message.success('提交成功')
          this.$emit('update', this.archiveId)
          this.$router.back()
        } else {
          this.$loading().close()
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        this.$loading().close()
        throw new Error(err)
      }
    },
    // 点击“鉴定通过”按钮后，发起父组件初鉴内容表单校验
    passCheck() {
      this.validateForm('pass')
    },
    // 点击“艺术品”按钮后，发起父组件初鉴内容表单校验
    artCheck() {
      this.validateForm('art')
    },
    // 点击“存疑”按钮后，发起父组件自动保存初鉴内容
    doubtCheck() {
      this.$emit('handleDoubt')
    },
    validateForm(flag) {
      this.$emit('validateForm', flag)
    },
    // 父组件初鉴内容表单校验通过后，弹出二次确认
    validatePass(flag, certificateUrl) {
      if (flag === 'pass') {
        this.passConfirm(certificateUrl)
      } else if (flag === 'art') {
        this.artConfirm(certificateUrl)
      }
    }
  }
}
</script>

<style lang="scss" >
.identify-opts-bottom-btns{
  .btn-group {
    .el-button + .el-button {
      margin-left: 40px;
    }
    .el-button {
      width: 126px;
    }
  }
  .wait-coding {
    margin-top: 12px;
    margin-bottom: -6px;
    font-size: 12px;
    color: #D40000;
  }
}
</style>
