<template>
  <div class="add-user-dialog">
    <el-dialog :visible.sync="addUserDialogVisible"
               title="添加成员"
               top="100px"
               width="500px"
               :close-on-click-modal="false"
               :before-close="handleClose"
               destory-on-close>
      <el-form ref="userForm"
               :model="userForm"
               :rules="rules"
               label-width="100px">
        <el-row :gutter="16">
          <el-col :span="22">
            <el-form-item prop="loginAccount" label="登录账号">
              <el-input v-model="userForm.loginAccount" size="small" placeholder="请输入" maxlength="20"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="22">
            <el-form-item prop="loginPassword" label="登录密码">
              <el-input v-model="userForm.loginPassword" size="small" placeholder="请输入" type="password" @input="handleDoubleCheck" show-password></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="22">
            <el-form-item prop="confirmPassword" label="确认密码">
              <el-input v-model="userForm.confirmPassword" size="small" placeholder="请输入" type="password" show-password></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="22">
            <el-form-item prop="userName" label="用户姓名">
              <el-input v-model="userForm.userName" size="small" placeholder="请输入" maxlength="40"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="22">
            <el-form-item prop="deptId" label="所属机构">
              <el-select v-model="selectedDept.deptId" size="small" popper-class="department-list-popper" ref="deptListSelect">
                <el-option style="height: auto" :value="selectedDept.deptId" :label="selectedDept.deptName">
                  <el-tree ref="deptListTree"
                           node-key="deptId"
                           :data="deptList"
                           :props="defaultProps"
                           :expand-on-click-node="false"
                           accordion
                           highlight-current
                           @node-click="handleNodeClick">
                    <div class="dept-tree-node" slot-scope="{ node }">
                      <span class="dept-tree-node-label ellipsis" :title="node.label">{{ node.label }}</span>
                    </div>
                  </el-tree>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="22">
            <el-form-item prop="userEmail" label="邮箱">
              <el-input v-model="userForm.userEmail" size="small" placeholder="请输入" maxlength="100"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="22">
            <el-form-item prop="mobilePhone" label="联系电话">
              <el-input v-model="userForm.mobilePhone" size="small" placeholder="请输入" maxlength="20"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="16">
          <el-col :span="22">
            <el-form-item prop="userGroup" label="所属用户组">
              <el-select v-model="userForm.userGroup" size="small">
                <el-option v-for="group in groupList"
                           :key="group.groupId"
                           :label="group.groupDescription ? group.groupDescription : group.groupName"
                           :value="group.groupId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="checkUserForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const crypto = require('crypto')

export default {
  props: {
    addUserDialogVisible: {
      type: Boolean,
      default: false
    },
    deptList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    let doubleCheckPassword = (rule, value, callback) => {
      if (value !== this.userForm.loginPassword) {
        callback(new Error('两次输入密码不一致'))
      } else {
        callback()
      }
    }

    let checkEmail = (rule, value, callback) => {
      const regExp = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
      if (!value) {
        callback()
      } else if (!regExp.test(value)) {
        callback(new Error('邮箱格式错误'))
      } else {
        callback()
      }
    }
    return {
      groupList: [],
      defaultProps: {
        children: 'children',
        label: 'deptName'
      },
      userForm: {
        loginAccount: '',
        loginPassword: '',
        confirmPassword: '',
        userName: '',
        userEmail: '',
        mobilePhone: '',
        userGroup: ''
      },
      selectedDept: {
        deptId: '',
        deptName: ''
      }, // 选中的树形部门
      submitForm: {},
      rules: {
        loginAccount: [
          { required: true, message: '请输入登录账号', trigger: 'change' },
          { min: 4, max: 20, message: '输入 4-20 个字符', trigger: 'change' },
          { pattern: /^[a-zA-Z]\w*$/, message: '只能输入数字字母下划线,且只能以字母开头' }
        ],
        loginPassword: [
          { required: true, message: '请输入登录密码', trigger: 'change' },
          { min: 6, max: 16, message: '请输入 6 至 16 个字符', trigger: 'change' }
        ],
        confirmPassword: [
          { required: true, validator: doubleCheckPassword, trigger: 'change' }
        ],
        deptId: [
          { required: true, message: '请选择所属机构', trigger: 'change' }
        ],
        userEmail: [
          { validator: checkEmail, trigger: 'change' }
        ],
        // userGroup针对单选的校验，多选可能要修改
        userGroup: [
          { required: true, message: '请选择用户组', trigger: 'change' }
        ]
      }
    }
  },
  mounted() {
    this.getUserGroup()
  },
  methods: {
    // 获取全部用户组列表, 初始化用户组下拉框
    async getUserGroup() {
      try {
        const { data } = await this.$api.userGroup.getUserGroup()
        if (data.code === 0 && data.data) {
          console.log(data.data)
          this.groupList = data.data
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    handleNodeClick(dept) {
      this.$set(this.userForm, 'deptId', dept.deptId)
      this.selectedDept = dept
      this.$refs.deptListSelect.blur()
    },
    // 校验添加成员表格
    checkUserForm() {
      this.$refs.userForm.validate((valid) => {
        if (!valid) {
          return false
        } else {
          this.$set(this.submitForm, 'loginAccount', this.userForm.loginAccount)
          this.$set(this.submitForm, 'loginPassword', this.encrypt(this.userForm.loginPassword))
          this.$set(this.submitForm, 'userName', this.userForm.userName)
          this.$set(this.submitForm, 'userEmail', this.userForm.userEmail)
          this.$set(this.submitForm, 'mobilePhone', this.userForm.mobilePhone)
          this.$set(this.submitForm, 'groupIds', [this.userForm.userGroup])
          this.$set(this.submitForm, 'deptId', this.selectedDept.deptId)
          console.log('添加成员数据')
          console.log(this.submitForm)
          this.handleSubmit()
        }
      })
    },
    // 校验确认密码
    handleDoubleCheck() {
      if (this.userForm.confirmPassword) {
        this.$refs.userForm.validateField('confirmPassword')
      }
    },
    handleClose() {
      this.$emit('handleUserDialogClose')
    },
    async handleSubmit() {
      try {
        const { data } = await this.$api.user.addUser(this.submitForm)
        if (data.code === 0) {
          this.$message({
            type: 'success',
            message: '添加成功'
          })
          this.showAddedMemberDepartment()
          this.$emit('getUserList')
          this.handleClose()
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    showAddedMemberDepartment() {
      this.$emit('showAddedMemberDepartment', this.userForm.deptId)
    },
    // 哈希加密(SHA1)
    encrypt(password) {
      const hash = crypto.createHash('sha1')
      hash.update(password)
      return hash.digest('hex')
    }
  }
}
</script>

<style lang="scss">
.department-list-popper {
  .el-select-dropdown__item {
    padding: 0;
    margin: 0 8px;
    &.selected {
      font-weight: 400;
    }
    .el-tree {
      user-select: none;
      .el-tree-node.is-current  {
        & > .el-tree-node__content {
          color: #3370FF;
          background-color: #F0F4FF;
          .dept-tree-node {
            &-icon {
              color: #3370FF;
              &:hover {
                background-color: #E1EAFF;
              }
            }
          }
        }
      }
      .el-tree-node__content{
        height: 40px;
        font-size: 14px;
      }
    }
  }
}
</style>
