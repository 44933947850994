// 表单项配置
let formData = [
  {
    items: {
      groupName: {
        label: '组名',
        type: 'text',
        clearable: true,
        rules: [
          { max: 20, message: '最长输入 20 个字符', trigger: 'change' }
        ]
      }
    }
  }
]

// 表头图标配置
let headData = {
  title: '用户组列表',
  items: [
    /* 新增按钮 */
    {
      style: 'button',
      label: '新增',
      icon: 'ym-icon-tianjia',
      size: 'medium',
      type: 'primary',
      clickEvent: (thisVue) => {
        thisVue.$router.push({ name: 'userGroup-edit' })
      }
    }
  ]
}

// 列表columns配置
let columnsData = [
  {
    label: '用户组id',
    prop: 'groupId',
    show: false
  },
  {
    type: '占位栏',
    show: true,
    width: '22'
  },
  {
    label: '组名',
    prop: 'groupName',
    show: true,
    width: '200',
    minWidth: '80'
  },
  {
    label: '描述',
    prop: 'groupDescription',
    show: true,
    minWidth: '120'
  },
  {
    label: '操作',
    prop: 'operators',
    minWidth: '80',
    fixed: 'right',
    children: {
      edit: {
        label: '编辑',
        icon: 'ym-icon-bianji',
        clickEvent: (thisVue, row) => {
          thisVue.$router.push({ name: 'userGroup-edit', query: { groupId: row.groupId, groupName: row.groupName, groupDescription: row.groupDescription } })
        },
        showFilter: (item, row, thisVue) => {
          return row.groupId !== '1' || thisVue.$parent.isSuperAdmin()
        }
      },
      showUser: {
        label: '查看用户',
        icon: 'ym-icon-xingming',
        clickEvent: (thisVue, row) => {
          thisVue.showUser(row)
        }
      },
      delete: {
        label: '删除',
        icon: 'ym-icon-shanchu',
        color: '#FF8851',
        clickEvent: (thisVue, row) => {
          thisVue.$confirm('此操作将删除该用户组,是否确定?', '警告', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            thisVue.deleteRow(row)
          }).catch(() => {

          })
        },
        showFilter: (item, row, thisVue) => {
          return row.groupId !== '1' || thisVue.$parent.isSuperAdmin()
        }
      }
    }
  }
]

// 分页配置项
let pageData = {
  size: 10, // 每页的数据量
  total: 0, // 数据总量
  layout: 'prev, pager, next, total, jumper, slot',
  page: 1 // 当前页
}

let tableObj = {
  'formData': formData, // 顶部搜索表单配置
  'columnsData': columnsData, // 表格列/操作列配置
  'headData': headData, // 表头图标配置
  'pageData': pageData // 分页数据
}

export default tableObj
