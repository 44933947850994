<template>
  <exception title="404"
             subtitle="抱歉，您访问的页面不存在！">
             <span>无法访问本页，请输入跳转至其它页面访问</span>
             </exception>
</template>

<script>
import Exception from '@/components/Exception/index.vue'
export default {
  components: {
    'exception': Exception
  }
}
</script>
