<template>
  <div class="record-details-chain">
    <el-table :data="data">
      <el-table-column label="角色"
       prop="loginAccount"
       align="center"
       width="100"
       :filters="roleFilter"
       :filter-method="filterHandler"
       >
      </el-table-column>
      <el-table-column prop="createTime" label="时间" align="center" width="180">
        <template slot-scope="scope">{{scope.row.createTime | timeFilter}}</template>
      </el-table-column>
      <el-table-column prop="content" label="操作记录">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import timeFormat from '@/utils/timeFormat.js'

export default {
  name: 'chainTable',
  components: {
  },
  props: {
    archiveId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      data: []

    }
  },
  watch: {
    archiveId(val) {
      if (val) {
        this.getList()
      }
    }
  },
  mounted() {
    this.getList()
  },
  computed: {
    roleFilter() {
      let filterList = []
      this.data.forEach(item => {
        let repeat = filterList.filter(a => {
          return a.text === item.loginAccount
        })
        if (repeat.length === 0) {
          filterList.push({ text: item.loginAccount, value: item.loginAccount })
        }
      })
      return filterList
    }
  },
  methods: {
    filterHandler(value, row, column) {
      const property = column['property']
      return row[property] === value
    },
    // 获取藏品列表
    async getList() {
      try {
        const { data } = await this.$api.record.getRecordOpts({
          archiveId: this.archiveId
        })
        if (data.code === 0) {
          this.data = data.data.records
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        throw new Error(err)
      }
    }
  },
  filters: {
    timeFilter(val) {
      if (!val) {
        return ''
      }
      return timeFormat(val, 'YYYY-MM-DD HH:mm')
    }
  }
}
</script>

<style lang="scss" scoped>
.record-details-chain {

}
</style>
