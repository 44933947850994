<template>
  <div class="ym-result">
    <div class="ym-result-content">
      <div class="ym-result-image">
        <img :src="src"/>
      </div>
      <!-- <div class="ym-result-title">{{ title }}</div> -->
      <div class="ym-result-subtitle">{{ content }}</div>
      <div class="ym-result-extra">
        <slot>
          <el-button :type="buttonType" @click="goHome">{{ extra }}</el-button>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: '',
      src: require('../../components/Exception/img/' + this.title + '.png')
    }
  },
  props: {
    // 标题
    title: {
      type: String,
      default: '403'
    },
    // 异常页提示内容
    subtitle: {
      type: String,
      default: ''
    },
    // 按钮文本
    extra: {
      type: String,
      default: '返回首页'
    },
    // 跳转地址
    backUrl: {
      type: String,
      default: ''
    },
    // 按钮样式
    buttonType: {
      type: String,
      default: 'primary'
    }
  },
  created () {
    if (this.subtitle === '') {
      switch (this.title) {
        case '403': {
          this.content = '抱歉，您无权访问此页面！'
          break
        }
        case '404': {
          this.content = '抱歉，您访问的页面不存在！'
          break
        }
        case '500': {
          this.content = '抱歉，服务器报告了一个错误！'
          break
        }
      }
    } else {
      this.content = this.subtitle
    }
  },
  methods: {
    // 跳转页面
    goHome() {
      if (this.backUrl === '') {
        this.$router.push('/')
      } else {
        this.$router.push(this.backUrl)
      }
    }
  }
}
</script>

<style lang="scss">
.ym-result {
  width: 100%;
  height: 100%;
  background-color: $--color-white;
  border-radius: $--border-radius-base;
  padding: 48px 32px;
  &-content {
    position: relative;
    width: 100%;
    top: 40%;
    transform: translateY(-50%);
  }
  &-image {
    max-width: 325px;
    background-position: center center;
    background-size: cover;
    margin: auto;
    img {
      width: 100%;
    }
  }
  &-icon {
    margin-bottom: 24px;
    text-align: center;
  }
  &-title {
    color: rgba(0,0,0,.85);
    font-size: 24px;
    line-height: 3;
    font-weight:900;
    text-align: center;
  }
  &-subtitle {
    color: $--color-text-primary;
    font-size: 20px;
    font-weight:900;
    line-height: 1.6;
    text-align: center;
  }
  &-extra {
    margin: 25px 0 0;
    text-align: center;
  }
}
</style>
