// 终鉴中列表
export const finalIdentifyColumns = [
  {
    label: '藏品前视图',
    prop: 'main',
    align: 'center',
    width: 200,
    show: true,
    enableSlot: true
  },
  {
    label: '藏品名称',
    prop: 'name',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '分类',
    prop: 'category',
    align: 'center',
    show: true
  },
  {
    label: '派单中心',
    prop: 'institution',
    align: 'center',
    show: true
  },
  {
    label: '派单时间',
    prop: 'assignTime',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '操作',
    prop: 'operators',
    fixed: 'right',
    width: 160,
    align: 'center',
    children: {
      detail: {
        label: '提交资料',
        color: '#267CDC',
        clickEvent: (thisVue, row) => {
          thisVue.toDetails(row)
        }
      }
    }
  }
]
// 已初鉴列表
export const finishFinalIdentifyColumns = [
  {
    label: '藏品前视图',
    prop: 'main',
    align: 'center',
    width: 200,
    show: true,
    enableSlot: true
  },
  {
    label: '藏品名称',
    prop: 'name',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '分类',
    prop: 'category',
    align: 'center',
    show: true
  },
  {
    label: '派单中心',
    prop: 'institution',
    align: 'center',
    show: true
  },
  {
    label: '派单时间',
    prop: 'assignTime',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '终鉴完成时间',
    prop: 'finishTime',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '操作',
    prop: 'operators',
    fixed: 'right',
    width: 160,
    align: 'center',
    children: {
      detail: {
        label: '终鉴详情',
        color: '#267CDC',
        clickEvent: (thisVue, row) => {
          thisVue.toDetailsBlank(row)
        }
      }
    }
  }
]
