<template>
  <div class="notarization-details">
    <div class="ym-panel-radius" v-loading="loading">
      <div class="title">
        <span>{{deptData.deptName}}</span>
      </div>
      <div class="dept" v-if="initFinished">
        <div class="dept-result">
          <el-form :model="form"
                    :rules="rules"
                    ref="form"
                    label-width="150px"
                    label-position="left"
                    label-suffix="："
                    size="small">
            <el-form-item label="所属人/机构" prop="owner">
              <el-input v-model="form.owner" placeholder="请输入" maxlength="50" :disabled="isOperatorOrRecorder" v-if="!submitted"></el-input>
              <p v-else class="show-info">{{form.owner}}</p>
            </el-form-item>

            <el-form-item label="证件信息" prop="info">
              <el-input v-model="form.info" placeholder="请输入" maxlength="50" :disabled="isOperatorOrRecorder" v-if="!submitted"></el-input>
              <p v-else class="show-info">{{form.info}}</p>
            </el-form-item>

            <el-form-item label="确权文件" prop="report" class="item-report">
              <file-upload :sizeLimit="1024*50"
                            typeErrorMsg="上传的格式必须为PDF"
                            :acceptTypes="acceptTypes"
                            :file-list="reportList"
                            :disabled="submitted || isOperatorOrRecorder"
                            @onSuccess="onUploadSuccess($event, 'report')"
                            @onRemove="onFileRemove('report')"
                            @onProgress="onUploadProgress"
                            @onError="onUploadError">
                <el-button size="small" icon="el-icon-upload2" v-if="(!reportList || reportList.length < 1) && !progressing" :disabled="submitted || isOperatorOrRecorder">上传报告</el-button>
              </file-upload>
              <el-tooltip effect="light" placement="top" content="格式必须为PDF，不大于50MB" class="report-tooltip">
                <i class="tips-icon el-icon-question"></i>
              </el-tooltip>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="btn-block" v-if="initFinished">
        <el-button type="primary" class="btn-submit" @click="handleSubmit" :loading="btnLoading" v-if="!submitted && !isOperatorOrRecorder">{{btnLoading ? '提交中' : '确定提交'}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from '@/views/record/component/uploadOnlyFile.vue' // 文件上传

export default {
  components: {
    FileUpload
  },
  props: {
    archiveId: {
      type: String,
      default: ''
    },
    isOperatorOrRecorder: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      btnLoading: false,
      initFinished: false,
      progressing: false,
      submitted: false, // 判断是否已提交鉴定
      acceptTypes: /.pdf$|.PDF$/,
      reportList: [],
      rules: {
        owner: [
          { required: true, message: '请输入所属人/机构信息', trigger: 'change' }
        ],
        info: [
          { required: true, message: '请输入证件信息', trigger: 'change' }
        ],
        report: [
          { required: true, message: '请上传确权文件', trigger: 'change' }
        ]
      },
      form: {
        owner: '',
        info: '',
        report: ''
      },
      deptData: {}
    }
  },
  mounted() {
    this.getNotarizationDetail()
  },
  methods: {
    onUploadSuccess(e, fileType) {
      const res = e.res
      this.$set(this.form, 'report', res.data.originalPath)
      this.reportList.push({
        name: '确权文件',
        url: this.FileUrl + res.data.originalPath
      })
      this.$refs.form.validateField('report')
      this.progressing = false
    },
    onFileRemove(fileType) {
      this.$set(this.form, 'report', '')
      this.reportList = []
      this.progressing = false
    },
    onUploadProgress(event, file, fileList) {
      this.progressing = true
    },
    onUploadError() {
      this.progressing = false
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false
        } else {
          const h = this.$createElement
          this.$msgbox({
            title: '提示',
            message: h('p', { class: 'final-confirm-msg' }, [
              h('span', null, '确定提交终鉴数据吗？'),
              h('span', { class: 'warning-msg' }, '提交后所有数据将不能修改。')
            ]),
            showCancelButton: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            console.log(this.form)
            this.submitNotarizationDetail()
          }).catch(() => {})
        }
      })
    },
    async getNotarizationDetail() {
      try {
        this.loading = true
        const { data } = await this.$api.finalIdentify.getNotarizationDetail({
          archiveId: this.archiveId
        })
        if (data.code === 0) {
          this.deptData = data.data[0]
          if (this.deptData.file) {
            this.$set(this.form, 'owner', this.deptData.owner)
            this.$set(this.form, 'info', this.deptData.certificateInfo)
            this.$set(this.form, 'report', this.deptData.file)
            this.reportList.push({
              name: '确权文件',
              url: this.FileUrl + this.deptData.file
            })
            this.submitted = true
          }
          this.loading = false
          this.initFinished = true
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
          this.loading = false
        }
      } catch (err) {
        this.loading = false
        throw new Error(err)
      }
    },
    async submitNotarizationDetail() {
      try {
        this.btnLoading = true
        const { data } = await this.$api.finalIdentify.submitNotarizationDetail({
          archiveId: this.archiveId,
          identifyAuthorityId: this.deptData.identifyAuthorityId,
          deptId: this.deptData.deptId,
          owner: this.form.owner,
          certificateInfo: this.form.info,
          file: this.form.report,
          fileThumbnail: undefined
        })
        if (data.code === 0) {
          this.$message({
            type: 'success',
            message: '提交成功'
          })
          this.btnLoading = false
          this.$emit('submitFinished')
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
          this.btnLoading = false
        }
      } catch (err) {
        this.btnLoading = false
        throw new Error(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.notarization-details {
  min-width: 1000px;
  color: $--color-text-primary;
  .title {
    display: flex;
    align-items: center;
    font-size: 20px;
    margin: 2px 0 24px;
    span {
      margin-right: 8px;
      font-weight: 700;
    }
  }
  .dept {
    .dept-result {
      padding: 24px 24px 0;
      .el-form {
        .el-form-item {
          width: 460px;
          .show-info {
            width: 700px;
          }
          .report-tooltip {
            position: absolute;
            font-size: 16px;
            color: #C8C9CC;
            top: 50%;
            transform: translateY(-50%);
            left: -74px;
          }
        }
      }
    }
  }
  ::v-deep.el-upload {
    width: 97px;
  }
  .btn-block {
    text-align: center;
    margin-top: 56px;
    margin-bottom: 38px;
    .btn-submit {
      width: 90px;
      height: 32px;
      padding: 8px 16px;
      font-size: 14px;
    }
  }
}
</style>
