<template>
  <div class="final-identify-order" ref="finalIdentifyOrder">
       <!-- 搜索栏 -->
      <div class="filter">
        <el-tabs v-model="selectedStatus" type="card">
        <!-- 档案信息 -->
          <el-tab-pane label="进行中" name="进行中"></el-tab-pane>
          <el-tab-pane label="已完成" name="已完成"></el-tab-pane>
          <el-tab-pane label="已取消" name="已取消"></el-tab-pane>
        </el-tabs>
        <div class="search-bar__top">
          <div class="block">
            <div class="top-left">
              <div class="selector">
                <el-select v-model="search.center"
                          placeholder="分中心"
                          clearable
                          filterable
                          @change="handleSearch">
                  <el-option v-for="center in centerList"
                            :key="center.deptId"
                            :label="center.deptName"
                            :value="center.deptId">
                  </el-option>
                </el-select>
              </div>
              <div class="selector">
                <el-select v-model="search.organization"
                          placeholder="鉴定机构"
                          clearable
                          filterable
                          @change="handleSearch">
                  <el-option v-for="center in organizationList"
                            :key="center.deptId"
                            :label="center.deptName"
                            :value="center.deptId">
                  </el-option>
                </el-select>
              </div>
              <div class="selector">
                <el-select v-model="search.identifyType"
                          placeholder="派单类型"
                          clearable
                          filterable
                          @change="handleSearch">
                  <el-option v-for="type in identifyTypeList"
                            :key="type"
                            :label="type"
                            :value="type">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="search-bar top-right">
              <el-input v-model="search.search"
                        class="search-input"
                        placeholder="请输入订单号或藏品名称"
                        prefix-icon="el-icon-search"
                        clearable
                        @clear="handleSearch"
                        @keyup.enter.native="handleSearch">
                        <el-button slot="append" @click="handleSearch"
                          type="primary">搜索</el-button>
              </el-input>
              <div class="button-box">
                <el-button @click="resetSearchForm" plain>重置</el-button>
              <el-button style="margin-left:40px" icon="el-icon-sort" type="text" @click="sort()">{{ascend?'倒序':'正序'}}</el-button>
              </div>
            </div>
          </div>
          <div class="time-search">
            <el-date-picker v-model="search.assignTime"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="派单时间"
                          end-placeholder="派单时间"
                          :default-time="['00:00:00', '23:59:59']">
            </el-date-picker>

            <el-date-picker v-model="search.identifyTime"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="终鉴时间"
                          end-placeholder="终鉴时间"
                          :default-time="['00:00:00', '23:59:59']"
                          v-if="selectedStatus === '已完成'">
            </el-date-picker>
          </div>
        </div>
      </div>
      <!-- 主体内容 -->
      <div class="ym-panel-radius clearfix">
        <div class="btn-block">
          <el-button @click="handleExport" type="primary" size="small" :loading="btnLoading" class="btn-export">{{btnLoading ? '导出中' : '批量导出'}}</el-button>
        </div>
        <div>
          <ym-table-list :loading="loading"
                        :data="dataList"
                        :columns="listColumns"
                        :pageData="pageData"
                        class="table-list"
                        :showPagination="showPagination"
                        @handlePageChange="handlePageChange"
                        @handleSizeChange="handleSizeChange">
            <!-- 藏品正视图 -->
            <template #main="{row}">
              <div @click="toDetails(row)" class="table-list-img">
                <div class="img">
                  <el-image :src="FileUrl + row.frontThumbnail" fit="contain" lazy>
                    <img class="alt-img" slot="placeholder" src="@/assets/img/logo-header.png" alt="">
                    <img class="alt-img" slot="error" src="@/assets/img/logo-header.png" alt="">
                  </el-image>
                </div>
              </div>
            </template>
            <!-- 藏品名称 -->
            <template #name={row}>
              <div @click="toDetails(row)" class="table-list-name">
                <div class="name">
                  <div class="ellipsis" :title="row.name">{{row.name}}</div>
                  <div class="ellipsis" :title="row.archiveId">{{`ID:${row.archiveId}`}}</div>
                </div>
              </div>
            </template>
            <!-- 派单时间 -->
            <template #dispatchTime="{row}">
              <div>{{(row.dispatchTime) | timeFilter('YYYY-MM-DD')}}</div>
              <div>{{(row.dispatchTime) | timeFilter('HH:mm:ss')}}</div>
            </template>
            <!-- 终鉴完成时间 -->
            <template #identifyTime="{row}">
              <div v-if="row.identifyTime">
                <div>{{(row.identifyTime) | timeFilter('YYYY-MM-DD')}}</div>
                <div>{{(row.identifyTime) | timeFilter('HH:mm:ss')}}</div>
              </div>
              <div v-else>-</div>
            </template>
            <!-- 鉴定项目 -->
            <template #detectItems="{row}">
              <p v-if="!row.detectItems">-</p>
              <template v-else>
                <div class="ellipsis" v-for="item in row.detectItems" :key="item" :title="item">{{item}}</div>
              </template>
            </template>
            <!-- 取消原因 -->
            <template #cancelReason="{row}">
              <div class="ellipsis" :title="row.cancelReason" v-if="row.cancelReason">{{row.cancelReason}}</div>
              <div v-else>-</div>
            </template>
          </ym-table-list>
        </div>
      </div>
  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'
import { progressingList, finishedList, canceledList } from './tableColumns.js'
import { getUserInfo } from '@/utils/localStorage.js'
import store from '@/store'
import debounce from '@/utils/debounce.js'

export default {
  components: {
    // YmPagination,
    YmTableList
  },
  data() {
    return {
      btnLoading: false,
      userInfo: {},
      permissionsTags: [],
      loading: false,
      selectedStatus: '',
      search: {
        search: '',
        center: '',
        organization: '',
        identifyType: '',
        assignTime: ['', ''],
        identifyTime: ['', '']
      },
      ascend: false,
      centerList: [], // 分中心
      organizationList: [], // 鉴定机构
      identifyTypeList: [], // 派单类型
      dataList: [],
      listColumns: [],
      pageData: {
        size: 10, // 每页的数据量
        total: 0, // 数据总量
        layout: 'prev, pager, next, total, sizes, jumper, slot',
        currentPage: 1 // 当前页
      },
      listName: 'final-identify-order-list',
      useListInfo: false, // 是否使用Vuex中的列表页码和搜索信息
      resetAssignTimeFinish: true, // 重置表单时防止重复请求
      resetIdentifyTimeFinish: true, // 重置表单时防止重复请求
      showPagination: false
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.path.includes('/order/final-identify/details')) {
      next(vm => { vm.useListInfo = true })
    }
    next()
  },
  created() {
    this.getList = debounce(this.getList.bind(this), 200)
  },
  mounted() {
    this.userInfo = JSON.parse(getUserInfo())
    this.permissionsTags = this.userInfo && this.userInfo.permissionsTags
    this.getAllDepartmentList('分中心')
    this.getAllDepartmentList('鉴定机构')
    this.getFinalIdentifyType()
    if (this.useListInfo && this.isStatusExist()) {
      this.initListInfo(this.listName)
    } else {
      this.selectedStatus = '进行中'
    }
    this.getTableColumns()
  },
  watch: {
    selectedStatus(val, oldVal) {
      if (!this.useListInfo) {
        this.dataList = []
        this.getTableColumns()
        this.resetSearchForm()
      } else {
        // 使用Vuex中的保留数据
        this.useListInfo = false
        this.resetAssignTimeFinish = false
        this.resetIdentifyTimeFinish = false
        this.loading = true
        this.getList()
      }
    },
    'search.assignTime': {
      // 对点击日期选择器的清空按钮进行特殊处理
      handler(val, oldVal) {
        if (this.resetAssignTimeFinish) {
          if (val === null) {
            this.$set(this.search, 'assignTime', ['', ''])
          } else {
            this.handleSearch()
          }
        } else {
          this.resetAssignTimeFinish = true
        }
      },
      deep: true
    },
    'search.identifyTime': {
      // 对点击日期选择器的清空按钮进行特殊处理
      handler(val, oldVal) {
        if (this.resetIdentifyTimeFinish) {
          if (val === null) {
            this.$set(this.search, 'identifyTime', ['', ''])
          } else {
            this.handleSearch()
          }
        } else {
          this.resetIdentifyTimeFinish = true
        }
      },
      deep: true
    }
  },
  computed: {
  },
  methods: {
    sort() {
      this.ascend = !this.ascend
      this.setListInfo()
      this.loading = true
      this.getList()
    },
    toDetails(items) {
      this.setListInfo()
      this.$router.push({
        name: 'finalIdentifyOrderDetails',
        query: { archiveId: items.archiveId }
      })
    },
    // 新窗口打开
    toDetailsBlank(items) {
      const routeData = this.$router.resolve({
        name: 'finalIdentifyOrderDetails',
        query: { archiveId: items.archiveId }
      })
      window.open(routeData.href, '_blank')
    },
    // 重置表单
    resetSearchForm() {
      this.resetAssignTimeFinish = false
      this.resetIdentifyTimeFinish = false
      this.search = {
        search: '',
        center: '',
        organization: '',
        identifyType: '',
        assignTime: ['', ''],
        identifyTime: ['', '']
      }
      this.ascend = false
      this.handleSearch()
    },
    // 搜索栏搜索
    handleSearch() {
      this.$set(this.pageData, 'currentPage', 1)
      this.setListInfo()
      this.loading = true
      this.getList()
    },
    async handleExport() {
      try {
        this.btnLoading = true
        const res = await this.$api.order.getExport({
          identifyType: this.search.identifyType ? this.search.identifyType : '终鉴',
          orderStatus: this.selectedStatus,
          subCenter: this.search.center,
          institution: this.search.organization,
          idOrName: this.search.search,

          dispatchStartTime: this.search.assignTime[0] ? Date.parse(this.search.assignTime[0]) : '',
          dispatchEndTime: this.search.assignTime[1] ? Date.parse(this.search.assignTime[1]) : '',
          identifyStartTime: this.search.identifyTime[0] ? Date.parse(this.search.identifyTime[0]) : '',
          identifyEndTime: this.search.identifyTime[1] ? Date.parse(this.search.identifyTime[1]) : '',
          ascend: this.ascend
        })
        const resData = res.data
        const fileReader = new FileReader()
        const _this = this
        // 读取文件完成后触发
        fileReader.onloadend = function () {
          try {
            const jsonData = JSON.parse(fileReader.result)
            if (jsonData.code) {
              // 普通对象数据，说明请求文件流失败
              _this.btnLoading = false
              _this.$message({
                type: 'error',
                message: jsonData.message
              })
            }
          } catch (err) {
            // 解析成对象失败，说明是正常的文件流
            _this.handleDownload(res)
          }
        }
        // 读取文件
        fileReader.readAsText(resData)
      } catch (err) {
        this.btnLoading = false
        throw new Error(err)
      }
    },
    handleDownload(res) {
      const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
      if (window.navigator && window.navigator.msSaveBlob) {
        // 兼容IE10，IE11
        window.navigator.msSaveBlob(blob, '终鉴订单列表')
      } else {
        const blobUrl = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = blobUrl
        a.download = '终鉴订单列表'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        window.URL.revokeObjectURL(blobUrl)
      }
      this.btnLoading = false
    },
    // 分页跳转
    handlePageChange(page) {
      this.$set(this.pageData, 'currentPage', page)
      this.setListInfo()
      this.loading = true
      this.getList()
    },
    // 每页大小跳转
    handleSizeChange(size) {
      this.$set(this.pageData, 'size', size)
      this.setListInfo()
      this.loading = true
      this.getList()
    },
    // 获取藏品列表
    async getList() {
      try {
        let res = {}
        const params = {
          page: this.pageData.currentPage,
          size: this.pageData.size,
          identifyType: this.search.identifyType ? this.search.identifyType : '终鉴',
          orderStatus: this.selectedStatus,
          subCenter: this.search.center,
          institution: this.search.organization,
          idOrName: this.search.search.trim(),

          dispatchStartTime: this.search.assignTime[0] ? Date.parse(this.search.assignTime[0]) : '',
          dispatchEndTime: this.search.assignTime[1] ? Date.parse(this.search.assignTime[1]) : '',
          identifyStartTime: this.search.identifyTime[0] ? Date.parse(this.search.identifyTime[0]) : '',
          identifyEndTime: this.search.identifyTime[1] ? Date.parse(this.search.identifyTime[1]) : '',
          ascend: this.ascend
        }
        res = await this.$api.order.getOrderList(params)
        const { data } = res
        if (data.code === 0) {
          this.dataList = data.data.records
          this.$set(this.pageData, 'total', data.data.total)
          this.$refs.finalIdentifyOrder.parentElement.scrollTop = 0
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }
        this.showPagination = true
        this.loading = false
      } catch (err) {
        this.loading = false
        this.showPagination = true
        throw new Error(err)
      }
    },
    // 根据不同状态指定列表展示的列属性
    getTableColumns() {
      this.dataList = []
      switch (this.selectedStatus) {
        case '进行中':
          this.listColumns = progressingList
          break
        case '已完成':
          this.listColumns = finishedList
          break
        case '已取消':
          this.listColumns = canceledList
          break
        default:
          this.listColumns = progressingList
          break
      }
    },
    // 权限查询
    hasPermission(tag) {
      return this.permissionsTags.includes(tag)
    },
    // 若角色为运营，获取当前用户所属分中心能查看的机构；若为鉴定专家，则获取所有分中心机构
    async getAllDepartmentList(deptType) {
      try {
        let res = {}
        if (deptType === '分中心') {
          res = await this.$api.order.getDepartmentListByTypes({ deptType: deptType })
        } else if (deptType === '鉴定机构') {
          res = await this.$api.order.getDepartmentListByTypes({ deptType: deptType, verifyType: '终鉴' })
        }
        const { data } = res
        if (data.code === 0) {
          if (deptType === '分中心') {
            this.centerList = data.data
          } else if (deptType === '鉴定机构') {
            this.organizationList = data.data
          }
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    async getFinalIdentifyType() {
      try {
        const { data } = await this.$api.order.getFinalIdentifyType()
        if (data.code === 0) {
          this.identifyTypeList = data.data
        } else {
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    // 若 Vuex 中保存有当前列表的页码及搜索信息，则获取所有信息并赋值
    initListInfo(listName) {
      const listInfo = store.getters.listInfo
      const index = store.getters.listInfo.findIndex(item => item.listName === listName)
      if (index !== -1) {
        const list = listInfo[index]
        this.$set(this.pageData, 'currentPage', list.pageData.currentPage)
        this.$set(this.pageData, 'size', list.pageData.size)
        this.search = JSON.parse(JSON.stringify(list.search))
        this.ascend = list.search.ascend
        this.selectedStatus = list.search.selectedStatus
      }
    },
    // 将当前页面信息保存至Vuex中
    setListInfo() {
      const listInfo = {
        listName: this.listName,
        pageData: this.pageData,
        search: {
          ...this.search,
          ascend: this.ascend,
          selectedStatus: this.selectedStatus
        }
      }
      this.$store.dispatch('setListInfo', listInfo)
    },
    // 判断Vuex中的selectedStatus是否仍存在(有效)
    isStatusExist() {
      const listInfo = store.getters.listInfo
      const index = store.getters.listInfo.findIndex(item => item.listName === this.listName)
      if (index !== -1 && listInfo[index].search && listInfo[index].search.selectedStatus) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$img-width: 290px;
$img-height: 193px;

.final-identify-order {
  .filter{
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 20px 24px;
    margin-bottom: 20px;
  }
  ::v-deep .filter{
    .el-tabs--card > .el-tabs__header .el-tabs__item.is-active{
      color: #fff;
      background: $--color-primary;
    }
  }
  .search-bar__top {
    min-width: 440px;
    background-color: $--header-bg-color;
    border: $--header-border;
    border-radius: $--border-radius-base;
    padding: 20px 24px;
    margin-bottom: 20px;
    .block {
      display: flex;
      justify-content: space-between;
      .top-left {
        display: flex;
        margin-right: 8px;
        .selector + .selector {
          margin-left: 8px;
        }
      }
    }
    .time-search {
      margin-top: 12px;
      ::v-deep.el-date-editor--daterange {
        &.el-input__inner {
          // width: 216px;
        }
        .el-range-input {
          // width: 70px;
        }
        .el-range-separator {
          width: 24px;
        }
        & + .el-date-editor--daterange {
          margin-left: 8px;
        }
      }
    }
  }
  .button-box{
    margin-left: 10px;
    min-width: 160px;
  }
  .search-bar {
    display: flex;
    ::v-deep .search-input {
      width: 350px;
    }
  }
  ::v-deep .el-input-group__append{
    background-color: $--color-primary;
    color: #fff;
  }
  .ym-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    height: 40px;
    .flex-left {
      align-self: flex-end;
    }
  }
  .btn-block {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
    .btn-export {
      width: 120px;
    }
  }
  .table-list {
    // ::v-deep .el-button + span {
    //   margin-left: 20px;
    // }
    &-img {
      cursor: pointer;
      .img {
        ::v-deep.el-image {
          width: ($img-width * 0.6);
          height: ($img-height * 0.6);
          img {
            width: 100%;
          }
          .alt-img{
            width: 100%;
            filter: grayscale(0.6);
            opacity: .5;
          }
        }
      }
    }
    &-name {
      cursor: pointer;
      .name:hover {
        div {
          color: $--color-primary;
        }
      }
    }
  }
  .name-ellipsis {
    ::v-deep.el-input__inner {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
