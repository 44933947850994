// 终鉴中列表
export const progressingList = [
  {
    label: '订单ID',
    prop: 'orderId',
    align: 'center',
    width: 200,
    show: true
  },
  {
    label: '藏品前视图',
    prop: 'main',
    align: 'center',
    width: 200,
    show: true,
    enableSlot: true
  },
  {
    label: '藏品名称',
    prop: 'name',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '分类',
    prop: 'category',
    align: 'center',
    show: true
  },
  {
    label: '派单中心',
    prop: 'subCenter',
    align: 'center',
    show: true
  },
  {
    label: '派单类型',
    prop: 'identifyType',
    align: 'center',
    show: true
  },
  {
    label: '中心派单时间',
    prop: 'dispatchTime',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '初鉴机构',
    prop: 'deptName',
    align: 'center',
    show: true
  },
  {
    label: '初鉴专家',
    prop: 'expert',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '操作',
    prop: 'operators',
    fixed: 'right',
    width: 160,
    align: 'center',
    children: {
      detail: {
        label: '藏品详情',
        color: '#267CDC',
        clickEvent: (thisVue, row) => {
          thisVue.toDetails(row)
        }
      }
    }
  }
]
// 已完成列表
export const finishedList = [
  {
    label: '订单ID',
    prop: 'orderId',
    align: 'center',
    width: 200,
    show: true
  },
  {
    label: '藏品前视图',
    prop: 'main',
    align: 'center',
    width: 200,
    show: true,
    enableSlot: true
  },
  {
    label: '藏品名称',
    prop: 'name',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '分类',
    prop: 'category',
    align: 'center',
    show: true
  },
  {
    label: '派单中心',
    prop: 'subCenter',
    align: 'center',
    show: true
  },
  {
    label: '派单类型',
    prop: 'identifyType',
    align: 'center',
    show: true
  },
  {
    label: '中心派单时间',
    prop: 'dispatchTime',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '初鉴机构',
    prop: 'deptName',
    align: 'center',
    show: true
  },
  {
    label: '初鉴专家',
    prop: 'expert',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '初鉴时间',
    prop: 'identifyTime',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '操作',
    prop: 'operators',
    fixed: 'right',
    width: 160,
    align: 'center',
    children: {
      detail: {
        label: '藏品详情',
        color: '#267CDC',
        clickEvent: (thisVue, row) => {
          thisVue.toDetails(row)
        }
      }
    }
  }
]
// 已取消列表
export const canceledList = [
  {
    label: '订单ID',
    prop: 'orderId',
    align: 'center',
    width: 200,
    show: true
  },
  {
    label: '藏品前视图',
    prop: 'main',
    align: 'center',
    width: 200,
    show: true,
    enableSlot: true
  },
  {
    label: '藏品名称',
    prop: 'name',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '分类',
    prop: 'category',
    align: 'center',
    show: true
  },
  {
    label: '派单中心',
    prop: 'subCenter',
    align: 'center',
    show: true
  },
  {
    label: '派单类型',
    prop: 'identifyType',
    align: 'center',
    show: true
  },
  {
    label: '中心派单时间',
    prop: 'dispatchTime',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '初鉴机构',
    prop: 'deptName',
    align: 'center',
    show: true
  },
  {
    label: '初鉴专家',
    prop: 'expert',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '取消原因',
    prop: 'cancelReason',
    align: 'center',
    show: true,
    enableSlot: true
  },
  {
    label: '操作',
    prop: 'operators',
    fixed: 'right',
    width: 160,
    align: 'center',
    children: {
      detail: {
        label: '藏品详情',
        color: '#267CDC',
        clickEvent: (thisVue, row) => {
          thisVue.toDetails(row)
        }
      }
    }
  }
]
