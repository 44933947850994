// 具体设置参考 https://clipboardjs.com/

import Clipboard from 'clipboard'
/**
 * 将指定的值复制到剪切板中
 * @param {Object} event 传入触发复制的按钮
 * @param {String} text 需要复制到剪切板的文本
 * @param {Function} handleSuccess 复制成功后的回调函数
 * @param {Functon} handleError 复制失败后的回调函数
 */
const handleClipboard = function (event, text, handleSuccess, handleError, addItem = '') {
  const clipboard = new Clipboard(event.target, {
    text: () => text + addItem
  })
  clipboard.on('success', () => {
    handleSuccess()
    clipboard.destroy()
  })
  clipboard.on('error', () => {
    handleError()
    clipboard.destroy()
  })
  clipboard.onClick(event)
}

export default handleClipboard
