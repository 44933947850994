<template>
  <el-steps direction="vertical" :active="stepActive" finish-status="success">
    <el-step title="基本信息" :description="stepActive === 0?'进行中':stepActive < 0 ? '待完成':'已完成'">进行中</el-step>
    <el-step title="完善资料" :description="stepActive === 1?'进行中':stepActive < 1 ? '待完成':'已完成'">待完成</el-step>
    <el-step title="建档完成" :description="stepActive === 2?'进行中':stepActive < 2 ? '待完成':'已完成'"></el-step>
    <el-step title="资料审核" :description="stepActive === 3?'进行中':stepActive < 3 ? '待完成':'已完成'"></el-step>
    <el-step title="科学鉴定" :description="stepActive === 4?'进行中':stepActive < 4 ? '待完成':'已完成'"></el-step>
    <!-- <el-step title="三确中" :description="stepActive === 5?'进行中':stepActive < 5 ? '待完成':'已完成'"></el-step>
    <el-step title="待终审" :description="stepActive === 6?'进行中':stepActive < 6 ? '待完成':'已完成'"></el-step>
    <el-step title="三确完成" :description="stepActive === 7?'进行中':stepActive < 7 ? '待完成':'已完成'"></el-step> -->
  </el-steps>
</template>

<script>
export default {
  name: 'steps',
  props: {
    stepActive: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {

    }
  },
  computed: {
  },
  watch: {},
  created() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
</style>
