/*
 * 计算规则
 * 1.span,xl,lg,md 四个参都没传, 直接设置默认 24, 9, 12, 18
 * 2.只传入了span, 则全部设为span的大小
 * 3.传xl lg md三个中任意值, 则按照比例 span:xl:lg:md = 6:3:4:6计算,向下取整
 * 4.上限设为24
 */
const calculateLayout = {

  // 根据某个属性补全其他的属性
  // span 默认响应格栅数; xl ≥1920px 响应式栅格数; lg ≥1200px 响应式栅格数; md ≥992px 响应式栅格数
  completionAttribute: function(span, xl, lg, md) {
    // 判断传入的是一个对象还是分开的四种属性,若是对象,则拆分参数
    if (typeof (span) === 'object' && !xl && !lg && !md) {
      return calculateLayout.completionAttribute(span.span, span.xl, span.lg, span.md)
    }
    span = Number(span) || null
    xl = Number(xl) || null
    lg = Number(lg) || null
    md = Number(md) || null
    // 全部为空, 则直接设置默认值
    if (!(span || xl || lg || md)) {
      return this.checkBoundaryValue(24, 24, 24, 24, 24)
    }
    // span 不为空, 其他值全部为空, 则全设置为span的值
    if (span != null && xl === null && lg === null && xl === null) {
      return this.checkBoundaryValue(24, span, span, span, span)
    }
    if (xl) return this.completionAttributeByXl(span, xl, lg, md)
    if (lg) return this.completionAttributeByLg(span, xl, lg, md)
    if (md) return this.completionAttributeByMd(span, xl, lg, md)
  },

  // 根据xl的值补全其余值
  completionAttributeByXl: function(span, xl, lg, md) {
    md = md || Math.floor(xl * 6 / 3)
    span = span || md
    lg = lg || Math.floor(xl * 4 / 3)
    return this.checkBoundaryValue(24, span, xl, lg, md)
  },

  // 根据lg的值补全其余值
  completionAttributeByLg: function(span, xl, lg, md) {
    md = md || Math.floor(lg * 6 / 4)
    span = span || md
    xl = xl || Math.floor(lg * 3 / 4)
    return this.checkBoundaryValue(24, span, xl, lg, md)
  },

  // 根据md的值补全其余值
  completionAttributeByMd: function(span, xl, lg, md) {
    lg = lg || Math.floor(md * 4 / 6)
    span = span || md
    xl = xl || Math.floor(md * 3 / 6)
    return this.checkBoundaryValue(24, span, xl, lg, md)
  },

  // 检查边界值
  checkBoundaryValue(boundary, span, xl, lg, md) {
    return {
      span: span > boundary ? boundary : span,
      xl: xl > boundary ? boundary : xl,
      lg: lg > boundary ? boundary : lg,
      md: md > boundary ? boundary : md
    }
  }

}

export default calculateLayout
