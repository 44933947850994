<template>
  <div class="value-details">
    <div class="ym-panel-radius">
      <div class="title">
        <span>{{deptData.deptName}}</span>
      </div>
      <div class="dept" v-if="initFinished">
        <div class="dept-result">
          <el-form :model="form"
                    ref="form"
                    label-width="120px"
                    label-position="left"
                    label-suffix="："
                    size="small"
                    :disabled="submitted">

            <div class="form-item__value">
              <el-form-item label="藏品估值" prop="min" class="form-item__min-value" :rules="rules.min">
                <el-input v-model="form.min" placeholder="请输入数字" maxlength="15" @input="checkOtherValue('max')" :disabled="isOperatorOrRecorder" v-if="!submitted">
                  <template slot="prepend">￥</template>
                </el-input>
                <p v-else class="show-info">￥ {{numberFormat(form.min)}}</p>
                <el-tooltip effect="light" placement="top" content="左边为最低价估值，右边为最高价估值，至少填其中一个" class="value-tooltip">
                  <i class="tips-icon el-icon-question"></i>
                </el-tooltip>
              </el-form-item>
              <span class="link-icon" :class="submitted ? 'show-info' : ''">～</span>
              <el-form-item prop="max" class="form-item__max-value" :rules="rules.max">
                <el-input v-model="form.max" placeholder="请输入数字" maxlength="15" @input="checkOtherValue('min')" :disabled="isOperatorOrRecorder"  v-if="!submitted">
                  <template slot="prepend">￥</template>
                </el-input>
                <p v-else class="show-info">￥ {{numberFormat(form.max)}}</p>
              </el-form-item>
            </div>

            <el-form-item label="确值文件" prop="report" class="item-report" :rules="rules.report">
              <file-upload :sizeLimit="1024*50"
                            typeErrorMsg="上传的格式必须为PDF"
                            :acceptTypes="acceptTypes"
                            :file-list="reportList"
                            @onSuccess="onUploadSuccess($event, 'report')"
                            @onRemove="onFileRemove('report')"
                            @onProgress="onUploadProgress"
                            @onError="onUploadError"
                            :disabled="submitted || isOperatorOrRecorder">
                <el-button size="small" icon="el-icon-upload2" v-if="(!reportList || reportList.length < 1) && !progressing" :disabled="submitted || isOperatorOrRecorder">上传报告</el-button>
              </file-upload>
              <el-tooltip effect="light" placement="top" content="格式必须为PDF，不大于50MB" class="report-tooltip">
                <i class="tips-icon el-icon-question"></i>
              </el-tooltip>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="btn-block" v-if="initFinished">
        <el-button type="primary" class="btn-submit" @click="handleSubmit" v-if="!submitted && !isOperatorOrRecorder" :loading="btnLoading">{{btnLoading ? '提交中' : '确定提交'}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from '@/views/record/component/uploadOnlyFile.vue' // 文件上传

export default {
  components: {
    FileUpload
  },
  props: {
    deptData: {
      type: Object,
      default: () => {}
    },
    isOperatorOrRecorder: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let checkValue = (rule, value, callback) => {
      const regExp = /^0\.([1-9])$|^0\.(0[1-9])$|^0\.([1-9][0-9])$|^[1-9][0-9]*\.[0-9][0-9]?$|^[1-9][0-9]*$/
      if (!this.form.min && !this.form.max) {
        callback(new Error('请至少输入一个估值'))
      } else if (!regExp.test(this.form.min) && !regExp.test(this.form.max)) {
        callback(new Error('估值格式错误'))
      } else if (this.form.min && this.form.max && (!regExp.test(this.form.min) || !regExp.test(this.form.max))) {
        callback(new Error('估值格式错误'))
      } else if (this.form.max && this.form.min && (Number(this.form.min) > Number(this.form.max))) {
        callback(new Error('最低价估值(左侧)不能大于最高价估值(右侧)'))
      } else {
        callback()
      }
    }
    return {
      initFinished: true,
      progressing: false,
      submitted: false, // 判断是否已提交鉴定
      btnLoading: false,
      acceptTypes: /.pdf$|.PDF$/,
      reportList: [],
      rules: {
        min: [
          { required: true, validator: checkValue, trigger: 'change' }
        ],
        max: [
          { required: true, validator: checkValue, trigger: 'change' }
        ],
        report: [
          { required: true, message: '请上传确值文件', trigger: 'change' }
        ]
      },
      form: {
        min: '',
        max: '',
        report: ''
      }
    }
  },
  mounted() {
    this.initForm(this.deptData)
  },
  methods: {
    initForm(data) {
      if (data.file) {
        this.$set(this.form, 'min', data.lowestPrice ? data.lowestPrice : '')
        this.$set(this.form, 'max', data.highestPrice ? data.highestPrice : '')
        this.$set(this.form, 'report', data.file)
        this.reportList.push({
          name: '确值文件',
          url: this.FileUrl + data.file
        })
        this.submitted = true
      }
      this.initFinished = true
    },
    onUploadSuccess(e, fileType) {
      const res = e.res
      this.$set(this.form, 'report', res.data.originalPath)
      this.reportList.push({
        name: '确值文件',
        url: this.FileUrl + res.data.originalPath
      })
      this.$refs.form.validateField('report')
      this.progressing = false
    },
    onFileRemove(fileType) {
      this.$set(this.form, 'report', '')
      this.reportList = []
      this.progressing = false
    },
    onUploadProgress(event, file, fileList) {
      this.progressing = true
    },
    onUploadError() {
      this.progressing = false
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false
        } else {
          const h = this.$createElement
          this.$msgbox({
            title: '提示',
            message: h('p', { class: 'final-confirm-msg' }, [
              h('span', null, '确定提交终鉴数据吗？'),
              h('span', { class: 'warning-msg' }, '提交后所有数据将不能修改。')
            ]),
            showCancelButton: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.submitValueDetail()
          }).catch(() => {})
        }
      })
    },
    async submitValueDetail() {
      try {
        this.btnLoading = true
        const { data } = await this.$api.finalIdentify.submitValueDetail({
          archiveId: this.deptData.archiveId,
          identifyValueId: this.deptData.identifyValueId,
          deptId: this.deptData.deptId,
          lowestPrice: this.form.min,
          highestPrice: this.form.max,
          file: this.form.report
        })
        if (data.code === 0) {
          this.$message({
            type: 'success',
            message: '提交成功'
          })
          this.btnLoading = false
          this.$emit('submitFinished')
        } else {
          this.btnLoading = false
          this.$message({
            type: 'error',
            message: data.message
          })
        }
      } catch (err) {
        this.btnLoading = false
        throw new Error(err)
      }
    },
    // 其中一个估值被输入，另一个估值清空提示
    checkOtherValue(flag) {
      this.$refs.form.validateField(flag, (error) => {
        if (!error) {

        } else {
          return false
        }
      })
    },
    numberFormat(str) {
      if (str) {
        return Number(str).toLocaleString('zh', { minimumFractionDigits: 2 })
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.value-details {
  min-width: 1000px;
  color: $--color-text-primary;
  .title {
    display: flex;
    align-items: center;
    font-size: 20px;
    margin: 2px 0 24px;
    span {
      margin-right: 8px;
      font-weight: 700;
    }
  }
  .dept {
    .dept-result {
      padding: 24px 24px 0;
      .el-form {
        .el-form-item {
          .report-tooltip, .value-tooltip {
            position: absolute;
            font-size: 16px;
            color: #C8C9CC;
            top: 50%;
            transform: translateY(-50%);
            left: -44px;
          }
          &.item-report {
            width: 452px;
          }
        }
        ::v-deep .form-item__value {
          display: flex;
          .el-input-group__prepend {
            color: #666;
          }
          .link-icon {
            font-size: 20px;
            line-height: 38px;
            margin-left: 12px;
            &.show-info {
              font-size: 14px;
              line-height: 34px;
            }
          }
          .form-item__max-value, .form-item__min-value {
            .el-input {
              .el-input-group__prepend {
                width: 32px;
                height: 38px;
                text-align: center;
                padding: 0;
              }
              input {
                width: 112px;
                height: 38px;
              }
            }
          }
          .form-item__min-value {
            .el-form-item__label {
              height: 38px;
            }
            .value-tooltip {
              top: 42%;
            }
            .el-form-item__error {
              width: 250px;
            }
          }
          .form-item__max-value {
            .el-form-item__content {
              margin-left: 12px !important;
            }
            .el-form-item__error {
              display: none;
            }
          }
        }
      }
    }
  }
  ::v-deep.el-upload {
    width: 97px;
  }
  .btn-block {
    text-align: center;
    margin-top: 56px;
    margin-bottom: 38px;
    .btn-submit {
      width: 90px;
      height: 32px;
      padding: 8px 16px;
      font-size: 14px;
    }
  }
}
</style>
