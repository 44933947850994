<template>
  <el-dialog :visible="show"
             append-to-body
             width="650px"
             custom-class="img-preview-dialog"
             :before-close="handleClose">
    <div class="body">
        <el-image :src="src" fit="contain"></el-image>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'imgDialog',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    src: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    }
  },
  computed: {
  },
  watch: {},
  created() {
  },
  methods: {
    handleClose() {
      this.$emit('update:show', false)
    }
  }
}
</script>

<style lang="scss">
.img-preview-dialog {
  .el-dialog__header {
    background-color: #fff;
  }
  .el-dialog__body {
    padding: 0 24px 24px;
  }
  .body{
    text-align: center;
    .el-image{
      max-width:100%;
      height: auto;
    }
    .el-image__error{
      width: 300px;
      height: 300px;
    }
  }
}
</style>
