<template>
  <div class='user-edit'>
    <ymForm md="11" ref="userForm" :formValue="formValue" :formData="formData" @onSubmit="onSubmit"></ymForm>
    <div class="user-add-btn">
      <el-button type="primary" @click="onSubmit">确定</el-button>
      <el-button @click="goBack=>{this.$router.go(-1)}">返回</el-button>
    </div>
  </div>
</template>

<script>
import ymForm from '@/components/YmForm'
import tableObj from './data.js'
export default {
  components: {
    ymForm
  },
  data () {
    return {
      formValue: {},
      formData: tableObj.editFormData
    }
  },
  mounted() {
    this.getUserGroup()
  },
  methods: {
    // 点击提交按钮,触发提交事件
    submitForm(id) {
      this.$refs[id].onSubmit()
    },
    // 提交事件
    async onSubmit(formValue, ref) {
      this.loading = true
      try {
        let groupIds = []
        groupIds.push(this.formValue.userGroup)
        this.formValue.groupIds = groupIds
        const { data } = await this.$api.user.updateUser(this.formValue)
        console.log({ '提交新增表单': this.formValue, '返回数据': data })
        if (data.code === 0 && data.message) {
          this.$router.push({ name: 'user-list', params: { action: 'edit-success' } })
        } else {
          this.$message.error('编辑用户失败' + (data.message || ''))
        }
      } catch (e) {
        this.loading = false
        console.log(e)
        this.$message.error('提交表单失败')
      }
      this.loading = false
    },
    // 获取全部用户组列表, 初始化用户组下拉框
    async getUserGroup() {
      let { data } = await this.$api.userGroup.getUserGroup()
      console.log({ '查询全部用户组': data, '表单配置': this.formData })
      if (data.code === 0 && data.data) {
        let userGroupFormData = []
        for (var key in data.data) {
          userGroupFormData.push({ label: data.data[key].groupName, value: data.data[key].groupId })
        }
        this.formData[0].items.userGroup.children = userGroupFormData
      }
      let userObj = {}
      userObj.userId = this.$route.query.userId
      userObj.userName = this.$route.query.userName
      userObj.userGroup = this.$route.query.groupIds
      this.$set(this, 'formValue', userObj)
    }
  }
}
</script>

<style lang='scss' scoped>
.user-edit {
  background: #fff;
  padding: 30px;
  .form-container{
    max-width: 600px;
  }

  .user-edit-btn{
    margin: 10px 40px;
    button{
      margin: 0 20px;
    }
  }
}
</style>
